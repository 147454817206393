import styles from "@styles/Sonohaler.module.css";
import { useCallback, useContext, useEffect, useRef } from "react";
import { animated, easings, SpringValue, useSpring, useSpringRef, useChain } from "@react-spring/three";
import { a } from "@react-spring/web";
import { Environment, useTexture } from "@react-three/drei";
import { mergeRefs } from "react-merge-refs";
import type { CanvasProps, HtmlProps, PageModule } from "@pages/Page";
import { EPage } from "@pages/Pages";
import Card from "@components/Card";
import PointerView from "@components/PointerView";
import ScrollInstructions from "@components/ScrollInstructions";
import ShiftedCamera from "@components/ShiftedCamera";
import FollowMouse from "@components/FollowMouse";
import { AnimatedTypeEffect } from "@components/TypeEffect";
import Measure, { useMeasure } from "@components/Measure";
import { Silhouette } from "@components/Silhouette";
import { Lungs } from "@components/Lungs";
import { Initializer, ShiftingWave } from "@components/Wave";
import { LoadingContext } from "@contexts/LoadingContext";
import { SonohalerContext } from "@contexts/SonohalerContext";
import { Easing } from "@animations/Easing";
import { useViewport } from "@utils/ViewportManager";
import useDrag from "@utils/useDrag";
import useWheel from "@utils/useWheel";
import { scrollDelay } from "@utils/Settings";
import { EPerformanceMode } from "@utils/usePerformance";
import data from "@data/Text.json";
const text = data.Sonohaler;

const page: EPage = EPage.Sonohaler;
export default {
    Html,
    Canvas,
    page
} as PageModule;

let moving = false;

function Html(props: HtmlProps) {
    const { loaded } = useContext(LoadingContext);
    const context = useContext(SonohalerContext);
    const { state, dispatch } = context || {};
    const [view] = useViewport<HTMLDivElement>(page);

    const [briefSize, setBriefSize] = useMeasure();

    const [{ headerScale }] = useSpring(() => ({
        headerScale: (state?.index ?? 0) < 2 ? 1.5 : 1,
        delay: (state?.index ?? 0) > 1 ? 0 : 500,
        config: { duration: 1000, easing: easings.easeInOutQuad, precision: 0.001 }
    }), [state?.index]);

    const [{ briefHeight }] = useSpring(() => ({
        briefHeight: (state?.index ?? 0) > 1 ? briefSize.height : 0,
        delay: (state?.index ?? 0) > 1 ? 0 : 500,
        config: { duration: 1000, easing: easings.easeInOutQuad, precision: 0.001 }
    }), [state?.index]);

    const [{ innerOpacity }] = useSpring(() => ({
        innerOpacity: (state?.index ?? 0) > 1 ? 1 : 0,
        delay: (state?.index ?? 0) > 1 ? 1000 : 0,
        config: { duration: 500, precision: 0.001 }
    }), [state?.index]);

    const title1Spring = useSpringRef();
    const title2Spring = useSpringRef();
    const scrollSpring = useSpringRef();
    const [{ title1Percent }] = useSpring(() => ({
        title1Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 700, precision: 0.001 },
        ref: title1Spring
    }), [state?.index]);
    const [{ title2Percent }] = useSpring(() => ({
        title2Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 600, precision: 0.001 },
        ref: title2Spring
    }), [state?.index]);
    const [{ showScroll }] = useSpring(() => ({
        showScroll: (state?.index ?? 0) > 0 ? true : false,
        delay: 200,
        config: { duration: 1 },
        ref: scrollSpring
    }), [state?.index]);
    useChain([title1Spring, title2Spring, scrollSpring]);

    useEffect(() => {
        let index = 0;
        if (loaded && state?.shown) index = 1;
        dispatch?.({ type: "set", index: index });
    }, [dispatch, loaded, state?.shown, props.active]);

    useEffect(() => {
        if (!props.active) return;
        dispatch?.({ type: "show" });
    }, [dispatch, props.active]);

    const change = useCallback((delta: number, ev: Event) => {
        // Get index to scroll to
        const index = Math.sign(delta) > 0 ? 2 : 1;

        // Only begin movement if index has changed
        if (!moving && state?.index === index) return;

        // Prevent propagation to parent, and web defaults
        ev.preventDefault();
        ev.stopPropagation();

        // Prevent change spam (from e.g. event decay by touch software)
        if (moving) return;

        // Apply the change
        dispatch?.({ type: "set", index: index });

        // Mark the component as moving for self and parent
        moving = true;
        props.interactionRef!.current!.interacting = true;

        // Release the movement after delay
        setTimeout(() => {
            moving = false;
            props.interactionRef!.current!.interacting = false;
        }, scrollDelay);
    }, [state, dispatch, props.interactionRef]);

    const refWheel = useWheel<HTMLDivElement>(change);
    const [refDrag] = useDrag<HTMLDivElement>(change);
    const ref = mergeRefs([refWheel, refDrag]);

    const getHeaderStyle = useCallback((scale: SpringValue<number>) => {
        return {
            transform: scale.to(t => `scale(${t})`),
            maxWidth: scale.to(t => `${100 / t}%`)
        }
    }, []);

    return (
        <div className={styles.root}>
            <Card ref={ref}>
                <div className={styles.static}>
                    <div className={styles.staticContent}>
                        <a.div className={styles.header} style={getHeaderStyle(headerScale)}>
                            <AnimatedTypeEffect type="h1" text={text.title1} percent={title1Percent} />
                            <AnimatedTypeEffect type="h1" className="accent1" text={text.title2} percent={title2Percent} />
                        </a.div>
                        <a.div className={styles.brief} style={{ opacity: innerOpacity, height: briefHeight.to(value => `${value}rem`) }}>
                            <Measure setSize={setBriefSize} offset dynamic>
                                <div>
                                    <h2>{text.subtitle1}</h2>
                                    <h2 className="accent1">{text.subtitle2}</h2>
                                    <p>{text.text1}</p>
                                </div>
                            </Measure>
                        </a.div>
                    </div>
                </div>
                <div ref={view} className={styles.dynamic}></div>
                <a.div className="scroll" style={{ opacity: showScroll.to(value => value ? "1" : "0") }}>
                    <ScrollInstructions mobile={props.mobile} />
                </a.div>
            </Card>
        </div>
    );
}

function Canvas(props: CanvasProps) {
    const [view] = useViewport<HTMLDivElement>(page);

    const context = useContext(SonohalerContext);
    const { state } = context || {};

    const [{ offset }] = useSpring(() => ({
        offset: (state?.index ?? 0) > 1 ? props.mobile ? 2 : 1 : 0,
        delay: (state?.index ?? 0) > 1 ? 0 : 0,
        config: { duration: 1000, precision: 0.001 }
    }), [state?.index, props.mobile]);

    const shift: [x: number, y: number] = props.mobile ? [0, 0.45] : [-0.5, 0];
    const z: number = props.mobile ? 20 : 10;

    const mouseInfluence: [x: number, y: number] = props.mobile ? [0.30, 0.15] : [0.10, 0.05];

    return (
        <PointerView ref={view} alwaysUpdate={false} clear={true}>
            <ShiftedCamera enabled={props.active} shift={shift} fov={40} position-z={z} />
            {(props.active || props.mode !== EPerformanceMode.Performance) && <>
                <directionalLight intensity={0.4} position={[1, 1, 1]} />
                <ambientLight intensity={0.2} />
                <Environment files={`${process.env.PUBLIC_URL}/textures/city.exr`} />
                <FollowMouse influence={mouseInfluence} ease={Easing.inoutQuad}>
                    <SonohalerSilhouette />
                </FollowMouse>
                <animated.group position-x={offset}>
                    <FollowMouse influence={mouseInfluence} ease={Easing.inoutQuad}>
                        <SonohalerLungs mobile={props.mobile} offset={0.1} />
                    </FollowMouse>
                    <Wave />
                </animated.group>
            </>}
        </PointerView>
    );
}


const silhouette = `${process.env.PUBLIC_URL}/textures/T_Silhouette_M.png`;
useTexture.preload(silhouette);
function SonohalerSilhouette() {
    const image = useTexture(silhouette);

    const context = useContext(SonohalerContext);
    const { state } = context || {};

    const [{ opacity }] = useSpring(() => ({
        opacity: (state?.index ?? 0) === 1 ? 1 : 0,
        delay: (state?.index ?? 0) > 1 ? 0 : 0,
        config: { duration: 1000, precision: 0.001 }
    }), [state?.index]);

    return (
        <Silhouette image={image} opacity={opacity} scale={27} position={[0, -5, -1.5]} />
    );
}

function SonohalerLungs({ mobile, offset = 0 }: { mobile: boolean; offset?: number }) {
    const context = useContext(SonohalerContext);
    const { state } = context || {};

    const [{ opacity }] = useSpring(() => ({
        opacity: (state?.index ?? 0) === 1 ? 1 : 0,
        delay: (state?.index ?? 0) === 1 ? 500 : 0,
        config: { duration: 1000, precision: 0.001 }
    }), [state?.index]);

    const [{ scale }] = useSpring(() => ({
        scale: (state?.index ?? 0) > 1 ? 1 : 24,
        config: { duration: 1000, precision: 0.001 }
    }), [state?.index]);

    return (
        <Lungs opacity={opacity} scale={scale} offset={offset} visibleThreshold={mobile ? 0 : -1} />
    );
}

function Wave() {
    const context = useContext(SonohalerContext);
    const { state } = context || {};

    const wave = useRef<Initializer>(null);

    const initialize = useCallback(() => {
        wave.current?.();
    }, [wave]);

    const [{ opacity }] = useSpring(() => ({
        opacity: (state?.index ?? 0) > 1 ? 1 : 0,
        delay: 250,
        config: { duration: 750, precision: 0.001 }
    }), [state?.index]);

    const [{ waveOpacity }] = useSpring(() => ({
        waveOpacity: (state?.index ?? 0) > 1 ? 1 : 0,
        delay: 1000,
        config: { duration: 500, precision: 0.001 },
        onStart: initialize
    }), [state?.index]);

    return (
        <ShiftingWave breathing count={21} steps={7} size={15} screenSize={0.6} direction={1} opacity={opacity} shiftOpacity={waveOpacity} initializer={wave} scale={0.25} />
    );
}