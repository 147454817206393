import styles from "@styles/Page.module.css";
import { useMemo, useState, useCallback, useEffect, useContext, useRef, RefObject } from "react";
import { mergeRefs } from "react-merge-refs";
import { EPage } from "@pages/Pages";
import Sonohaler from "@pages/Sonohaler";
import Applications from "@pages/Applications";
import SonoOne from "@pages/SonoOne";
import SonoTwo from "@pages/SonoTwo";
import Mission from "@pages/Mission";
import Footer from "@pages/Footer";
import { appContext } from "@contexts/AppContext";
import { PagesContext } from "@contexts/PagesContext";
import { CSSUtils } from "@utils/CSSUtils";
import { MathUtils } from "@utils/MathUtils";
import useResize from "@utils/useResize";
import useDrag from "@utils/useDrag";
import useWheel from "@utils/useWheel";
import { scrollDelay } from "@utils/Settings";
import usePerformance, { EPerformanceMode } from "@utils/usePerformance";

type InteractingRef = RefObject<{ interacting: boolean }>;

type InnerProps = {
    active: boolean;
    mobile: boolean;
    interactionRef?: InteractingRef;
};
export type HtmlProps = InnerProps;
export type CanvasProps = InnerProps & { mode: EPerformanceMode };

export type PageModule = {
    Html: (props: HtmlProps) => JSX.Element;
    Canvas?: (props: CanvasProps) => JSX.Element;
    page: EPage;
};

type FullPage = PageModule & {
    height: number;
};

type ScrollPage = PageModule & {
    start: number;
    end: number;
};

const pages = [
    Sonohaler,
    Applications,
    SonoOne,
    SonoTwo,
    Mission,
    Footer
];

let moving = false;

export default function Page() {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const context = useContext(PagesContext);

    const resize = useCallback((w: number, h: number) => {
        setSize({ width: w, height: h });
    }, []);
    useResize(resize, true);

    // Heights are dynamic and must be recalculated on resize
    /* eslint-disable react-hooks/exhaustive-deps */
    const navbarHeight = useMemo(() => CSSUtils.parseSize(appContext.getCustomStyle("navbar-height")), [size]);
    const footerHeight = useMemo(() => CSSUtils.parseSize(appContext.getCustomStyle("footer-height")), [size]);
    /* eslint-enable react-hooks/exhaustive-deps */
    const pageHeight = useMemo(() => size.height - navbarHeight, [size, navbarHeight]);

    const content: FullPage[] = useMemo(() => {
        return [
            { ...Sonohaler, height: pageHeight },
            { ...Applications, height: pageHeight },
            { ...SonoOne, height: pageHeight },
            { ...SonoTwo, height: pageHeight },
            { ...Mission, height: pageHeight },
            { ...Footer, height: footerHeight }
        ];
    }, [pageHeight, footerHeight]);

    const pages: ScrollPage[] = useMemo(() => {
        let y = 0;
        return content.map<ScrollPage>((content) => {
            const scrollPage = { ...content, start: y, end: y + content.height };
            y += content.height;
            return scrollPage;
        });
    }, [content]);

    const limit = useMemo(() => {
        const min = pages[0].start;
        const lastFull = pages.length >= 2 ? pages[pages.length - 2].start : 0;
        const last = content[content.length - 1].height;
        const max = lastFull + last;
        return {
            min: -max,
            max: -min
        };
    }, [pages, content]);

    const targetToIndex = useCallback(() => {
        for (let i = 0; i < content.length; ++i) {
            if (content[i].page === context?.target) return i;
        }
        return 0;
    }, [content, context?.target]);

    const movePage = useCallback((delta: number, ev: Event) => {
        // If a child is consuming the interaction, don't do anything
        if (interactionRef.current!.interacting) return;

        // Get page to scroll to
        const p = MathUtils.clamp(targetToIndex() + Math.sign(delta), 0, pages.length - 1);

        // Prevent propagation to parent, and web defaults
        ev.preventDefault();
        ev.stopPropagation();

        // Prevent change spam (from e.g. event decay by touch software)
        if (moving) return;

        // Apply the change
        context?.setTarget(content[p].page);

        // Mark the component as moving
        moving = true;

        // Release the movement after delay
        setTimeout(() => {
            moving = false;
        }, scrollDelay);
    }, [targetToIndex, pages, context, content]);

    const refWheel = useWheel<HTMLDivElement>(movePage);
    const [refDrag, dragging] = useDrag<HTMLDivElement>(movePage);
    const ref = mergeRefs([refWheel, refDrag]);
    const interactionRef = useRef({ interacting: false }) as InteractingRef;

    const top = MathUtils.clamp(-pages[targetToIndex()].start, limit.min, limit.max);
    const style = { top: `${top}px` };
    const classes = [styles.root];
    if (dragging) classes.push(styles.grabbing);

    const isActive = useCallback((page: EPage) => {
        if (page === EPage.Mission || page === EPage.Footer) return context?.target === EPage.Mission || context?.target === EPage.Footer;
        return context?.target === page;
    }, [context?.target]);

    return (
        <div ref={ref} className={classes.join(" ")} style={style}>
            {pages.map((p) => <p.Html key={p.page} active={isActive(p.page)} mobile={context?.mobile ?? false} interactionRef={interactionRef} />)}
        </div>
    );
}

export function PageR3F() {
    // Wait for canvas to hide before changing target
    // This is necessary as each page configures the 
    // view offset, which causes a visual shift.
    const [active, setActive] = useState<EPage | undefined>(undefined);
    const context = useContext(PagesContext);
    const target = context ? context.target : undefined;

    const mode = usePerformance();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setActive(target);
        }, 500);
        return () => clearTimeout(timeout);
    }, [target]);

    const isActive = useCallback((page: EPage) => {
        if (page === EPage.Mission || page === EPage.Footer) return active === EPage.Mission || active === EPage.Footer;
        return active === page;
    }, [active]);

    return (
        <>
            {pages.map((p) => p.Canvas && <p.Canvas key={p.page} active={isActive(p.page)} mobile={context?.mobile ?? false} mode={mode} />)}
        </>
    );
}