import styles from "@styles/ScrollInstructions.module.css";
import Svg from "@components/Svg";

export default function ScrollInstructions({ mobile = false, color = "#26ffff" }: { mobile?: boolean; color?: string; }) {
    return (
        <>
            <div className={styles.main} style={{ borderColor: color }}>
                <Svg viewBox="0 0 20 38">
                    <circle className={styles.dot1} style={{ fill: color }} cx="10" cy="19" r="3" />
                    <circle className={styles.dot2} style={{ fill: color }} cx="10" cy="19" r="3" />
                </Svg>
            </div>
            <div className={styles.text} style={{ color: color }}>{mobile ? "swipe up" : "scroll down"}</div>
        </>
    );
}