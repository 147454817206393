import { appContext } from "@contexts/AppContext";

export class CSSUtils {
    /**
     * Transform the CSS size into number of pixels
     * @param value CSS size value
     * @returns Size in pixels
     */
    static parseSize(value: string | null | undefined) {
        if (value === null || value === undefined) return 0;
        
        let result: RegExpExecArray | null;

        result = /(-?\d+(\.\d+)?)px/i.exec(value);
        if (result?.[0]) {
            return parseFloat(result[1]);
        }

        result = /(-?\d+(\.\d+)?)vw/i.exec(value);
        if (result?.[0]) {
            const percent = parseFloat(result[1]);
            const width = window.innerWidth;
            return width * percent;
        }

        result = /(-?\d+(\.\d+)?)vh/i.exec(value);
        if (result?.[0]) {
            const percent = parseFloat(result[1]);
            const height = window.innerHeight;
            return height * percent;
        }

        result = /(-?\d+(\.\d+)?)rem/i.exec(value);
        if (result?.[0]) {
            const size = parseFloat(result[1]);
            const scale = parseFloat(appContext.getCustomStyle("scale") ?? "1");
            return size * scale;
        }

        console.error("CSS size is too complex to parse!");
        return 0;
    }
}
