import { PropsWithChildren, createContext, useCallback } from "react";
import { PageAction, PageContextProps, PageState, usePageContext } from "@contexts/PageContext";
import SonoOne, { EFeature } from "@pages/SonoOne";

type State = PageState & {
    readonly shown: boolean;
    readonly feature: EFeature;
    readonly prevFeature: EFeature;
    readonly page: Record<EFeature, number>;
    readonly prevPage: Record<EFeature, number>;
    readonly offset: number;
};

type Action = PageAction & {
    feature?: EFeature;
    page?: { key: EFeature, index: number; };
    offset?: number;
};

type ProviderProps = {
} & PropsWithChildren;

export const SonoOneContext = createContext<PageContextProps<State, Action>>(null!);

export function SonoOneContextProvider({ children }: ProviderProps) {
    const initial: State = {
        index: 0,
        shown: false,
        feature: EFeature.None,
        prevFeature: EFeature.None,
        page: { [EFeature.None]: 0, [EFeature.SmartThroughSound]: 0, [EFeature.UserCentricDesign]: 0, [EFeature.Sustainability]: 0 },
        prevPage: { [EFeature.None]: 0, [EFeature.SmartThroughSound]: 0, [EFeature.UserCentricDesign]: 0, [EFeature.Sustainability]: 0 },
        offset: 0
    };
    const showHandler = useCallback((state: State, action: Action) => {
        return { ...state, shown: true };
    }, []);
    const featureHandler = useCallback((state: State, action: Action) => {
        if (action.feature === undefined) return state;
        if (action.feature === state.feature) action.feature = EFeature.None;
        return { ...state, feature: action.feature, prevFeature: state.feature, prevPage: state.page };
    }, []);
    const pageHandler = useCallback((state: State, action: Action) => {
        if (action.page === undefined) return state;
        if (action.page.index === state.page[action.page.key]) return state;
        const page = { ...state.page };
        page[action.page.key] = action.page.index;
        return { ...state, page: page, prevPage: state.page };
    }, []);
    const syncPageHandler = useCallback((state: State, action: Action) => {
        return { ...state, prevPage: state.page };
    }, []);
    const offsetHandler = useCallback((state: State, action: Action) => {
        if (action.offset === undefined) return state;
        if (action.offset === state.offset) return state;
        return { ...state, offset: action.offset };
    }, []);
    const [state, dispatch] = usePageContext<State, Action>(SonoOne, initial, {
        "show": showHandler,
        "feature": featureHandler,
        "page": pageHandler,
        "syncPage": syncPageHandler,
        "offset": offsetHandler
    });
    return (
        <SonoOneContext.Provider value={{ state, dispatch }}>
            {SonoOneContext && children}
        </SonoOneContext.Provider>
    );
};