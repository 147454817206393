import { MathUtils } from "@utils/MathUtils";

// https://easings.net/#
const pow = Math.pow;
const sqrt = Math.sqrt;
const sin = Math.sin;
const cos = Math.cos;
const asin = Math.asin;
const acos = Math.acos;
const log2 = Math.log2;
const PI = Math.PI;
const c1 = 1.70158;
const c2 = c1 * 1.525;
const c3 = c1 + 1;
const c4 = (2 * PI) / 3;
const c5 = (2 * PI) / 4.5;
const bounceOut = function (x: number) {
	const n1 = 7.5625;
	const d1 = 2.75;

	if (x < 1 / d1) {
		return n1 * x * x;
	} else if (x < 2 / d1) {
		return n1 * (x -= 1.5 / d1) * x + 0.75;
	} else if (x < 2.5 / d1) {
		return n1 * (x -= 2.25 / d1) * x + 0.9375;
	} else {
		return n1 * (x -= 2.625 / d1) * x + 0.984375;
	}
};

type Easer = (x: number) => number;
type EaserMap = Record<string, Easer>;

const easers: EaserMap = {
	linear: function (x: number) {
		return x;
	},
	inQuad: function (x: number) {
		return pow(x, 2);
	},
	outQuad: function (x: number) {
		return 1 - pow(1 - x, 2);
	},
	inoutQuad: function (x: number) {
		return x < 0.5 ? 2 * x * x : 1 - pow(-2 * x + 2, 2) / 2;
	},
	inCubic: function (x: number) {
		return pow(x, 3);
	},
	outCubic: function (x: number) {
		return 1 - pow(1 - x, 3);
	},
	inoutCubic: function (x: number) {
		return x < 0.5 ? 4 * x * x * x : 1 - pow(-2 * x + 2, 3) / 2;
	},
	inQuart: function (x: number) {
		return pow(x, 4);
	},
	outQuart: function (x: number) {
		return 1 - pow(1 - x, 4);
	},
	inoutQuart: function (x: number) {
		return x < 0.5 ? 8 * x * x * x * x : 1 - pow(-2 * x + 2, 4) / 2;
	},
	inQuint: function (x: number) {
		return pow(x, 5);
	},
	outQuint: function (x: number) {
		return 1 - pow(1 - x, 5);
	},
	inoutQuint: function (x: number) {
		return x < 0.5 ? 16 * x * x * x * x * x : 1 - pow(-2 * x + 2, 5) / 2;
	},
	inSine: function (x: number) {
		return 1 - cos((x * PI) / 2);
	},
	outSine: function (x: number) {
		return sin((x * PI) / 2);
	},
	inoutSine: function (x: number) {
		return -(cos(PI * x) - 1) / 2;
	},
	inExpo: function (x: number) {
		return x === 0 ? 0 : pow(2, 10 * x - 10);
	},
	outExpo: function (x: number) {
		return x === 1 ? 1 : 1 - pow(2, -10 * x);
	},
	inoutExpo: function (x: number) {
		return x === 0
			? 0
			: x === 1
			? 1
			: x < 0.5
			? pow(2, 20 * x - 10) / 2
			: (2 - pow(2, -20 * x + 10)) / 2;
	},
	inCirc: function (x: number) {
		return 1 - sqrt(1 - pow(x, 2));
	},
	outCirc: function (x: number) {
		return sqrt(1 - pow(x - 1, 2));
	},
	inoutCirc: function (x: number) {
		return x < 0.5
			? (1 - sqrt(1 - pow(2 * x, 2))) / 2
			: (sqrt(1 - pow(-2 * x + 2, 2)) + 1) / 2;
	},
	inBack: function (x: number) {
		return c3 * x * x * x - c1 * x * x;
	},
	outBack: function (x: number) {
		return 1 + c3 * pow(x - 1, 3) + c1 * pow(x - 1, 2);
	},
	inoutBack: function (x: number) {
		return x < 0.5
			? (pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
			: (pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
	},
	inElastic: function (x: number) {
		return x === 0
			? 0
			: x === 1
			? 1
			: -pow(2, 10 * x - 10) * sin((x * 10 - 10.75) * c4);
	},
	outElastic: function (x: number) {
		return x === 0
			? 0
			: x === 1
			? 1
			: pow(2, -10 * x) * sin((x * 10 - 0.75) * c4) + 1;
	},
	inoutElastic: function (x: number) {
		return x === 0
			? 0
			: x === 1
			? 1
			: x < 0.5
			? -(pow(2, 20 * x - 10) * sin((20 * x - 11.125) * c5)) / 2
			: (pow(2, -20 * x + 10) * sin((20 * x - 11.125) * c5)) / 2 + 1;
	},
	inBounce: function (x: number) {
		return 1 - bounceOut(1 - x);
	},
	outBounce: bounceOut,
	inoutBounce: function (x: number) {
		return x < 0.5
			? (1 - bounceOut(1 - 2 * x)) / 2
			: (1 + bounceOut(2 * x - 1)) / 2;
	},
};

const inverters: EaserMap = {
	linear: function (x: number) {
		return x;
	},
	inQuad: function (x: number) {
		return pow(x, 1 / 2);
	},
	outQuad: function (x: number) {
		return 1 - pow(1 - x, 1 / 2);
	},
	inCubic: function (x: number) {
		return pow(x, 1 / 3);
	},
	outCubic: function (x: number) {
		return 1 - pow(1 - x, 1 / 3); 
	},
	inQuart: function (x: number) {
		return pow(x, 1 / 4);
	},
	outQuart: function (x: number) {
		return 1 - pow(1 - x, 1 / 4);
	},
	inQuint: function (x: number) {
		return pow(x, 1 / 5);
	},
	outQuint: function (x: number) {
		return 1 - pow(1 - x, 1 / 5);
	},
	inSine: function (x: number) {
		return acos(1 - x) * 2 / PI;
	},
	outSine: function (x: number) {
		return asin(x) * 2 / PI;
	},
	inExpo: function (x: number) {
		return x === 0 ? 0 : (log2(x) + 10) / 10;
	},
	outExpo: function (x: number) {
		return x === 1 ? 1 : (log2(1 - x)) / -10;
	},
	inCirc: function (x: number) {
		return sqrt(1 - pow(x - 1, 2));
	},
	outCirc: function (x: number) {
		return 1 - sqrt(1 - pow(x, 2));
	},
	inBack: function (x: number) {
		if (!Easing.suppressWarnings) console.warn("Inverted easing of inBack has not been implemented. Visual artifacts may occur!");
		return MathUtils.clamp(x, 0, 1);
	},
	outBack: function (x: number) {
		if (!Easing.suppressWarnings) console.warn("Inverted easing of outBack has not been implemented. Visual artifacts may occur!");
		return MathUtils.clamp(x, 0, 1);
	},
	inElastic: function (x: number) {
		if (!Easing.suppressWarnings) console.warn("Inverted easing of inElastic has not been implemented. Visual artifacts may occur!");
		return MathUtils.clamp(x, 0, 1);
	},
	outElastic: function (x: number) {
		if (!Easing.suppressWarnings) console.warn("Inverted easing of outElastic has not been implemented. Visual artifacts may occur!");
		return MathUtils.clamp(x, 0, 1);
	},
	inBounce: function (x: number) {
		if (!Easing.suppressWarnings) console.warn("Inverted easing of inBounce has not been implemented. Visual artifacts may occur!");
		return MathUtils.clamp(x, 0, 1);
	},
	outBounce: function (x: number) {
		if (!Easing.suppressWarnings) console.warn("Inverted easing of outBounce has not been implemented. Visual artifacts may occur!");
		return MathUtils.clamp(x, 0, 1);
	},
}

export type EaserType = string | Easer;

export class Easing {
	static linear = "linear";
	static inQuad = "inQuad";
	static outQuad = "outQuad";
	static inoutQuad = "inoutQuad";
	static inCubic = "inCubic";
	static outCubic = "outCubic";
	static inoutCubic = "inoutCubic";
	static inQuart = "inQuart";
	static outQuart = "outQuart";
	static inoutQuart = "inoutQuart";
	static inQuint = "inQuint";
	static outQuint = "outQuint";
	static inoutQuint = "inoutQuint";
	static inSine = "inSine";
	static outSine = "outSine";
	static inoutSine = "inoutSine";
	static inExpo = "inExpo";
	static outExpo = "outExpo";
	static inoutExpo = "inoutExpo";
	static inCirc = "inCirc";
	static outCirc = "outCirc";
	static inoutCirc = "inoutCirc";
	static inBack = "inBack";
	static outBack = "outBack";
	static inoutBack = "inoutBack";
	static inElastic = "inElastic";
	static outElastic = "outElastic";
	static inoutElastic = "inoutElastic";
	static inBounce = "inBounce";
	static outBounce = "outBounce";
	static inoutBounce = "inoutBounce";

	static suppressWarnings = false;

	static getEaser(easing: EaserType) { 
		switch (typeof easing) {
			case "string":
				if (easers[easing]) return easers[easing];
				if (!Easing.suppressWarnings) console.warn(`No easing with name ${easing} exists! Please use the Easing object for convenience`);
				return easers.linear;
			case "function":
				return easing;
			default:
				if (!Easing.suppressWarnings) console.warn(`Easing expects an Easing value or custom easing function, but a ${typeof easing} was passed`);
				return easers.linear;
		}
	}
	
	static getInverter(easing: EaserType) {
		switch (typeof easing) {
			case "string":
				if (inverters[easing]) return inverters[easing];
				if (!Easing.suppressWarnings) console.warn(`No easing with name ${easing} exists! Please us the Easing object for convenience`);
				return inverters.linear;
			case "function":
				if (!Easing.suppressWarnings) console.warn(`Inverted custom easing has not been implemented. Visual artifacts may occur!`);
				return inverters.linear;
			default:
				if (!Easing.suppressWarnings) console.warn(`Easing expects an Easing value or custom easing function, but a ${typeof easing} was passed`);
				return inverters.linear;
		}
	}

	static smoothTransition(from: EaserType, to: EaserType, time: number) {
		if (typeof from !== typeof to) {
			if (!Easing.suppressWarnings) console.warn(`Smoothing between two different argument types has not been implemented. Visual artifacts may occur!`);
			return time;
		}
		switch (typeof from) {
			case "string":
				if (from.startsWith("inout") || (to as string).startsWith("inout")) {
					if (from === to) return time;
					if (!Easing.suppressWarnings) console.warn(`Smoothing between different inout easers has not been implemented. Visual artifacts may occur!`);
					return time;
				}
				return Easing.getInverter(to)(Easing.getEaser(from)(time));
			case "function":
				if (!Easing.suppressWarnings) console.warn(`Smoothing custom easing has not been implemented. Visual artifacts may occur!`);
				return time;
			default:
				if (!Easing.suppressWarnings) console.warn(`Easing expects an Easing value or custom easing function, but a ${typeof from} was passed`);
				return time;
		}
	}
}