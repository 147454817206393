import styles from "@styles/NavbarButton.module.css";

type Props = {
    text: string;
    active: boolean;
    onClick?: () => void;
};
export type { Props as NavbarButtonProps }

export default function NavbarButton({ text, active, onClick }: Props) {
    const classes = [styles.root];
    if (active) classes.push(styles.active);
    return (
        <div className={classes.join(" ")} onClick={onClick}>
            {`\u2007${text}\u2007`}
        </div>
    );
}