import styles from "@styles/Tag.module.css";
import { CSSProperties, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { GroupProps } from "@react-three/fiber";
import { a, AnimatedProps, FrameValue } from "@react-spring/web";
import Measure, { useMeasure } from "@components/Measure";
import Svg from "@components/Svg";
import { AppContext } from "@contexts/AppContext";
// Fix the HTML position glitch after resize
import { Html } from "@components/lib/Html";

enum ESide {
    Left = 180,
    Right = 0,
    Top = 270,
    Bottom = 90
};
export { ESide as ETagSide };

type Pin = {
    side?: ESide;
    percent?: number;
};
export type { Pin as TagPin };

type Props = {
    index: number;
    text: string;
    pin?: Pin;
    opacity?: number | FrameValue<number>;
    active: number;
    setActive: Dispatch<SetStateAction<number>>;
} & Omit<GroupProps, "ref">;
export type { Props as TagProps };

function getX(side: ESide, percent: number) {
    switch (side) {
        case ESide.Left: return 0;
        case ESide.Right: return 1;
        default: case ESide.Top: case ESide.Bottom: return percent;
    }
}

function getY(side: ESide, percent: number) {
    switch (side) {
        case ESide.Top: return 0;
        case ESide.Bottom: return 1;
        default: case ESide.Left: case ESide.Right: return percent;
    }
}

export default function Tag({ index, text, pin, opacity = 1, active, setActive, ...props }: Props) {
    const { side = ESide.Right, percent = 0.5 } = pin ?? {};

    const [size, setSize] = useMeasure();
    const { getCustomStyle } = useContext(AppContext);

    const [hover, setHover] = useState(false);
    const isActive = active === index;
    const showContent = hover || isActive;

    const onHover = useCallback(() => {
        setHover(true);
    }, []);
    const onUnhover = useCallback(() => {
        setHover(false);
    }, []);
    const onClick = useCallback(() => {
        setActive(index);
    }, [setActive, index]);

    // Wait for Measure to finish measuring
    const [ready, init] = useReducer(() => true, false);
    useEffect(() => {
        if (size.width > 1 && size.height > 1) init();
    }, [size]);

    // Get radius from CSS
    const radius = useMemo(() => {
        return parseFloat(getCustomStyle("radius") ?? "0");
    }, [getCustomStyle]);

    // Limit radius to size
    const padding = useMemo(() => {
        return Math.min(size.width * 0.5, size.height * 0.5, radius);
    }, [radius, size]);

    // Get pin position and angle
    const [angle, x, y] = useMemo(() => {
        const angle = side as number;
        const x = getX(side, percent);
        const y = getY(side, percent);
        return [angle, x, y];
    }, [side, percent]);

    // Compute styles
    const [rootStyle, containerStyle, constraintStyle, anchorStyle, pinStyle] = useMemo(() => {
        const rootStyle: AnimatedProps<CSSProperties> = {
            opacity: ready ? opacity : 0,
            width: showContent ? `${size.width}rem` : `${padding * 2}rem`,
            transform: `translate(${-x * 100}%, ${-y * 100}%)`
        };
        const containerStyle: CSSProperties = {
            width: `calc(100% - ${padding * 0.5}rem)`,
            height: `calc(100% - ${padding * 0.5}rem)`,
            top: `${padding * 0.25}rem`,
            left: `${padding * 0.25}rem`
        };
        const constraintStyle: CSSProperties = {
            width: `calc(100% - ${padding * 1.5}rem)`,
            height: `calc(100% - ${padding * 1.5}rem)`
        };
        const anchorStyle: CSSProperties = {
            left: `${x * 100}%`,
            top: `${y * 100}%`,
            transform: `rotate(${angle}deg)`
        };
        const pinStyle: CSSProperties = {
            left: `${padding - 2}rem`
        };
        return [rootStyle, containerStyle, constraintStyle, anchorStyle, pinStyle];
    }, [ready, opacity, showContent, size, angle, x, y, padding]);

    const content = useMemo(() => {
        if (!showContent) return "\u2002";
        return text;
    }, [showContent, text]);

    return (
        <Html zIndexRange={[20, 10]} {...props}>
            <a.div className={styles.root} style={rootStyle} onPointerEnter={onHover} onPointerLeave={onUnhover} onClick={onClick}>
                <div className={styles.content}>
                    {content}
                </div>
                <div className={styles.container} style={containerStyle}>
                    <div className={styles.constraint} style={constraintStyle}>
                        <div className={styles.anchor} style={anchorStyle}>
                            <div className={styles.pin} style={pinStyle}>
                                <Svg viewBox="0 -5.1854 14.4016 10.3708" absolute>
                                    <polygon points="0,5.1854 14.4016,0 0,-5.1854" fill="#26ffff" />
                                </Svg>
                            </div>
                        </div>
                    </div>
                </div>
            </a.div>
            <Measure setSize={setSize} offset dynamic hidden>
                <div className={styles.root}>
                    <div className={styles.content}>
                        {text}
                    </div>
                </div>
            </Measure>
        </Html>
    );
}