import { Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, createContext, useCallback, useEffect, useState } from "react";
import { EPage, ETag } from "@pages/Pages";
import { SonohalerContextProvider } from "@contexts/SonohalerContext";
import { ApplicationsContextProvider } from "@contexts/ApplicationsContext";
import { SonoOneContextProvider } from "@contexts/SonoOneContext";
import { SonoTwoContextProvider } from "@contexts/SonoTwoContext";
import { MissionContextProvider } from "@contexts/MissionContext";

type ContextProps = {
    target: EPage;
    setTarget: Dispatch<SetStateAction<EPage>>;
    mobile: boolean;
} | null
export const PagesContext = createContext<ContextProps>(null);

type ProviderProps = {
    canvas: MutableRefObject<HTMLCanvasElement>;
    mobile: boolean;
} & PropsWithChildren;

function getHashTarget() {
    switch (window.location.hash as ETag) {
        default: case ETag.Sonohaler: return EPage.Sonohaler;
        case ETag.Applications: return EPage.Applications;
        case ETag.SonoOne: return EPage.SonoOne;
        case ETag.SonoTwo: return EPage.SonoTwo;
        case ETag.Mission: return EPage.Mission;
        case ETag.Footer: return EPage.Footer;
    }
}

function getTag(page: EPage) {
    switch (page) {
        default: case EPage.Sonohaler: return ETag.Sonohaler;
        case EPage.Applications: return ETag.Applications;
        case EPage.SonoOne: return ETag.SonoOne;
        case EPage.SonoTwo: return ETag.SonoTwo;
        case EPage.Mission: return ETag.Mission;
        case EPage.Footer: return ETag.Footer;
    }
}

export function PagesContextProvider({ canvas, mobile, children }: ProviderProps) {
    const hash = getHashTarget();
    const [target, set] = useState<EPage>(hash);
    const [prevTarget, setPrev] = useState<EPage>(hash);
    const setTarget: typeof set = useCallback((page: SetStateAction<EPage>) => {
        set(t => {
            setPrev(t);
            if (typeof page === "function") page = page(t);
            return page;
        });
    }, [set, setPrev]);

    const onHashChange = useCallback((ev: HashChangeEvent) => {
        setTarget(getHashTarget());
    }, [setTarget]);

    useEffect(() => {
        window.addEventListener("hashchange", onHashChange);
        return () => window.removeEventListener("hashchange", onHashChange);
    }, [onHashChange]);

    useEffect(() => {
        window.location.hash = getTag(target);
    }, [target]);

    useEffect(() => {
        if (!canvas.current) return;
        if (target === prevTarget) return;
        if (target === EPage.Footer || prevTarget === EPage.Footer) return;
        canvas.current.animate({
            opacity: [1, 0, 0, 1],
            offset: [0, 0.1, 0.75, 1]
        }, {
            duration: 1500,
            easing: "ease"
        });
    }, [canvas, target, prevTarget]);

    return (
        <PagesContext.Provider value={{ target, setTarget, mobile }}>{PagesContext &&
            <SonohalerContextProvider>
                <ApplicationsContextProvider>
                    <SonoOneContextProvider>
                        <SonoTwoContextProvider>
                            <MissionContextProvider>
                                {children}
                            </MissionContextProvider>
                        </SonoTwoContextProvider>
                    </SonoOneContextProvider>
                </ApplicationsContextProvider>
            </SonohalerContextProvider>
        }</PagesContext.Provider>
    );
}