import { PageModule } from "@pages/Page";
import { Dispatch, useCallback, useMemo, useReducer } from "react";

type State = {
    readonly index: number;
};
export type { State as PageState };

type Action = {
    readonly type: string;
    index?: number;
} & { [key: string]: any };
export type { Action as PageAction };

type ActionHandler<S extends State, A extends Action> = (state: S, action: A) => S;

type ActionMap<S extends State, A extends Action> = {
    [key: string]: ActionHandler<S, A>
}

type Props<S extends State, A extends Action> = {
    state: Readonly<S>;
    dispatch: Dispatch<A>;
} | null;
export type { Props as PageContextProps };

export function usePageContext<S extends State, A extends Action, _S extends State = Readonly<S>>(page: PageModule, initial: _S, actions?: ActionMap<S, A>) {
    const set = useCallback((prevIndex: number, nextIndex: number) => {
        // Validate nextIndex
        if (nextIndex === undefined) return prevIndex;
        return nextIndex;
    }, []);

    const setHandler = useCallback((state: _S, action: A) => {
        if (!action.index) return state;
        const i = set(state.index, action.index);
        return { ...state, index: i };
    }, [set]);

    const actionMap: ActionMap<_S, A> = useMemo(() => {
        return {
            "set": setHandler,
            ...actions
        };
    }, [setHandler, actions])

    const reducer = useCallback((state: _S, action: A) => {
        const handler = actionMap[action.type];
        if (!handler) throw Error(`Unknown ActionHandler for type ${action.type} in ${page.page}`);
        return handler(state, action);
    }, [actionMap, page]);

    const [state, dispatch] = useReducer(reducer, initial);
    return [state, dispatch] as [_S, Dispatch<A>];
};