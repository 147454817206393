import { LineBasicMaterial, LineBasicMaterialParameters, Shader, Uniform, Vector3 as Vec3, WebGLRenderer } from "three";
import { Vector3 } from "@react-three/fiber";
import { PhasedDisplaceMaterialParams } from "@shaders/PhasedDisplace/PhasedDisplaceMaterialShader";
import { ShaderUtils } from "@utils/ShaderUtils";
import { ThreeUtils } from "@utils/ThreeUtils";

import vert_pars from "@shaders/PhasedDisplace/PhasedDisplace.pars.vert";
import vert_inject from "@shaders/PhasedDisplace/PhasedDisplace.inject.vert";
import vert from "@shaders/PhasedDisplace/PhasedDisplace.vert";

type Params = PhasedDisplaceMaterialParams;
export type { Params as PhasedDisplaceLineMaterialParams };

export class PhasedDisplaceLineMaterial extends LineBasicMaterial {
    private _phase: Uniform<number> = new Uniform(0);
    private _amplitude: Uniform<number> = new Uniform(1);
    private _direction: Uniform<Vec3> = new Uniform(new Vec3(0, 1, 0));

    constructor(params: Params & LineBasicMaterialParameters) {
        const p = ShaderUtils.extract(params,
            "phase", "amplitude", "direction"
        );
        super(params);
        if (!this.defines) this.defines = {};
        this.defines.USE_COLOR = "";
        ShaderUtils.assign(this, p);
    }

    onBeforeCompile(shader: Shader, renderer: WebGLRenderer) {
        super.onBeforeCompile(shader, renderer);

        this._direction.value.normalize(); 

        shader.uniforms.phase = this._phase;
        shader.uniforms.amplitude = this._amplitude;
        shader.uniforms.direction = this._direction;

        shader.vertexShader = ShaderUtils.prepend(shader.vertexShader, vert_pars, true);
        shader.vertexShader = ShaderUtils.inject(shader.vertexShader, vert_inject, vert, true);
    }

    public get phase() { return this._phase.value; }
    public set phase(value: number) { this._phase.value = value; }

    public get amplitude() { return this._amplitude.value; }
    public set amplitude(value: number) { this._amplitude.value = value; }

    public get direction() { return this._direction.value; }
    public set direction(value: Vector3) { ThreeUtils.setVector(value, this._direction.value); }
};