import { useCallback, useMemo } from "react";
import { Vector3 } from "three";
import { Interpolation, SpringValue, useSpring, SpringConfig } from "@react-spring/three";

type ExplodedPosition = {
    "position-x": Interpolation<Vector3, number>;
    "position-y": Interpolation<Vector3, number>;
    "position-z": Interpolation<Vector3, number>;
};

type Input<T extends any[]> = {
    get: (...args: T) => Vector3;
    from: T;
    to: T;
    config: SpringConfig;
};

type Output = [{ position: ExplodedPosition }, SpringValue<number>];

function explodePosition(vec: Interpolation<number, Vector3>) {
    return ({
        "position-x": vec.to(v => v.x),
        "position-y": vec.to(v => v.y),
        "position-z": vec.to(v => v.z),
    });
}

export default function usePositionSpring<T extends any[]>({ get, from, to, config }: Input<T>): Output {
    const [{ position }] = useSpring(() => ({
        from: { position: 0 },
        to: { position: 1 },
        config: config
    }), [config]);

    const [p1, p2] = useMemo(() => {
        const p1 = get(...from);
        const p2 = get(...to);
        position.reset();
        return [p1, p2];
    }, [get, from, to, position]);

    const workVec = useMemo(() => new Vector3(), []);
    const interp = useCallback((t: number) => workVec.lerpVectors(p1, p2, t), [p1, p2, workVec]);

    return [{ position: explodePosition(position.to(interp)) }, position];
}