import { Callback, Listener } from "@utils/Callback";
import { Clock } from "three";

export enum EUpdateMode {
    Off,
    Auto,
    Manual
}

export class Updater {
    static _instance: Updater;
    static get instance() {
        if (!Updater._instance) Updater._instance = new Updater();
        return Updater._instance;
    }

    private onUpdate: Callback<[deltaTime: number, time: number]>;
    private _clock: Clock;
    private _mode: EUpdateMode;

    constructor() {
        this.onUpdate = new Callback();
        this._clock = new Clock();
        this._mode = EUpdateMode.Off;
    }

    set mode(mode: EUpdateMode) { 
        if (Updater._instance._mode === mode) return;
        Updater._instance._mode = mode;
        if (mode === EUpdateMode.Auto) Updater._instance.update();
    }

    update() {
        if (Updater._instance._mode === EUpdateMode.Auto) requestAnimationFrame(Updater._instance.update);
        const deltaTime = Math.min(Updater._instance._clock.getDelta(), 0.2);
        const time = Updater._instance._clock.elapsedTime;
        Updater._instance.onUpdate.call(deltaTime, time);
    }

    add(order: number, key: string, callback: Listener<[deltaTime: number, time: number]>, override = false, once = false) {
        return Updater._instance.onUpdate.add(order, key, callback, override, once) !== null;
    }

    remove(order: number, key: string) {
        return Updater._instance.onUpdate.remove(order, key);
    }
}