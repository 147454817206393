import styles from "@styles/Loading.module.css";
import { useProgress } from "@react-three/drei";
import { PropsWithChildren, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import Logo from "@components/Logo";
import { AppContext, appContext } from "@contexts/AppContext";
import { LoadingContext } from "@contexts/LoadingContext";
import { Sequence } from "@animations/Sequence";
import { Animation, ECollectionTime } from "@animations/Animation";
import { Easing } from "@animations/Easing";
import { Updater } from "@utils/Updater";
import { MathUtils } from "@utils/MathUtils";
import useMeasure from "@utils/useMeasure";

export default function Loading({ children }: PropsWithChildren) {
    const { progress, errors } = useProgress();
    const [loaded, setLoaded] = useState(false);

    if (errors) for (let error of errors) console.error(error);

    const context = useContext(AppContext);
    const margin = useMemo(() => {
        return parseFloat(context.getCustomStyle("margin") ?? "0");
    }, [context]);

    const content = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
    const [logo, logoSize] = useMeasure<HTMLDivElement>({ dynamic: true });
    const percentage = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

    const animation = useMemo(() => {
        return new Sequence("Loading",
            new Animation("Hide Percentage", 0, 1, (t) => {
                if (!percentage.current) return;
                const opacity = MathUtils.lerp(1, 0, t);
                percentage.current.style.opacity = `${opacity}`;
            }).ease(Easing.inoutQuad)
        ).then(
            new Animation("Move Logo", 0, 1, (t) => {
                if (!logo.current) return;
                const left = MathUtils.lerp(logoSize.left, 0, t);
                const top = MathUtils.lerp(logoSize.top, 0, t);
                const position = t > 0 ? "absolute" : "";
                logo.current.style.left = `${left}rem`;
                logo.current.style.top = `${top}rem`;
                logo.current.style.position = position;
            }).ease(Easing.inoutQuad),
            new Animation("Resize Logo", 0, 1, (t, { scale }) => {
                if (!logo.current) return;
                const width = MathUtils.lerp(logoSize.width, 200, t);
                const marginLeft = MathUtils.lerp(0, margin, t);
                logo.current.style.width = `${width}rem`;
                logo.current.style.marginLeft = `${marginLeft}rem`;
            }).ease(Easing.outQuad).addDataCollector(() => {
                const scale = parseFloat(appContext.getCustomStyle("scale") ?? "1");
                return { scale };
            }, ECollectionTime.Start)
        ).then(
            new Animation("Fade Out", 0, 1, (t) => {
                if (!content.current) return;
                const opacity = MathUtils.lerp(1, 0, t);
                content.current.style.opacity = `${opacity}`;
            }).ease(Easing.inQuad)
        ).onComplete(() => {
            if (!content.current) return;
            content.current.style.display = "none";
            setLoaded(true);
        });
    }, [logo, logoSize, margin]);

    useEffect(() => {
        let percent = 0, crawl = 0;
        Updater.instance.add(0, "Loading", (delta) => {
            if (percent > 99.999) {
                Updater.instance.remove(0, "Loading");
                if (percentage.current) percentage.current.innerHTML = `100%`;
                animation.play();
                return;
            }

            const speed = Easing.getEaser(Easing.inQuad)(MathUtils.mapRange(crawl, 70, 99, 1, 0, true)) * 3;
            crawl = MathUtils.clamp(crawl + delta * speed, progress, 99);
            percent = Math.max(crawl, percent);
            if (percent < progress) {
                percent = MathUtils.lerp(percent, progress, 0.1);
            }
            if (percentage.current) percentage.current.innerHTML = `${percent.toFixed(0)}%`;
        });

        return () => {
            Updater.instance.remove(0, "Loading");
        }
    }, [progress, animation]);

    useEffect(() => {
        if (content.current) content.current.removeAttribute("style");
        if (logo.current) logo.current.removeAttribute("style");
        if (percentage.current) percentage.current.removeAttribute("style");
    }, [content, logo, percentage]);

    return (
        <LoadingContext.Provider value={{ loaded: loaded }}>
            {children}
            {!loaded &&
                <div ref={content} className={styles.root}>
                    <div className={styles.content}>
                        <div ref={logo} className={styles.logo}>
                            <Logo animated />
                        </div>
                        <div ref={percentage} className={styles.percent}>0%</div>
                    </div>
                </div>
            }
        </LoadingContext.Provider>
    );
}