import { PropsWithChildren, Ref, Suspense, useEffect, useMemo } from "react";
import { Color, Group, MeshStandardMaterial, SRGBColorSpace, Texture, VideoTexture } from "three";
import { animated } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { TSUtils } from "@utils/TSUtils";
import useMesh, { MeshOptions } from "@utils/useMesh";
import { MathUtils } from "@utils/MathUtils";
import useOpacity from "@utils/useOpacity";

type Props = {
    image: Texture | undefined;
    brightness?: number;
    opacity?: number;
    groupRef?: Ref<Group>;
} & GroupProps & MeshOptions & PropsWithChildren;

const phone = `${process.env.PUBLIC_URL}/models/iPhone-0v4.glb`;
useGLTF.preload(phone);
export const Phone = animated(function ({ image, brightness = 1, opacity = 1, groupRef, children, ...props }: Props) {
    const [mesh] = useMesh(phone, { ...props, copyHierarchy: true, copyMaterial: true });
    const [color, black, white] = useMemo(() => {
        const color = new Color();
        const black = new Color("#000000");
        const white = new Color("#ffffff");
        return [color, black, white];
    }, []);

    const screenMat = useMemo(() => {
        const mat = new MeshStandardMaterial({
            color: "#000000",
            opacity: 1,
            transparent: true,
            roughness: 0.05,
            metalness: 0.1,
            envMapIntensity: 0.2,
            emissive: 0xffffff,
            emissiveIntensity: 0
        });
        mat.name = "M_Screen";
        return mat;
    }, []);

    useEffect(() => {
        if (!image) return;
        if (image.colorSpace !== SRGBColorSpace) {
            image.colorSpace = SRGBColorSpace;
            image.flipY = false;
            image.needsUpdate = true;
        }
        screenMat.map = image;
        screenMat.emissiveMap = image;
        screenMat.needsUpdate = true;
    }, [screenMat, image]);

    useEffect(() => {
        screenMat.color = color.lerpColors(black, white, MathUtils.clamp(brightness, 0, 1));
        screenMat.emissiveIntensity = brightness;
    }, [screenMat, brightness, color, black, white]);

    useEffect(() => {
        mesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            const mat = TSUtils.single(child.material);
            if (mat.name === "M_Screen") child.material = screenMat;
        });
    }, [mesh, screenMat]);

    useOpacity(mesh, opacity, true);
    useOpacity(screenMat, opacity, true);

    return (
        <Suspense>
            <animated.group ref={groupRef} {...props}>
                <primitive object={mesh}>
                    {children}
                </primitive>
            </animated.group>
        </Suspense>
    );
});

export function setupVideo(texture: VideoTexture | undefined) {
    if (!texture) return;
    texture.flipY = false;
}