import { ReactNode, MutableRefObject, useEffect, useRef, useState, useCallback, useReducer } from "react";
import { OrthographicCamera, Color, Scene } from "three";
import { createPortal, useFrame, useThree, Size } from "@react-three/fiber";

const isOrthographicCamera = (def: any): def is OrthographicCamera =>
    def && (def as OrthographicCamera).isOrthographicCamera;
const col = new Color();

function computeContainerPosition(canvasSize: Size, trackRect: DOMRect) {
    const { right, top, left: trackLeft, bottom: trackBottom, width, height } = trackRect;
    const isOffscreen = trackRect.bottom < 0 || top > canvasSize.height || right < 0 || trackRect.left > canvasSize.width;

    const canvasBottom = canvasSize.top + canvasSize.height;
    const bottom = canvasBottom - trackBottom;
    const left = trackLeft - canvasSize.left;
    return {
        position: { width, height, left, top, bottom, right },
        isOffscreen
    };
}

export type ContainerProps = {
    scene: Scene;
    index: number;
    children?: ReactNode;
    frames: number;
    clear: boolean;
    rect: MutableRefObject<DOMRect>;
    track: MutableRefObject<HTMLElement>;
    canvasSize: Size;
}

export type ViewProps = {
    /** The tracking element, the view will be cut according to its whereabouts */
    track: MutableRefObject<HTMLElement>;
    /** Views take over the render loop, optional render index (1 by default) */
    index?: number;
    /** If you know your view is always at the same place set this to 1 to avoid needless getBoundingClientRect overhead */
    frames?: number;
    /** Whether to clear the viewport before render */
    clear?: boolean;
    /** The scene to render, if you leave this undefined it will render the default scene */
    children?: ReactNode;
}

function Container({ canvasSize, scene, index, children, frames, clear, rect, track }: ContainerProps) {
    const get = useThree(state => state.get);
    const camera = useThree(state => state.camera);
    const virtualScene = useThree(state => state.scene);
    const setEvents = useThree(state => state.setEvents);

    let frameCount = 0;
    useFrame((state) => {
        if (frames === Infinity || frameCount <= frames) {
            rect.current = track.current?.getBoundingClientRect() ?? void 0;
            frameCount++;
        }

        if (rect.current) {
            const {
                position: {
                    left,
                    bottom,
                    width,
                    height
                },
                isOffscreen
            } = computeContainerPosition(canvasSize, rect.current);
            const aspect = width / height;

            if (isOrthographicCamera(camera)) {
                if (
                    camera.left !== width / -2 ||
                    camera.right !== width / 2 ||
                    camera.top !== height / 2 ||
                    camera.bottom !== height / -2
                ) {
                    Object.assign(camera, { left: width / -2, right: width / 2, top: height / 2, bottom: height / -2 });
                    camera.updateProjectionMatrix();
                }
            } else if (camera.aspect !== aspect) {
                camera.aspect = aspect;
                camera.updateProjectionMatrix();
            }

            state.gl.setViewport(left, bottom, width, height);
            state.gl.setScissor(left, bottom, width, height);
            state.gl.setScissorTest(true);

            if (isOffscreen) {
                if (clear) {
                    state.gl.getClearColor(col);
                    state.gl.setClearColor(col, state.gl.getClearAlpha());
                    state.gl.clear(true, true);
                }
            } else {
                // When children are present render the portalled scene, 
                // otherwise the default scene
                state.gl.render(children ? virtualScene : scene, camera);
            }

            state.gl.setScissorTest(true);
        }
    }, index);

    useEffect(() => {
        // Connect the event layer to the tracking element
        const old = get().events.connected;
        setEvents({ connected: track.current });
        return () => setEvents({ connected: old });
    }, [get, setEvents, track]);

    return <>{children}</>;
}

// View without target checking
export const View = ({ track, index = 1, frames = Infinity, clear = false, children }: ViewProps) => {
    const rect = useRef<DOMRect>(null!);
    const { size, scene } = useThree();
    const [virtualScene] = useState(() => new Scene());

    const compute = useCallback((event: any, state: any) => {
        // Do not check for target, to allow the elements to always get updated pointer positions
        // This will cause the pointer to exceed the [-1, 1] range
        if (rect.current && track.current) {
            const { width, height, left, top } = rect.current;
            const x = event.clientX - left;
            const y = event.clientY - top;
            state.pointer.set((x / width) * 2 - 1, -(y / height) * 2 + 1);
            state.raycaster.setFromCamera(state.pointer, state.camera);
        }
    }, [rect, track]);

    const [ready, toggle] = useReducer(() => true, false);
    useEffect(() => {
        // We need the tracking elements bounds beforehand in order to inject it into the portal
        rect.current = track.current?.getBoundingClientRect() ?? void 0;
        // And now we can proceed
        toggle();
    }, [track, toggle]);

    const portal = ready && createPortal(
        <Container
            canvasSize={size}
            frames={frames}
            clear={clear}
            scene={scene}
            track={track}
            rect={rect}
            index={index}
        >
            {children}
            <group onPointerOver={() => null} />
        </Container>,
        virtualScene,
        {
            events: { compute, priority: index },
            size: {
                width: rect.current?.width ?? void 0,
                height: rect.current?.height ?? void 0,
                left: rect.current?.left ?? void 0,
                top: rect.current?.top ?? void 0,
            }
        }
    );
    return <>{portal}</>;
};