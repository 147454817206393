import { Callback, Listener } from "@utils/Callback";
import { EaserType, Easing } from "@animations/Easing";
import { EAnimationDirection } from "@animations/Sequence";
import { MathUtils } from "@utils/MathUtils";
import { JSUtils } from "@utils/JSUtils";

export enum ECollectionTime {
    Never = 0,       // 0000
    Manual = 1,      // 0001
    Start = 2,       // 0010
    End = 4,         // 0100
    Frame = 8,       // 1000
    StartAndEnd = 6, // 0110
    Always = 15,     // 1111
}

enum ETime {
    OutOfBounds = 0,
    Start = 1,
    Frame = 2,
    End = 3,
}

type Action = (time: number, data: Record<string, any>) => void;
export type { Action as AnimationAction };

export class Animation {
    name: string;

    action: Action;
    easerForward: EaserType;
    easerBackward: EaserType;

    private _isPlaying: boolean;

    private _startTime: number;
    private _endTime: number;
    private _duration: number;

    private _onStart: Callback<[]>;
    private _onEnd: Callback<[]>;

    private _t: number;

    private _collectedData: { [key: string]: any };
    private _collectionTime: ECollectionTime;
    private _onCollect: Callback<[time: ECollectionTime]>;

    constructor(name: string, startTime: number, endTime: number, action: Action) {
        this.name = JSUtils.valueOrDefault(name, JSUtils.guid());
        this._startTime = JSUtils.valueOrDefault(startTime, 0);
        this._endTime = JSUtils.valueOrDefault(endTime, this._startTime);
        this._duration = 0;
        this.calculateDuration();
        this.action = JSUtils.valueOrDefault(action, (time, data) => { });
        this.easerForward = Easing.linear;
        this.easerBackward = Easing.linear;

        this._onStart = new Callback();
        this._onEnd = new Callback();

        this._t = -1;
        this._isPlaying = false;

        this._collectedData = {};
        this._collectionTime = ECollectionTime.Always;
        this._onCollect = new Callback();
    }

    get startTime() { return this._startTime; }
    get endTime() { return this._endTime; }

    get data() { return { ...this._collectedData }; }

    get isPlaying() { return this._isPlaying; }
    set isPlaying(value: boolean) { this._isPlaying = value; if (!value) this._t = -1; }

    getEaser(direction: EAnimationDirection) { return direction === EAnimationDirection.Forward ? this.easerForward : this.easerBackward; }

    setTimes(startTime: number, endTime: number) {
        this._startTime = JSUtils.valueOrDefault(startTime, 0);
        this._endTime = JSUtils.valueOrDefault(endTime, this._startTime);
        this.calculateDuration();
    }

    calculateDuration() {
        this._duration = this._endTime - this._startTime;
        if (this._duration < 0) {
            console.warn(`Animation ${this.name} has invalid duration of ${this._duration}`);
            this._duration = 0;
        }
    }

    /*
     * Add easing to the animation
     * Specify two functions to add different easing based on the animation direction
     * This can be any function that transforms time
     * Check `Easing` for common easing functions
     */
    ease(easerForward: EaserType, easerBackward: EaserType | null = null) {
        this.easerForward = JSUtils.valueOrDefault(easerForward, Easing.linear);
        this.easerBackward = JSUtils.valueOrDefault(easerBackward, this.easerForward);
        return this;
    }

    /*
     * Add a function to execute when the animation starts
     * Specify whether the functions should execute only once
     */
    onStart(callback: Listener<[]>, once = false) {
        this._onStart.add(0, null, callback, false, once);
        return this;
    }

    /*
     * Add a function to execute when the animation ends
     * Specify whether the functions should execute only once
     */
    onEnd(callback: Listener<[]>, once = false) {
        this._onEnd.add(0, null, callback, false, once);
        return this;
    }

    /*
     * Manual data collection
     */
    collectData() {
        this._onCollect.call(ECollectionTime.Manual);
        return this;
    }

    /*
     * Manual data insertion
     */
    addData(data: { [key: string]: any }) {
        if (!data) return this;
        this._collectedData = { ...this._collectedData, ...data };
        return this;
    }

    /*
     * Add a function that collects data (by returning an object) at the given time
     * This data will be passed in the animation action and can be accessed 
     * from anywhere using animation.data
     */
    addDataCollector(collector: () => Record<string, any>, collectionTime: ECollectionTime = ECollectionTime.Manual) {
        this._onCollect.add(0, null, (time) => {
            if (!this._shouldCollect(time, collectionTime)) return;
            const data = collector() || {};
            this._collectedData = { ...this._collectedData, ...data };
        }, false, false);
        return this;
    }

    private _shouldCollect(currentTime: ECollectionTime, collectionTime: ECollectionTime) {
        return (currentTime & collectionTime) !== 0;
    }

    setCollectionTime(collectionTime: ECollectionTime) {
        this._collectionTime = collectionTime;
        return this;
    }

    /*
     * Run the animation at given time and direction
     * Used internally by the Sequencer
     */
    run(t: number, d: EAnimationDirection) {
        if (this._duration < 0) return this;
        if (this._duration > 0) {
            t = this._transformTime(t);
            if (!this._isTimeValid(t)) return this;
            const et = this._getTime(t, d);
            t = MathUtils.clamp(t, 0, 1);
            this._collectData(et);
            this.action(this._easeTime(t, d), this.data);
            this._handleCallbacks(et);
        } else {
            if (!this._shouldPlayFrame(t, d)) return this;
            this.action(1, this.data);
        }
        return this;
    }

    private _transformTime(t: number) {
        t -= this.startTime;
        if (this._duration > 0) t /= this._duration;
        else t = 0;
        return t;
    }

    private _isTimeValid(t: number) {
        const lastValid = this._t >= 0 && this._t <= 1;
        const currValid = t >= 0 && t <= 1;
        this._t = t;
        return lastValid || currValid;
    }

    private _shouldPlayFrame(t: number, d: EAnimationDirection) {
        switch (d) {
            case EAnimationDirection.Forward: {
                const shouldPlay = t >= this._startTime && this._t < this._startTime;
                this._t = t;
                return shouldPlay;
            }
            case EAnimationDirection.Backward: {
                const shouldPlay = t <= this._endTime && this._t > this._endTime;
                this._t = t;
                return shouldPlay;
            }
            default: {
                console.warn(`Invalid animation direction for Animation ${this.name}`);
                return false;
            }
        }
    }

    private _easeTime(t: number, d: EAnimationDirection) {
        switch (d) {
            case EAnimationDirection.Forward: {
                return Easing.getEaser(this.easerForward)(t);
            }
            case EAnimationDirection.Backward: {
                return Easing.getEaser(this.easerBackward)(t);
            }
            default: {
                console.warn(`Invalid animation direction for Animation ${this.name}`);
                return t;
            }
        }
    }

    private _getTime(t: number, d: EAnimationDirection) {
        switch (d) {
            case EAnimationDirection.Forward: {
                if (t <= 0) return ETime.OutOfBounds;
                if (t <= 1 && !this.isPlaying) return ETime.Start;
                if (t >= 1 && this.isPlaying) return ETime.End;
                if (t > 1) return ETime.OutOfBounds;
                return ETime.Frame;
            }
            case EAnimationDirection.Backward: {
                if (t >= 1) return ETime.OutOfBounds;
                if (t >= 0 && !this.isPlaying) return ETime.Start;
                if (t <= 0 && this.isPlaying) return ETime.End;
                if (t < 0) return ETime.OutOfBounds;
                return ETime.Frame;
            }
            default: {
                console.warn(`Invalid animation direction for Animation ${this.name}`);
                return ETime.OutOfBounds;
            }
        }
    }

    private _collectData(et: number) {
        if (this._collectionTime === ECollectionTime.Never) return;
        if (this._collectionTime === ECollectionTime.Manual) return;

        switch (et) {
            case ETime.Start:
                if (this._shouldCollect(this._collectionTime, ECollectionTime.Start)) {
                    this._onCollect.call(ECollectionTime.Start);
                }
                break;
            case ETime.End:
                if (this._shouldCollect(this._collectionTime, ECollectionTime.End)) {
                    this._onCollect.call(ECollectionTime.End);
                }
                break;
            case ETime.Frame:
                if (this._shouldCollect(this._collectionTime, ECollectionTime.Frame)) {
                    this._onCollect.call(ECollectionTime.Frame);
                }
                break;
            default:
                break;
        }
    }

    private _handleCallbacks(et: number) {
        switch (et) {
            case ETime.Start:
                this.isPlaying = true;
                this._onStart.call();
                break;
            case ETime.End:
                this.isPlaying = false;
                this._onEnd.call();
                break;
            default:
                break;
        }
    }

    /**
     * Create a copy of this animation
     * Will not copy the onStart/onEnd callbacks
     */
    clone() {
        return new Animation(`${this.name} clone ${JSUtils.guid()}`, this.startTime, this.endTime, this.action)
            .ease(this.easerForward, this.easerBackward);
    }
}