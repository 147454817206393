import styles from "@styles/Button.module.css";

type Props = {
    label: string;
    active: boolean;
    onClick: () => void;
}
export type { Props as ButtonProps };

export default function Button({ label, active, onClick }: Props) {
    const classes = [styles.root];
    if (active) classes.push(styles.active);
    return (
        <button className={classes.join(" ")} onClick={onClick}>
            {label}
        </button>
    );
};