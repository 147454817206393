import { ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { DynamicDrawUsage, Group, InstancedMesh, Material, Matrix4, Mesh, Vector3 } from "three";
import { PrimitiveProps, useFrame } from "@react-three/fiber";
import { animated } from "@react-spring/three";
import { MathUtils } from "@utils/MathUtils";
import { OptRange, Range, TSUtils } from "@utils/TSUtils";
import { useSize } from "@utils/useOffset";
import { applyOptions } from "@utils/useMesh";
import useOpacity from "@utils/useOpacity";

type MeshDefinition = {
    /** ThreeJS Mesh */
    mesh: Mesh;
    /** Model index, used for grouping */
    index: number;
    /** Model scale */
    scale: number;
}

type InstanceDefinition = {
    mesh: MeshDefinition;
    instance: InstancedMesh;
    indices: number[];
}

type ShowerProps = {
    /** Number of meshes to spawn */
    count: number;
    /** Shower opacity */
    opacity: number;
    /** Mesh definitions to populate the shower with */
    meshDefinitions: MeshDefinition[];
    /** Speed of downwards movement */
    speed: number;
    /** Screen width in percent */
    width: number;
    /** Depth range */
    depth: OptRange;
}

function zoneIndex(value: number, range: Range, zones: number) {
    const zoneSize = (range[1] - range[0]) / zones;
    return Math.floor((value - range[0]) / zoneSize);
}

function randomZone(range: OptRange, prev: number, zones: number) {
    const r = TSUtils.toRange(range);
    if (r[0] === r[1]) return r[0];
    zones = Math.max(zones, 1);
    const prevZone = zoneIndex(prev, r, zones);
    let val: number;
    do {
        val = MathUtils.randRange(range);
    } while (zoneIndex(val, r, zones) === prevZone);
    return val;
}

export const Shower = animated(function Shower({ count, opacity, meshDefinitions, speed, width, depth }: ShowerProps) {
    const random = useRef({ index: -1, depth: -Infinity, x: -Infinity });

    const randomIndex = useCallback((length: number) => {
        const index = Math.floor(randomZone([0, length], random.current.index, length));
        random.current.index = index;
        return index;
    }, []);

    const randomDepth = useCallback((range: OptRange) => {
        const depth = randomZone(range, random.current.depth, 3);
        random.current.depth = depth;
        return depth;
    }, []);

    const randomX = useCallback((range: OptRange) => {
        const x = randomZone(range, random.current.x, 3);
        random.current.x = x;
        return x;
    }, []);

    const [meshes, items, definitionMap] = useMemo(() => {
        // Group definitions by index
        const definitionMap: Record<number, InstanceDefinition[]> = {};
        for (let definition of meshDefinitions) {
            const index = definition.index;
            if (!definitionMap[index]) definitionMap[index] = [];
            const arr = definitionMap[index];
            arr.push({ mesh: definition, instance: null!, indices: [] });
            definitionMap[index] = arr;
        }

        // Generate indices for each definition group
        for (let i = 0; i < count; ++i) {
            const index = randomIndex(Object.keys(definitionMap).length);
            for (let definition of definitionMap[index]) {
                definition.indices.push(i);
            }
        }

        // Create instances
        for (let index of TSUtils.keys(definitionMap)) {
            for (let definition of definitionMap[index]) {
                definition.instance = new InstancedMesh(definition.mesh.mesh.geometry, definition.mesh.mesh.material, definition.indices.length);
                applyOptions(definition.instance, {});
                definition.instance.instanceMatrix.setUsage(DynamicDrawUsage);
            }
        }

        // Create meshes and matrix control items
        const meshes: ReactElement<PrimitiveProps>[] = [];
        const items: ReactElement<ShowerItemProps>[] = [];
        for (let index of TSUtils.keys(definitionMap)) {
            const definitions = definitionMap[index];

            // Create an InstancedMesh for each definition
            for (let i = 0; i < definitions.length; ++i) {
                const definition = definitions[i];
                meshes.push(
                    <primitive key={`group ${index} mesh ${i}`} object={definition.instance} />
                );
            }

            // Create a matrix control item for each instance per group
            for (let i = 0; i < definitions[0].indices.length; ++i) {
                items.push(
                    <ShowerItem
                        key={`group ${index} instance ${i}`} index={definitions[0].indices[i]}
                        definitions={definitions} instance={i}
                        count={count} speed={speed} width={width}
                        x={randomX} z={randomDepth(depth)}
                    />
                );
            }
        }
        return [meshes, items, definitionMap];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...meshDefinitions, count, ...TSUtils.toArray(depth), speed, width, randomIndex, randomX, randomDepth]);

    const materials = useMemo(() => {
        const materials: Material[] = [];
        for (let index of TSUtils.keys(definitionMap)) {
            for (let definition of definitionMap[index]) {
                TSUtils.forEach(definition.instance.material, material => materials.push(material))
            }
        }
        return materials;
    }, [definitionMap]);
    useOpacity(materials, opacity);

    useFrame(() => {
        for (let index of TSUtils.keys(definitionMap)) {
            for (let definition of definitionMap[index]) {
                definition.instance.instanceMatrix.needsUpdate = true;
            }
        }
    }, 1);

    return (
        <group>
            {meshes}
            {items}
        </group>
    );
});

type ShowerItemProps = {
    count: number;
    index: number;
    definitions: InstanceDefinition[];
    instance: number;
    x: (range: OptRange) => number;
    z: number;
    speed?: number;
    width?: number;
}

export function ShowerItem({ count, index, definitions, instance, x, z, speed = 1, width: percent = 1 }: ShowerItemProps) {
    const [width, height] = useSize(-z);

    const ref = useRef<Group>(null);

    const generate = useCallback(() => ({
        x: x([-percent, percent]),
        rx: Math.random() * Math.PI,
        rz: Math.random() * Math.PI,
        spin: Math.random() * 4 + 8
    }), [x, percent]);

    const [data] = useState({ ...generate(), y: index / count * height * 2 - height });

    const applyMatrix = useMemo(() => {
        const workMatrix = new Matrix4();
        const scales = new Array<Vector3>(definitions.length).fill(new Vector3());
        for (let i = 0; i < definitions.length; ++i) scales[i].setScalar(definitions[i].mesh.scale);

        const applyMatrix = (matrix: Matrix4) => {
            for (let i = 0; i < definitions.length; ++i) {
                const definition = definitions[i];
                workMatrix.copy(matrix).scale(scales[i]);
                definition.instance.setMatrixAt(instance, workMatrix);
            }
        }
        return applyMatrix;
    }, [definitions, instance]);

    useFrame(({ clock }, dt) => {
        if (!ref.current) return;
        if (dt > 0.2) return;

        ref.current.position.set(
            data.x * width,
            (data.y -= dt * speed),
            -z
        );

        ref.current.rotation.set(
            (data.rx += dt / data.spin),
            Math.sin(index * 1000 + clock.elapsedTime / 10) * Math.PI,
            (data.rz += dt / data.spin)
        );

        ref.current.updateMatrix();
        applyMatrix(ref.current.matrix);

        if (data.y < -height) {
            Object.assign(data, generate());
            data.y = height;
        }
    });

    return (
        <group ref={ref} />
    );
}