import { useMemo, useRef, useEffect } from "react";
import { Color, Group, Layers, MeshBasicMaterial, MeshDepthMaterial, OrthographicCamera, PlaneGeometry, ShaderMaterial, WebGLRenderTarget } from "three";
import { useThree, useFrame } from "@react-three/fiber";
import { HorizontalBlurShader, VerticalBlurShader } from "three-stdlib";
import { ContactShadowsProps } from "@react-three/drei";
import { useMeshGeometry } from "@utils/useMesh";
import { animated } from "@react-spring/three";

type Props = Pick<Omit<Omit<import("@react-three/fiber").GroupProps, "scale">, "layers"> & ContactShadowsProps, "visible" | "attach" | "args" | "children" | "key" | "onUpdate" | "position" | "up" | "rotation" | "matrix" | "quaternion" | "dispose" | "type" | "isGroup" | "id" | "uuid" | "name" | "parent" | "modelViewMatrix" | "normalMatrix" | "matrixWorld" | "matrixAutoUpdate" | "matrixWorldAutoUpdate" | "matrixWorldNeedsUpdate" | "castShadow" | "receiveShadow" | "frustumCulled" | "renderOrder" | "animations" | "userData" | "customDepthMaterial" | "customDistanceMaterial" | "isObject3D" | "onBeforeRender" | "onAfterRender" | "applyMatrix4" | "applyQuaternion" | "setRotationFromAxisAngle" | "setRotationFromEuler" | "setRotationFromMatrix" | "setRotationFromQuaternion" | "rotateOnAxis" | "rotateOnWorldAxis" | "rotateX" | "rotateY" | "rotateZ" | "translateOnAxis" | "translateX" | "translateY" | "translateZ" | "localToWorld" | "worldToLocal" | "lookAt" | "add" | "remove" | "removeFromParent" | "clear" | "getObjectById" | "getObjectByName" | "getObjectByProperty" | "getObjectsByProperty" | "getWorldPosition" | "getWorldQuaternion" | "getWorldScale" | "getWorldDirection" | "raycast" | "traverse" | "traverseVisible" | "traverseAncestors" | "updateMatrix" | "updateMatrixWorld" | "updateWorldMatrix" | "toJSON" | "clone" | "copy" | "addEventListener" | "hasEventListener" | "removeEventListener" | "dispatchEvent" | keyof import("@react-three/fiber/dist/declarations/src/core/events").EventHandlers | keyof ContactShadowsProps> & React.RefAttributes<Group> & { layers: Layers };

export function ContactShadows({
    scale = 10,
    frames = Infinity,
    opacity = 1,
    width = 1,
    height = 1,
    blur = 1,
    near = 0,
    far = 10,
    resolution = 512,
    smooth = true,
    color = "#000000",
    depthWrite = false,
    renderOrder,
    layers,
    ...props
}: Props) {
    const ref = useRef<Group>(null!);
    const scene = useThree(state => state.scene);
    const gl = useThree(state => state.gl);
    const shadowCamera = useRef<OrthographicCamera>(null!);
    width = width * (Array.isArray(scale) ? scale[0] : scale || 1);
    height = height * (Array.isArray(scale) ? scale[1] : scale || 1);
    const [renderTarget, planeGeometry, depthMaterial, horizontalBlurMaterial, verticalBlurMaterial, renderTargetBlur] = useMemo(() => {
        const renderTarget = new WebGLRenderTarget(resolution, resolution);
        const renderTargetBlur = new WebGLRenderTarget(resolution, resolution);
        renderTargetBlur.texture.generateMipmaps = renderTarget.texture.generateMipmaps = false;
        const planeGeometry = new PlaneGeometry(width, height).rotateX(Math.PI / 2);
        const depthMaterial = new MeshDepthMaterial();
        depthMaterial.depthTest = depthMaterial.depthWrite = false;

        depthMaterial.onBeforeCompile = shader => {
            shader.uniforms = {
                ...shader.uniforms,
                ucolor: {
                    value: new Color(color)
                }
            };
            shader.fragmentShader = shader.fragmentShader.replace(`void main() {`, //
                `uniform vec3 ucolor;
void main() {`
            );
            shader.fragmentShader = shader.fragmentShader.replace("vec4( vec3( 1.0 - fragCoordZ ), opacity );", // Colorize the shadow, multiply by the falloff so that the center can remain darker
                "vec4( ucolor * fragCoordZ * 2.0, ( 1.0 - fragCoordZ ) * 1.0 );");
        };

        const horizontalBlurMaterial = new ShaderMaterial(HorizontalBlurShader);
        const verticalBlurMaterial = new ShaderMaterial(VerticalBlurShader);
        verticalBlurMaterial.depthTest = horizontalBlurMaterial.depthTest = false;
        return [renderTarget, planeGeometry, depthMaterial, horizontalBlurMaterial, verticalBlurMaterial, renderTargetBlur] as [WebGLRenderTarget, PlaneGeometry, MeshDepthMaterial, ShaderMaterial, ShaderMaterial, WebGLRenderTarget];
    }, [resolution, width, height, color]);

    const blurPlane = useMeshGeometry(planeGeometry, null!, { shadow: true });

    const blurShadows = (blur: number) => {
        blurPlane.visible = true;
        blurPlane.material = horizontalBlurMaterial;
        horizontalBlurMaterial.uniforms.tDiffuse.value = renderTarget.texture;
        horizontalBlurMaterial.uniforms.h.value = blur * 1 / 256;
        gl.setRenderTarget(renderTargetBlur);
        gl.render(blurPlane, shadowCamera.current);
        blurPlane.material = verticalBlurMaterial;
        verticalBlurMaterial.uniforms.tDiffuse.value = renderTargetBlur.texture;
        verticalBlurMaterial.uniforms.v.value = blur * 1 / 256;
        gl.setRenderTarget(renderTarget);
        gl.render(blurPlane, shadowCamera.current);
        blurPlane.visible = false;
    };

    let count = 0;
    let initialBackground;
    let initialOverrideMaterial;
    useFrame(() => {
        if (shadowCamera.current && (frames === Infinity || count < frames)) {
            count++;
            initialBackground = scene.background;
            initialOverrideMaterial = scene.overrideMaterial;
            ref.current!.visible = false;
            scene.background = null;
            scene.overrideMaterial = depthMaterial;
            gl.setRenderTarget(renderTarget);
            gl.render(scene, shadowCamera.current);
            blurShadows(blur);
            if (smooth) blurShadows(blur * 0.4);
            gl.setRenderTarget(null);
            ref.current!.visible = true;
            scene.overrideMaterial = initialOverrideMaterial;
            scene.background = initialBackground;
        }
    });

    const material = useMemo(() => {
        return new MeshBasicMaterial({
            transparent: true,
            map: renderTarget.texture,
            opacity: 0,
            depthWrite: depthWrite
        })
    }, [renderTarget.texture, depthWrite]);

    useEffect(() => {
        material.opacity = opacity;
    }, [material, opacity]);

    const mesh = useMeshGeometry(planeGeometry, material, { shadow: true });
    mesh.renderOrder = renderOrder ?? 0;

    const camera = useMemo(() => {
        const camera = new OrthographicCamera(-width * 0.5, width * 0.5, height * 0.5, -height * 0.5, near, far);
        camera.layers = layers;
        return camera;
    }, [width, height, near, far, layers]);

    return (
        <group ref={ref} rotation-x={Math.PI * 0.5} {...props}>
            <primitive object={mesh} scale={[1, -1, 1]} rotation={[-Math.PI * 0.5, 0, 0]} />
            <primitive ref={shadowCamera} object={camera} />
        </group>
    );
}

export const AnimatedContactShadows = animated(ContactShadows);