import { createContext } from "react";

export type LoadingContextProps = {
    loaded: boolean;
}

export const loadingContext: LoadingContextProps = {
    loaded: false
}

export const LoadingContext = createContext(loadingContext);