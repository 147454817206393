import { Suspense, useEffect, useMemo, useState } from "react";
import { animated } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { GroupProps, useFrame } from "@react-three/fiber";
import { AnimationMixer, AnimationObjectGroup, Color, MeshBasicMaterial, MeshPhysicalMaterial } from "three";
import { EOrigin, OutlineGlowMaterial } from "@shaders/OutlineGlow/OutlineGlowMaterialShader";
import useMesh from "@utils/useMesh";
import { TSUtils } from "@utils/TSUtils";
import { speed } from "@utils/Settings";
import useOpacity from "@utils/useOpacity";

type Props = {
    opacity?: number;
    offset?: number;
    visibleThreshold?: number; 
} & GroupProps;

const lungs = `${process.env.PUBLIC_URL}/models/Lungs-0v4.glb`;
useGLTF.preload(lungs);
export const Lungs = animated(function Lungs({ opacity = 1, offset = 0, visibleThreshold = 0, ...props }: Props) {
    const [lungMesh, animations] = useMesh(lungs, { copyHierarchy: true });
    const [wireMesh] = useMesh(lungs, { copyHierarchy: true });

    useEffect(() => {
        wireMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            if (child.name.includes("_0")) child.visible = false;
        });
    }, [wireMesh]);

    const outlineMat = useMemo(() => {
        return new OutlineGlowMaterial({
            color: new Color("#26ffff"),
            transparent: true,
            opacity: 0,
            depthWrite: false,
            depthTest: false,
            outlineThickness: 0.1,
            outlineOpacity: 1,
            glowStartOpacity: 0.3,
            glowEndOpacity: 0,
            origin: EOrigin.Right
        })
    }, []);

    const lungMat = useMemo(() => {
        return new MeshPhysicalMaterial({
            color: "#26ffff",
            roughness: 0.1,
            metalness: 0.0,
            envMapIntensity: 1.5,
            transparent: true,
            opacity: 0,
            transmission: 0.712,
            depthWrite: true,
            wireframe: false
        });
    }, []);

    const wireMat = useMemo(() => {
        return new MeshBasicMaterial({
            color: "#26ffff",
            transparent: true,
            opacity: 0,
            depthWrite: false,
            wireframe: true
        });
    }, []);

    useOpacity(outlineMat, opacity, false, visibleThreshold);
    useOpacity(lungMat, opacity, true, visibleThreshold);
    useOpacity(wireMat, opacity * 0.5, false, visibleThreshold);

    useEffect(() => {
        lungMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            if (child.name.includes("_0")) { child.material = outlineMat; child.renderOrder = 1; }
            else child.material = lungMat;
        });
    }, [lungMesh, outlineMat, lungMat]);

    useEffect(() => {
        wireMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            child.material = wireMat;
        });
    }, [wireMesh, wireMat]);

    const mixer = useMemo(() => {
        const mixer = new AnimationMixer(new AnimationObjectGroup(lungMesh, wireMesh));
        return mixer;
    }, [lungMesh, wireMesh]);

    useMemo(() => {
        const action = mixer.clipAction(animations[0]);
        const action2 = mixer.clipAction(animations[1]);
        action.timeScale = animations[0].duration;
        action2.timeScale = animations[1].duration;
        action.play();
        action2.play();
    }, [mixer, animations]);

    const [time, setTime] = useState(0);
    useFrame((state, delta) => {
        setTime(t => t += delta * speed);
    });

    useEffect(() => {
        const delta = (time + offset) - mixer.time;
        mixer.update(delta);
    }, [time, offset, mixer]);

    return (
        <Suspense>
            <group {...props}>
                <primitive object={lungMesh} />
                <primitive object={wireMesh} />
            </group>
        </Suspense>
    );
});