import { Ref, Suspense, useMemo } from "react";
import { Group, Mesh, Vector3 } from "three";
import { animated } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { TSUtils } from "@utils/TSUtils";
import useMesh, { MeshOptions } from "@utils/useMesh";
import useOpacity from "@utils/useOpacity";

type Props = {
    opacity?: number;
    groupRef?: Ref<Group>;
} & GroupProps & MeshOptions;

const ellipta = `${process.env.PUBLIC_URL}/models/Ellipta-0v6.glb`;
useGLTF.preload(ellipta);
export const Ellipta = animated(function ({ opacity = 1, groupRef, ...props }: Props) {
    const elliptaMesh = useEllipta({ opacity, ...props });

    return (
        <Suspense>
            <animated.group ref={groupRef} {...props}>
                <primitive object={elliptaMesh} />
            </animated.group>
        </Suspense>
    );
});

export function useEllipta({ opacity = 1, ...props }: { opacity?: number } & MeshOptions) {
    const [elliptaMesh] = useMesh(ellipta, { ...props, copyHierarchy: true, copyMaterial: true });
    const mesh = useMemo(() => {
        let mesh: Mesh = null!;
        elliptaMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            mesh = child;
        });
        mesh.position.copy(mesh.getWorldPosition(new Vector3()));
        mesh.updateMatrix();
        return mesh;
    }, [elliptaMesh]);
    useOpacity(mesh.material, opacity, true);
    return mesh;
};