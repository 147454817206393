import { JSUtils } from "@utils/JSUtils";

export type Listener<T extends any[]> = (...args: T) => any;
type Invocation<T extends any[]> = { listener: Listener<T>; once: boolean; };
type InvocationList<T extends any[]> = Record<string, Invocation<T>>;
type OrderedInvocationList<T extends any[]> = Record<number, InvocationList<T>>;
type Condition = (result: any) => boolean;

export class Callback<T extends any[]> {
    _listeners: OrderedInvocationList<T> = {};
    _condition: Condition | null = null;

    /**
     * Specifies a condition that ends the callback call, stopping propagation to other listeners 
     * @param condition Condition to check after every call, taking the result of the listener
     * @returns this, for chaining
     */
    withCondition(condition: Condition) {
        this._condition = condition;
        return this;
    }

    /** 
     * Adds the callback at key
     * @param order The order at which to call the callback. Execution happens from low to high order. Callbacks with same order will be called in order of addition
     * @param key They key at which to store the callback. Set to `null` for auto
     * @param callback The callback function. Can have any signature
     * @param override Whether to override the callback at key if key already exists
     * @param once Whether to call the function only once
     * @returns The key at which the callback has been stored. `null` if not stored
     */
    add(order: number, key: string | null, callback: Listener<T>, override: boolean = false, once: boolean = false) {
        if (key === null) key = JSUtils.guid();
        if (this._listeners[order] === undefined) this._listeners[order] = {};
        if (!override && this._listeners[order][key] !== undefined) {
            console.warn(`${key} already exists in Callback at order ${order}`);
            return null;
        }
        this._listeners[order][key] = { listener: callback, once };
        return key;
    }

    /**
     * Removes the callback at key
     * @param order The order of the callback
     * @param key The key of the callback
     * @returns Whether the deletion was successful
     */
    remove(order: number, key: string) {
        if (key === null || key === undefined) return false;
        if (this._listeners[order] === undefined) return false;
        if (this._listeners[order][key] === undefined) return false;
        delete this._listeners[order][key];
        return true;
    }

    /**
     * Removes all callbacks
     */
    clear() {
        this._listeners = {};
    }

    /**
     * Call the callbacks
     * @param args Argument list
     * @returns The results of the callbacks
     * @note If the callback has a condition, the call will stop as soon as the condition is met.
     * This condition is global; if the first listener meets it, none of the others are called.
     */
    call(...args: T) {
        const orders = JSUtils.transform(Object.keys(this._listeners), (el) => parseInt(el));
        orders.sort((x, y) => x - y);
        const ret = [];
        for (let order of orders) {
            for (let key in this._listeners[order]) {
                const { listener, once } = this._listeners[order][key];
                if (once) this.remove(order, key);
                const result = listener(...args);
                ret.push(result);
                if (this._condition) {
                    if (this._condition(result)) return ret;
                }
            }
        }
        return ret;
    }

    /**
     * Number of listeners
     */
    get count() { 
        let count = 0;
        for (let order in this._listeners) {
            count += Object.keys(this._listeners[order]).length;
        }
        return count;
    }
}