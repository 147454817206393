import { MeshBasicMaterial, MeshBasicMaterialParameters, Shader, Uniform, Vector3 as Vec3, WebGLRenderer } from "three";
import { Vector3 } from "@react-three/fiber";
import { PhasedDisplaceMaterialParams } from "@shaders/PhasedDisplace/PhasedDisplaceMaterialShader";
import { EOrigin, OutlineGlowMaterialParams } from "@shaders/OutlineGlow/OutlineGlowMaterialShader";
import { ShaderUtils } from "@utils/ShaderUtils";
import { ThreeUtils } from "@utils/ThreeUtils";
import { MathUtils } from "@utils/MathUtils";

import vert_pars from "@shaders/PhasedDisplace/PhasedDisplace.pars.vert";
import vert_inject from "@shaders/PhasedDisplace/PhasedDisplace.inject.vert";
import vert from "@shaders/PhasedDisplace/PhasedDisplace.vert";
import frag_pars from "@shaders/OutlineGlow/OutlineGlow.pars.frag";
import frag_inject from "@shaders/OutlineGlow/OutlineGlow.inject.frag";
import frag from "@shaders/OutlineGlow/OutlineGlow.frag";

type Params = PhasedDisplaceMaterialParams & OutlineGlowMaterialParams;
export type { Params as PhasedDisplaceOutlineGlowMaterialParams };

export class PhasedDisplaceOutlineGlowMaterial extends MeshBasicMaterial {
    private _phase: Uniform<number> = new Uniform(0);
    private _amplitude: Uniform<number> = new Uniform(1);
    private _direction: Uniform<Vec3> = new Uniform(new Vec3(0, 1, 0));
    private _origin: Uniform<EOrigin> = new Uniform(EOrigin.Bottom);
    private _outlineThickness: Uniform<number> = new Uniform(0.4);
    private _outlineOpacity: Uniform<number> = new Uniform(1);
    private _glowStartOpacity: Uniform<number> = new Uniform(0.25);
    private _glowEndOpacity: Uniform<number> = new Uniform(0);

    constructor(params: Params & MeshBasicMaterialParameters) {
        const p = ShaderUtils.extract(params,
            "phase", "amplitude", "direction",
            "origin", "outlineThickness", "outlineOpacity", "glowStartOpacity", "glowEndOpacity"
        );
        super(params);
        if (!this.defines) this.defines = {};
        this.defines.USE_COLOR = "";
        this.defines.USE_UV = "";
        ShaderUtils.assign(this, p);
    }

    onBeforeCompile(shader: Shader, renderer: WebGLRenderer) {
        super.onBeforeCompile(shader, renderer);

        this._direction.value.normalize(); 
        const cos = Math.cos(this._origin.value * MathUtils.deg2Rad);
        const sin = Math.sin(this._origin.value * MathUtils.deg2Rad);
        const rotation = [cos, -sin, sin, cos];

        shader.uniforms.phase = this._phase;
        shader.uniforms.amplitude = this._amplitude;
        shader.uniforms.direction = this._direction;
        shader.uniforms.outlineGlowRotation = new Uniform(rotation);
        shader.uniforms.outlineThickness = this._outlineThickness;
        shader.uniforms.outlineOpacity = this._outlineOpacity;
        shader.uniforms.glowStartOpacity = this._glowStartOpacity;
        shader.uniforms.glowEndOpacity = this._glowEndOpacity;

        shader.vertexShader = ShaderUtils.prepend(shader.vertexShader, vert_pars, true);
        shader.vertexShader = ShaderUtils.inject(shader.vertexShader, vert_inject, vert, true);
        shader.fragmentShader = ShaderUtils.prepend(shader.fragmentShader, frag_pars, true);
        shader.fragmentShader = ShaderUtils.inject(shader.fragmentShader, frag_inject, frag, true);
    }

    public get phase() { return this._phase.value; }
    public set phase(value: number) { this._phase.value = value; }

    public get amplitude() { return this._amplitude.value; }
    public set amplitude(value: number) { this._amplitude.value = value; }

    public get direction() { return this._direction.value; }
    public set direction(value: Vector3) { ThreeUtils.setVector(value, this._direction.value); }

    public get origin() { return this._origin.value; }
    public set origin(value: EOrigin) { this._origin.value = value; }

    public get outlineThickness() { return this._outlineThickness.value; }
    public set outlineThickness(value: number) { this._outlineThickness.value = value; }

    public get outlineOpacity() { return this._outlineOpacity.value; }
    public set outlineOpacity(value: number) { this._outlineOpacity.value = value; }

    public get glowStartOpacity() { return this._glowStartOpacity.value; }
    public set glowStartOpacity(value: number) { this._glowStartOpacity.value = value; }

    public get glowEndOpacity() { return this._glowEndOpacity.value; }
    public set glowEndOpacity(value: number) { this._glowEndOpacity.value = value; }
};