import { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { AnimationClip, BufferGeometry, Group, Line, Material, Mesh } from "three";
import { RAYCAST, RENDER, SHADOW } from "@utils/Settings";
import { TSUtils } from "@utils/TSUtils";

export type MeshOptions = {
    shadow?: boolean;
    interactive?: boolean;
};
export type CopyOptions = {
    copyHierarchy?: boolean;
    copyGeometry?: boolean | ((geom: BufferGeometry) => boolean);
    copyMaterial?: boolean | ((mat: Material) => boolean);
}

function copyGeometry(child: Mesh | Line, condition: (geom: BufferGeometry) => boolean) {
    if (condition(child.geometry)) child.geometry = child.geometry.clone();
}

function copyMaterial(child: Mesh | Line, condition: (mat: Material) => boolean) {
    if (TSUtils.isArray(child.material)) {
        for (let i = 0; i < child.material.length; ++i) {
            if (condition(child.material[i])) child.material[i] = child.material[i].clone();
        }
    } else {
        if (condition(child.material)) child.material = child.material.clone();
    }
}

export function applyOptions(object: Mesh | Line, options?: MeshOptions & Omit<CopyOptions, "copyHierarchy">) {
    object.layers.set(RENDER);

    if (options?.shadow) {
        object.layers.enable(SHADOW);
        object.castShadow = true;
        object.receiveShadow = true;
    }

    if (options?.interactive) {
        object.layers.enable(RAYCAST);
    }

    if (options?.copyGeometry !== undefined && options.copyGeometry !== false) {
        if (options.copyGeometry === true) copyGeometry(object, () => true);
        else copyGeometry(object, options.copyGeometry);
    }

    if (options?.copyMaterial !== undefined && options.copyMaterial !== false) {
        if (options.copyMaterial === true) copyMaterial(object, () => true);
        else copyMaterial(object, options.copyMaterial);
    }
}

export default function useMesh(path: string, options?: MeshOptions & CopyOptions) {
    const gltf = useGLTF(path);
    const mesh = useMemo(() => {
        const mesh = options?.copyHierarchy ? gltf.scene.clone(true) : gltf.scene;
        mesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            applyOptions(child, options);
        });
        return mesh;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gltf.scene, options?.shadow, options?.interactive, options?.copyHierarchy, options?.copyGeometry, options?.copyMaterial]);
    return [mesh, gltf.animations] as [Group, AnimationClip[]];
}

export function useMeshGeometry(geometry: BufferGeometry, material: Material, options?: MeshOptions & Omit<CopyOptions, "copyHierarchy">) {
    const mesh = useMemo(() => {
        const mesh = new Mesh(geometry, material);
        applyOptions(mesh, options);
        return mesh;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geometry, material, options?.shadow, options?.interactive, options?.copyGeometry, options?.copyMaterial]);
    return mesh;
}

export function useLineGeometry(geometry: BufferGeometry, material: Material, options?: MeshOptions & Omit<CopyOptions, "copyHierarchy">) {
    const line = useMemo(() => {
        const line = new Line(geometry, material);
        applyOptions(line, options);
        return line;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geometry, material, options?.shadow, options?.interactive, options?.copyGeometry, options?.copyMaterial]);
    return line;
}