import { PropsWithChildren, createContext, useCallback } from "react";
import { PageAction, PageContextProps, PageState, usePageContext } from "@contexts/PageContext";
import Applications, { EFeature } from "@pages/Applications";

type State = PageState & {
    readonly shown: boolean;
    readonly feature: EFeature;
    readonly prevFeature: EFeature;
    readonly offset: number;
};

type Action = PageAction & {
    feature?: EFeature;
    offset?: number;
};

type ProviderProps = {
} & PropsWithChildren;

export const ApplicationsContext = createContext<PageContextProps<State, Action>>(null!);

export function ApplicationsContextProvider({ children }: ProviderProps) {
    const initial = {
        index: 0,
        shown: false,
        feature: EFeature.None,
        prevFeature: EFeature.None,
        offset: 0
    };
    const showHandler = useCallback((state: State, action: Action) => {
        return { ...state, shown: true };
    }, []);
    const featureHandler = useCallback((state: State, action: Action) => {
        if (action.feature === undefined) return state;
        if (action.feature === state.feature) action.feature = EFeature.None;
        return { ...state, feature: action.feature, prevFeature: state.feature };
    }, []);
    const offsetHandler = useCallback((state: State, action: Action) => {
        if (action.offset === undefined) return state;
        if (action.offset === state.offset) return state;
        return { ...state, offset: action.offset };
    }, []);
    const [state, dispatch] = usePageContext<State, Action>(Applications, initial, {
        "show": showHandler,
        "feature": featureHandler,
        "offset": offsetHandler
    });
    return (
        <ApplicationsContext.Provider value={{ state, dispatch }}>
            {ApplicationsContext && children}
        </ApplicationsContext.Provider>
    );
};