import { RefObject } from "react";
import { VideoTexture } from "three";

export type VideoHandle = {
    play: () => void;
    pause: () => void;
    restart: () => void;
};

export type VideoOptions = {
    /** Restart the video on pause */
    restart?: boolean;
    /** Create a ThreeJS VideoTexture */
    texture?: boolean;
};

export class VideoManager {
    static _instance: VideoManager;
    static get instance() {
        if (!VideoManager._instance) VideoManager._instance = new VideoManager();
        return VideoManager._instance;
    }

    private videos: Map<string, VideoHandle>;
    private textures: Map<string, VideoTexture>;
    private refs: Map<string, RefObject<HTMLVideoElement>>;

    constructor() {
        this.videos = new Map<string, VideoHandle>();
        this.textures = new Map<string, VideoTexture>();
        this.refs = new Map<string, RefObject<HTMLVideoElement>>();
    }

    has(src: string): boolean {
        return this.refs.has(src);
    }

    get(src: string, type: "video"): VideoHandle | undefined;
    get(src: string, type: "texture"): VideoTexture | undefined; 
    get(src: string, type: "ref"): RefObject<HTMLVideoElement> | undefined; 
    get(src: string, type: "video" | "texture" | "ref") {
        switch (type) {
            case "video": return this.videos.get(src);
            case "texture": return this.textures.get(src);
            case "ref": return this.refs.get(src);
        }
    }

    create(src: string, ref: RefObject<HTMLVideoElement>, options?: VideoOptions) {
        const handle = {
            play: () => {
                ref.current?.play().catch((reason: DOMException) => {
                    if (reason.name === "NotAllowedError") console.warn("Cannot play video before user interaction. Consider muting the video, or wait for user input.");
                    else if (reason.name === "NotSupportedError") console.error(typeof src === "string" ? `Video at "${src}" not found.` : `Video stream not found`);
                    else throw reason;
                });
            },
            pause: () => {
                ref.current?.pause();
                if (options?.restart && ref.current) ref.current.currentTime = 0;
            },
            restart: () => {
                if (ref.current) ref.current.currentTime = 0;
            }
        }
        this.videos.set(src, handle);
        if (options?.texture && ref.current) {
            const tex = new VideoTexture(ref.current);
            this.textures.set(src, tex);
        }
        this.refs.set(src, ref);
        return handle;
    }

    destroy(src: string) {
        this.videos.delete(src);
        const texture = this.textures.get(src);
        if (texture) texture.dispose();
        this.textures.delete(src);
        this.refs.delete(src);
    }
};