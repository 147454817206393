import { Suspense, useEffect, useMemo } from "react";
import { MeshBasicMaterial, PlaneGeometry, SRGBColorSpace, Texture } from "three";
import { animated } from "@react-spring/three";
import { GroupProps } from "@react-three/fiber";
import { useMeshGeometry } from "@utils/useMesh";
import useOpacity from "@utils/useOpacity";

type Props = {
    image: Texture;
    opacity?: number;
} & GroupProps;

export const Silhouette = animated(function ({ image, opacity = 1, ...props }: Props) {
    const mat = useMemo(() => {
        const mat = new MeshBasicMaterial({
            map: image,
            transparent: true,
            opacity: 0,
            depthWrite: false
        });
        return mat;
    }, [image]);

    useEffect(() => {
        if (image.colorSpace !== SRGBColorSpace) {
            image.colorSpace = SRGBColorSpace;
            image.needsUpdate = true;
        }
    }, [image]);

    useOpacity(mat, opacity, false);

    const geom = useMemo(() => {
        return new PlaneGeometry(1, 1, 1, 1);
    }, []);

    const mesh = useMeshGeometry(geom, mat);
    mesh.renderOrder = -1;

    return (
        <Suspense>
            <primitive object={mesh} {...props} />
        </Suspense>
    )
});