import { useEffect, useRef } from "react";
import { Camera, PerspectiveCameraProps } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { cameraSettings } from "@components/App";
import { useSize } from "@utils/useOffset";

type Props = {
    /** Enable shifted camera */
    enabled: boolean;
    /** Shift percentage in range [-1, 1], where 1 is positive edge of viewport, -1 is negative edge */
    shift: [x: number, y: number];
    /** Callback when shift has changed, with shift percent as first parameter and pixels in second */
    onChange?: (offset: [x: number, y: number], pixels: [x: number, y: number]) => void;
} & PerspectiveCameraProps;
export type { Props as ShiftedCameraProps };

export default function ShiftedCamera({ enabled, shift, onChange, ...camProps }: Props) {
    const camera = useRef<Camera>(null);

    const [width, height, factor] = useSize(0, camera.current);
    useEffect(() => {
        if (!camera.current) return;
        const offsetX = width * 0.5 * shift[0];
        const offsetY = height * 0.5 * shift[1];
        camera.current.setViewOffset(width, height, offsetX, offsetY, width, height);
        onChange?.([offsetX, offsetY], [-offsetX * factor, -offsetY * factor]);
    }, [enabled, width, height, factor, camera, shift, onChange]);

    if (!enabled) return null;
    return <PerspectiveCamera ref={camera} makeDefault={true} {...cameraSettings} {...camProps} />
}