import { DependencyList, PropsWithChildren, createContext, useEffect, useState } from "react";

export type AppContextProps = {
    style?: CSSStyleDeclaration | undefined;
    getCustomStyle: (prop: string) => string | undefined;
}

export const appContext: AppContextProps = {
    getCustomStyle: (prop: string) => {
        if (!appContext.style) return undefined;
        const value = appContext.style.getPropertyValue(`--${prop}`);
        if (!value) return undefined;
        return value;
    }
}

export const AppContext = createContext(appContext);

export function AppContextProvider({ root, deps, children }: { root?: HTMLElement | string | null, deps?: DependencyList } & PropsWithChildren) {
    const [init, setInit] = useState(false);
    if (!deps) deps = [];
    useEffect(() => {
        const el = (typeof root === "string" ? document.getElementById(root) : root) ?? document.documentElement;
        const style = getComputedStyle(el);
        appContext.style = style;
        setInit(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [root, ...deps]);

    return (
        <AppContext.Provider value={appContext}>
            {init && children}
        </AppContext.Provider>
    );
}