import styles from "@styles/Anchor.module.css";
import { AnchorHTMLAttributes } from "react";

type Props = {
    selectable?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>

export default function Anchor({ selectable = false, href, className, children, ...props }: Props) {
    const classes = [styles.root, className];
    if (selectable) classes.push(styles.selectable);
    return (
        <a target="_blank" rel="noreferrer" href={href} className={classes.join(" ")} {...props}>{children}</a>
    )
}