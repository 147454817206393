import { useCallback, useMemo } from "react";
import { Quaternion } from "three";
import { Interpolation, SpringValue, useSpring, SpringConfig } from "@react-spring/three";

type ExplodedQuaternion = {
    "quaternion-x": Interpolation<Quaternion, number>;
    "quaternion-y": Interpolation<Quaternion, number>;
    "quaternion-z": Interpolation<Quaternion, number>;
    "quaternion-w": Interpolation<Quaternion, number>;
}

type Input<T extends any[]> = {
    get: (...args: T) => Quaternion;
    from: T;
    to: T;
    config: SpringConfig;
};

type Output = [{ rotation: ExplodedQuaternion }, SpringValue<number>];

function explodeQuaternion(quat: Interpolation<number, Quaternion>) {
    return ({
        "quaternion-x": quat.to(q => q.x),
        "quaternion-y": quat.to(q => q.y),
        "quaternion-z": quat.to(q => q.z),
        "quaternion-w": quat.to(q => q.w),
    });
}

export default function useQuaternionSpring<T extends any[]>({ get, from, to, config }: Input<T>): Output {
    const [{ rotation }] = useSpring(() => ({
        from: { rotation: 0 },
        to: { rotation: 1 },
        config: config
    }), [config]);

    const [q1, q2] = useMemo(() => {
        const q1 = get(...from);
        const q2 = get(...to);
        rotation.reset();
        return [q1, q2];
    }, [get, from, to, rotation]);

    const workQuat = useMemo(() => new Quaternion(), []);
    const interp = useCallback((t: number) => workQuat.slerpQuaternions(q1, q2, t), [q1, q2, workQuat]);

    return [{ rotation: explodeQuaternion(rotation.to(interp)) }, rotation];
}