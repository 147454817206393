import styles from "@styles/Footer.module.css";
import { useContext, useMemo } from "react";
import { EPage } from "@pages/Pages";
import { PageModule } from "@pages/Page";
import { PagesContext } from "@contexts/PagesContext";
import { Instagram, LinkedIn } from "@components/Icons";
import Logo from "@components/Logo";
import Anchor from "@components/Anchor";

const page: EPage = EPage.Footer;
export default {
    Html,
    page
} as PageModule;

function Html() {
    const context = useContext(PagesContext);

    const pages = useMemo(() => {
        return [
            [EPage.Sonohaler],
            [EPage.Applications],
            [EPage.SonoOne],
            [EPage.SonoTwo],
            [EPage.Mission, EPage.Footer]
        ];
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.navigation}>
                    <h2>Navigation</h2>
                    {pages.map((names) => {
                        return <div key={names[0]} onClick={() => context?.setTarget(names[0])} className={styles.button}>{names[0]}</div>
                    })}
                </div>
                <div className={styles.app}>
                    <h2>Links</h2>
                    <Anchor href="https://app.sonohaler.com/terms-and-conditions">Terms and Conditions</Anchor>
                    <Anchor href="https://app.sonohaler.com/privacy-policy">Privacy Policy</Anchor>
                    <Anchor className={styles.login} href="https://app.sonohaler.com/signin">Login</Anchor>
                </div>
                <div className={styles.info}>
                    <h2>Information</h2>
                    <p>
                        Diplomvej 373, <br />
                        2800 Kongens Lyngby, <br />
                        Denmark.
                    </p>
                    <p>
                        CVR: 39304635
                    </p>
                </div>
                <div className={styles.socials}>
                    <h2>Social Networks</h2>
                    <div>
                        <Anchor href="https://www.instagram.com/sonohaler/"><Instagram /></Anchor>
                        <Anchor href="https://www.linkedin.com/company/sonohaler/about/?viewAsMember=true"><LinkedIn /></Anchor>
                    </div>
                </div>
                <div className={styles.about}>
                    <div className={styles.logo}><Logo /></div>
                    <p className={styles.description}>
                        Sonohaler is a digital health company dedicated to improving the lives of people with respiratory conditions.
                    </p>
                    <p className={styles.copyright}>
                        Copyright sonohaler.dk 2023 &copy; <br />Produced by aeroplane.be
                    </p>
                </div>
            </div>
        </div>
    );
}