import { useRef, useState, RefObject, useCallback } from "react";
import useResize from "@utils/useResize";
import { appContext } from "@contexts/AppContext";

type Props = {
    offset?: boolean;
    dynamic?: boolean;
}
export type { Props as SizeProps };

export default function useMeasure<T extends HTMLElement>({ offset = false, dynamic = false }: Props = {}) {
    const ref = useRef<T>(null);

    const [rect, setRect] = useState<DOMRect>(null!);
    const set = useCallback(() => {
        if (!ref.current) return;
        const scale = parseFloat(appContext.getCustomStyle("scale") ?? "1");
        const rect = ref.current.getBoundingClientRect();
        if (offset) {
            rect.width = ref.current.offsetWidth;
            rect.height = ref.current.offsetHeight;
        }
        if (dynamic) {
            rect.width /= scale;
            rect.height /= scale;
            rect.x /= scale;
            rect.y /= scale;
        }
        setRect(rect);
    }, [offset, dynamic]);
    useResize(set, true);

    return [ref, rect] as [RefObject<T>, DOMRect];
}