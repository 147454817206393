import styles from "@styles/MaskedArc.module.css"
import { CSSProperties, PropsWithChildren, ReactElement, useCallback, useMemo } from "react";
import { Matrix3, Vector2 } from "three";
import { a, AnimatedProps, FrameValue, Interpolation } from "@react-spring/web";
import Svg from "@components/Svg";
import { MathUtils } from "@utils/MathUtils";
import { OptArray, TSUtils } from "@utils/TSUtils";

export type MaskProps = {
    angle: number | FrameValue<number>;
    radius: number | FrameValue<number>;
} & PropsWithChildren;

type Props = {
    from: number;
    to: number;
    rx: number;
    ry?: number;
    rotate?: number;
    color?: string;
    strokeWidth?: number;
    children: OptArray<ReactElement<MaskProps>>;
};

type Point = { x: number, y: number };
enum EDirection { Forward = 1, Backward = -1 };

const corners: Point[] = [
    { x: -1, y: 1 },
    { x: -1, y: -1 },
    { x: 1, y: -1 },
    { x: 1, y: 1 },
];

function getCorners(angle: number, direction: EDirection, center: Point, size: number): Point[] {
    const d = direction as number;
    const first = d > 0
        ? (x: number) => Math.ceil((x - 45) / 90) + 2
        : (x: number) => Math.floor((x + 45) / 90) + 1;
    const cond = d > 0
        ? (x: number) => x < 4
        : (x: number) => x >= 0;
    const incr = (x: number) => x + d;
    const out: Point[] = [];
    for (let i = first(angle); cond(i); i = incr(i)) {
        out.push({ x: corners[i].x * size + center.x, y: corners[i].y * size + center.y });
    }
    return out;
}

const workingMat = new Matrix3();
const workingVec = new Vector2();
function prepare(angle: number, size: number) {
    workingMat.makeRotation(angle * MathUtils.deg2Rad);
    workingVec.set(0, -size).applyMatrix3(workingMat);
}

function getPoint(angle: number, center: Point, size: number): Point {
    prepare(angle, size);
    const a = 1 - Math.abs(MathUtils.mod(angle, 90) - 45) / 45;
    const scale = 1 / Math.cos(a);
    workingVec.multiplyScalar(scale);
    return { x: workingVec.x + center.x, y: workingVec.y + center.y };
}

function getCenter(angle: number, center: Point, size: number): Point {
    prepare(angle, size);
    return { x: workingVec.x + center.x, y: workingVec.y + center.y };
}

function sanitizeAngle(angle: number) {
    while (angle < -180) angle += 360;
    while (angle > 180) angle -= 360;
    return angle;
}

/**
 * Create a masked arc from `from` angle to `to` angle, with `rx` radius1 and `ry` radius2.
 * `ry` is equal to `rx` if omitted.
 * Top is considered `rotate` degrees (clockwise), angles are constrained to [-180, 180]
 * @returns Masked Arc SVG element
 */
export default function MaskedArc({ from = -180, to = 180, rx = 100, ry = rx, rotate = 0, color = "#ffffff", strokeWidth = 1, children }: Props) {
    from = sanitizeAngle(from);
    to = sanitizeAngle(to);
    if (from > to) [from, to] = [to, from];

    const [center, size, w, h] = useMemo(() => {
        const center: Point = { x: rx, y: ry };
        const size = Math.max(rx, ry);
        const w = rx * 2;
        const h = ry * 2;
        return [center, size, w, h];
    }, [rx, ry]);

    const points = useMemo(() => {
        const p1 = getPoint(from, center, size);
        const c1 = getCorners(from, EDirection.Backward, center, size);
        const c2 = getCorners(to, EDirection.Forward, center, size).reverse();
        const p2 = getPoint(to, center, size);
        const points = [center, p1, ...c1, ...c2, p2];
        return points.map(p => `${p.x},${p.y}`).join(" ");
    }, [from, to, center, size]);

    const rotation = useMemo(() => {
        const rotation: CSSProperties = {
            transformOrigin: "center",
            transform: `rotate(${rotate}deg)`
        }
        return rotation;
    }, [rotate]);

    const maskElements = useMemo(() => {
        const elements: JSX.Element[] = [];
        let i = 0;
        TSUtils.forEach(children, (mask) => {
            const angle = mask.props.angle instanceof FrameValue ? mask.props.angle.get() : mask.props.angle;
            const p = getCenter(angle, center, size);
            elements.push(<a.circle key={i++} cx={p.x} cy={p.y} r={mask.props.radius} fill="black" />);
        });
        return elements;
    }, [children, center, size]);

    const angleToPosition = useCallback((angle: number | FrameValue<number>): [left: string | Interpolation<number, string>, top: string | Interpolation] => {
        if (angle instanceof FrameValue) {
            const left = angle.to(a => {
                const p = getCenter(a, center, size);
                return `${p.x / rx * 50}%`;
            });
            const top = angle.to(a => {
                const p = getCenter(a, center, size);
                return `${p.y / ry * 50}%`;
            });
            return [left, top];
        } else {
            const p = getCenter(angle, center, size);
            return [`${p.x / rx * 50}%`, `${p.y / ry * 50}%`];
        }
    }, [center, size, rx, ry]);

    const radiusToSize = useCallback((radius: number | FrameValue<number>): string | Interpolation<number, string> => {
        if (radius instanceof FrameValue) {
            return radius.to(r => `${r * 2 / size * 50}%`);
        } else {
            return `${radius * 2 / size * 50}%`;
        }
    }, [size]);

    const childElements = useMemo(() => {
        const elements: JSX.Element[] = [];
        let i = 0;
        TSUtils.forEach(children, (mask) => {
            const [left, top] = angleToPosition(mask.props.angle);
            const size = radiusToSize(mask.props.radius);
            const style: AnimatedProps<CSSProperties> = {
                left: left, top: top,
                width: size, height: size,
            };
            elements.push(<a.div key={i++} className={styles.item} style={style}>{mask.props.children}</a.div>);
        });
        return elements;
    }, [children, angleToPosition, radiusToSize]);

    return (
        <div className={styles.root}>
            <Svg viewBox={`0 0 ${w} ${h}`}>
                <mask id="main">
                    <rect x="0" y="0" width={w} height={h} fill="white" />
                    <ellipse cx={rx} cy={ry} rx={rx - strokeWidth} ry={ry - strokeWidth} />
                    <polygon style={rotation} points={points} fill="black" />
                    {maskElements}
                </mask>
                <ellipse
                    cx={rx} cy={ry} rx={rx} ry={ry}
                    fill={color} mask="url(#main)"
                />
            </Svg>
            <div className={styles.content}>
                {childElements}
            </div>
        </div>
    )
}

export function Mask(props: MaskProps): JSX.Element { return <></>; };