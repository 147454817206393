import { forwardRef, MutableRefObject, PropsWithChildren } from "react"
// Use the modified drei View that allows global tracking
import { View } from "@components/lib/View";

type Props = {
    index?: number;
    frames?: number;
    clear?: boolean;
    alwaysUpdate?: boolean;
} & PropsWithChildren;

export default forwardRef<HTMLElement, Props>(function PointerView({
    index = 1,
    frames = Infinity,
    clear = false,
    children
}, ref) {
    return (
        // Wrap in scene to avoid views affecting each other
        <scene>
            <View track={ref as MutableRefObject<HTMLElement>} index={index} frames={frames} clear={clear}>
                {/* Fix the pointer events by stopping propagation inside the view 
                   https://codesandbox.io/s/bp6tmc?file=/src/Models.js:22 */}
                <group onPointerOver={(ev) => ev.stopPropagation()}>
                    {children}
                </group>
            </View>
        </scene>
    );
});