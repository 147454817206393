import { CSSUtils } from "@utils/CSSUtils";
import { OptArray } from "@utils/TSUtils";
import { ReactElement } from "react";

type Props = {
    viewBox: string;
    absolute?: boolean;
    children: OptArray<ReactElement<SVGElement>>;
};

export default function Svg({ viewBox, absolute = false, children }: Props) {
    let [x, y, w, h] = viewBox.split(" ");
    x = CSSUtils.parseSize(`${x}rem`).toString();
    y = CSSUtils.parseSize(`${y}rem`).toString();
    w = absolute ? CSSUtils.parseSize(`${w}rem`).toString() : "100%";
    h = absolute ? CSSUtils.parseSize(`${h}rem`).toString() : "100%";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} x={x} y={y} width={w} height={h}>
            {children}
        </svg>
    )
}