import { ReactHTML, createElement, useEffect, useMemo, useState } from "react";
import { animated } from "@react-spring/web";
import { MathUtils } from "@utils/MathUtils";

type Props = {
    type: keyof ReactHTML;
    text: string;
    percent: number;
    occupy?: boolean;
} & React.ClassAttributes<HTMLElement> & React.HTMLAttributes<HTMLElement>;

export default function TypeEffect({ type, text: target, percent, occupy = false, ...props }: Props) {
    const [text, setText] = useState("");
    percent = MathUtils.clamp(percent, 0, 1);

    useEffect(() => {
        const end = Math.floor(target.length * percent);
        const s = end > 0 ? target.substring(0, end) : occupy ? "\u2002" : "";
        setText(s);
    }, [target, percent, occupy]);

    const element = useMemo(() => {
        return createElement(type, props, text);
    }, [type, props, text]);

    return element;
}

export const AnimatedTypeEffect = animated(TypeEffect);