import { Ref, Suspense, useMemo } from "react";
import { Group, Mesh, Vector3 } from "three";
import { animated } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { TSUtils } from "@utils/TSUtils";
import useMesh, { MeshOptions } from "@utils/useMesh";
import useOpacity from "@utils/useOpacity";

type Props = {
    opacity?: number;
    groupRef?: Ref<Group>;
} & GroupProps & MeshOptions;

const turbohaler = `${process.env.PUBLIC_URL}/models/Turbohaler-0v7.glb`;
useGLTF.preload(turbohaler);
export const Turbohaler = animated(function ({ opacity = 1, groupRef, ...props }: Props) {
    const turbohalerMesh = useTurbohaler({ opacity, ...props });

    return (
        <Suspense>
            <animated.group ref={groupRef} {...props}>
                <primitive object={turbohalerMesh} />
            </animated.group>
        </Suspense>
    );
});

export function useTurbohaler({ opacity = 1, ...props }: { opacity?: number } & MeshOptions) {
    const [turbohalerMesh] = useMesh(turbohaler, { ...props, copyHierarchy: true, copyMaterial: true });
    const mesh = useMemo(() => {
        let mesh: Mesh = null!;
        turbohalerMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            mesh = child;
        });
        mesh.position.copy(mesh.getWorldPosition(new Vector3()));
        mesh.updateMatrix();
        return mesh;
    }, [turbohalerMesh]);
    useOpacity(mesh.material, opacity, true);
    return mesh;
};