import { useCallback, useState, RefObject } from "react";
import useActiveEvent from "@utils/useActiveEvent";

export default function useDrag<T extends HTMLElement>(onDrag: (amount: number, ev: PointerEvent) => void) {
    const [dragging, setDragging] = useState<number | undefined>(undefined);

    const dragStart = useCallback((ev: PointerEvent) => {
        setDragging(ev.clientY);
    }, []);

    const dragEnd = useCallback((ev: PointerEvent) => {
        if (dragging === undefined) return;
        const delta = ev.clientY - dragging;
        if (Math.abs(delta) > window.innerHeight * 0.1) {
            // Invert drag to imitate scroll
            onDrag(-delta, ev);
        }
        setDragging(undefined);
    }, [onDrag, dragging]);

    const ref = useActiveEvent<T, "pointerdown">("pointerdown", dragStart);
    useActiveEvent<HTMLElement, "pointerup">("pointerup", dragEnd);

    return [ref, dragging !== undefined] as [RefObject<T>, boolean];
}