import styles from "@styles/Card.module.css";
import { forwardRef, ForwardedRef, PropsWithChildren, DOMAttributes } from "react";

export default forwardRef(
    function Card({ children, ...props }: DOMAttributes<HTMLDivElement> & PropsWithChildren,
        ref: ForwardedRef<HTMLDivElement>
    ) {
        return (
            <div className={styles.root}>
                <div ref={ref} className={styles.content} {...props}>
                    {children}
                </div>
            </div>
        );
    }
);