import styles from "@styles/Applications.module.css";
import { Dispatch, ReactElement, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from "react";
import { config, easings, SpringValue, useSpring, useSpringRef, useChain } from "@react-spring/three";
import { a } from "@react-spring/web";
import { Quaternion, Vector3 } from "three";
import { Environment, useTexture } from "@react-three/drei";
import type { CanvasProps, HtmlProps, PageModule } from "@pages/Page";
import { EPage } from "@pages/Pages";
import Card from "@components/Card";
import PointerView from "@components/PointerView";
import ScrollInstructions from "@components/ScrollInstructions";
import ShiftedCamera from "@components/ShiftedCamera";
import Button from "@components/Button";
import FollowMouse from "@components/FollowMouse";
import { AnimatedContactShadows } from "@components/lib/ContactShadows";
import { AnimatedTypeEffect } from "@components/TypeEffect";
import Measure, { useMeasure } from "@components/Measure";
import { Phone } from "@components/Phone";
import { Silhouette } from "@components/Silhouette";
import { MDInhaler } from "@components/MDInhaler";
import MaskedArc, { Mask, MaskProps } from "@components/MaskedArc";
import { ColorProps, Insights, Performance, Predictions, Simulations, Wearables } from "@components/Icons";
import { Initializer, ShiftingWave } from "@components/Wave";
import Video, { useVideoTexture } from "@components/Video";
import { LoadingContext } from "@contexts/LoadingContext";
import { ApplicationsContext } from "@contexts/ApplicationsContext";
import { Easing } from "@animations/Easing";
import { MathUtils } from "@utils/MathUtils";
import { useViewport } from "@utils/ViewportManager";
import { ThreeUtils } from "@utils/ThreeUtils";
import useQuaternionSpring from "@utils/useQuaternionSpring";
import usePositionSpring from "@utils/usePositionSpring";
import { EnumDictionary } from "@utils/TSUtils";
import { shadowLayer } from "@utils/Settings";
import useOffset from "@utils/useOffset";
import { EPerformanceMode } from "@utils/usePerformance";
import data from "@data/Text.json";
const text = data.Applications;

const page: EPage = EPage.Applications;
export default {
    Html,
    Canvas,
    page
} as PageModule;

export enum EFeature {
    None = 1,
    Monitoring = 2,
    Diagnostics = 3,
    DataAnalytics = 4
};

export enum EAnalytic {
    None = 0,
    Insights = 1,
    Simulations = 2,
    Predictions = 4,
    Wearables = 8,
    Performance = 16
};

const analyticTitle: EnumDictionary<EAnalytic, string> = {
    [EAnalytic.None]: "",
    [EAnalytic.Insights]: text.Analytics.Insights,
    [EAnalytic.Simulations]: text.Analytics.Simulations,
    [EAnalytic.Predictions]: text.Analytics.Predictions,
    [EAnalytic.Wearables]: text.Analytics.Wearables,
    [EAnalytic.Performance]: text.Analytics.Performance,
}

function Html(props: HtmlProps) {
    const { loaded } = useContext(LoadingContext);
    const context = useContext(ApplicationsContext);
    const { state, dispatch } = context || {};
    const [view] = useViewport<HTMLDivElement>(page);

    const [analytic, setAnalytic] = useState(EAnalytic.Insights);

    const [noneSize, setNoneSize] = useMeasure();
    const [monitoringSize, setMonitoringSize] = useMeasure();
    const [diagnosticsSize, setDiagnosticsSize] = useMeasure();
    const [dataAnalyticsSize, setDataAnalyticsSize] = useMeasure();

    const getFeatureHeight = useCallback((feature: EFeature | undefined) => {
        switch (feature) {
            default: case EFeature.None: return noneSize.height;
            case EFeature.Monitoring: return monitoringSize.height;
            case EFeature.Diagnostics: return diagnosticsSize.height;
            case EFeature.DataAnalytics: return dataAnalyticsSize.height;
        }
    }, [noneSize.height, monitoringSize.height, diagnosticsSize.height, dataAnalyticsSize.height]);

    const [{ featureHeight }] = useSpring(() => ({
        featureHeight: getFeatureHeight(state?.feature),
        delay: state?.feature === EFeature.None ? 500 : 0,
        config: { duration: 500, easing: easings.easeOutQuad, precision: 0.001 },
    }), [getFeatureHeight, state?.feature]);

    const [{
        noneOpacity,
        monitoringOpacity,
        diagnosticsOpacity,
        dataAnalyticsOpacity,
    }] = useSpring(() => ({
        noneOpacity: state?.feature === EFeature.None ? 1 : 0,
        monitoringOpacity: state?.feature === EFeature.Monitoring ? 1 : 0,
        diagnosticsOpacity: state?.feature === EFeature.Diagnostics ? 1 : 0,
        dataAnalyticsOpacity: state?.feature === EFeature.DataAnalytics ? 1 : 0,
        config: { duration: 500, precision: 0.001 },
    }), [state?.feature]);

    const [{ analyticsOverlayOpacity }] = useSpring(() => ({
        analyticsOverlayOpacity: state?.feature === EFeature.DataAnalytics ? 1 : 0,
        delay: state?.feature === EFeature.DataAnalytics ? 2000 : 0,
        config: { duration: 500, precision: 0.001 },
    }), [state?.feature]);


    const [{ analyticTitleOpacity }] = useSpring(() => ({
        from: { analyticTitleOpacity: 0 },
        to: { analyticTitleOpacity: 1 },
        reset: true,
        config: { duration: 500, precision: 0.001 }
    }), [analytic]);

    const titleSpring = useSpringRef();
    const subtitle1Spring = useSpringRef();
    const subtitle2Spring = useSpringRef();
    const innerSpring = useSpringRef();
    const scrollSpring = useSpringRef();
    const [{ titlePercent }] = useSpring(() => ({
        titlePercent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 300, precision: 0.001 },
        ref: titleSpring
    }), [state?.index]);
    const [{ subtitle1Percent }] = useSpring(() => ({
        subtitle1Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        delay: 200,
        config: { duration: 200, precision: 0.001 },
        ref: subtitle1Spring
    }), [state?.index]);
    const [{ subtitle2Percent }] = useSpring(() => ({
        subtitle2Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 400, precision: 0.001 },
        ref: subtitle2Spring
    }), [state?.index]);
    const [{ innerOpacity }] = useSpring(() => ({
        innerOpacity: (state?.index ?? 0) > 0 ? 1 : 0,
        delay: 200,
        config: { duration: 100, precision: 0.001 },
        ref: innerSpring
    }), [state?.index]);
    const [{ showScroll }] = useSpring(() => ({
        showScroll: (state?.index ?? 0) > 0 ? true : false,
        delay: 200,
        config: { duration: 1 },
        ref: scrollSpring
    }), [state?.index]);
    useChain([titleSpring, subtitle1Spring, subtitle2Spring, innerSpring, scrollSpring]);

    useEffect(() => {
        let index = 0;
        if (loaded && state?.shown) index = 1;
        if (state?.feature !== EFeature.None) index = 2;
        dispatch?.({ type: "set", index: index });
    }, [dispatch, loaded, state?.shown, state?.index, state?.feature]);

    useEffect(() => {
        if (!props.active) return;
        dispatch?.({ type: "show" });
        dispatch?.({ type: "feature", feature: EFeature.None });
    }, [dispatch, props.active]);

    const click = useCallback((feature: EFeature) => {
        dispatch?.({ type: "feature", feature: feature });
    }, [dispatch]);

    const featureStyle = useCallback((feature: EFeature, opacity: SpringValue<number>) => {
        const active = feature === state?.feature;
        const ease = (t: number) => t;
        const tOut = (t: number) => MathUtils.mapRange(t, 1, 0.5, 1, 0, true);
        const tIn = (t: number) => MathUtils.mapRange(t, 0.5, 1, 0, 1, true);
        const transform = active ? tIn : tOut;
        return {
            opacity: opacity.to(t => ease(transform(t))),
            order: opacity.to(t => 1 - Math.round(t))
        };
    }, [state?.feature]);

    return (
        <div className={styles.root}>
            <Card>
                <div className={styles.static}>
                    <div className={styles.staticContent}>
                        <div className={styles.header}>
                            <AnimatedTypeEffect type="h1" text={text.title1} percent={titlePercent} /><br />
                            <AnimatedTypeEffect type="h2" className="accent1" text={text.subtitle1} percent={subtitle1Percent} occupy />
                            <AnimatedTypeEffect type="h2" text={text.subtitle2} percent={subtitle2Percent} />
                        </div>
                        <a.div className={styles.feature} style={{ opacity: innerOpacity, height: featureHeight.to(value => `${value}rem`) }}>
                            <Measure setSize={setNoneSize} offset dynamic>
                                <a.div style={featureStyle(EFeature.None, noneOpacity)}>
                                    <p>{text.text1}</p>
                                </a.div>
                            </Measure>
                            <Measure setSize={setMonitoringSize} offset dynamic>
                                <a.div style={featureStyle(EFeature.Monitoring, monitoringOpacity)}>
                                    <h1 className={styles.featureTitle}>{text.Monitoring.title1}</h1>
                                    <h2>{text.Monitoring.subtitle1}</h2>
                                    <p>{text.Monitoring.text1}</p>
                                </a.div>
                            </Measure>
                            <Measure setSize={setDiagnosticsSize} offset dynamic>
                                <a.div style={featureStyle(EFeature.Diagnostics, diagnosticsOpacity)}>
                                    <h1 className={styles.featureTitle}>{text.Diagnostics.title1}</h1>
                                    <h2>{text.Diagnostics.subtitle1}</h2>
                                    <p>{text.Diagnostics.text1}</p>
                                </a.div>
                            </Measure>
                            <Measure setSize={setDataAnalyticsSize} offset dynamic>
                                <a.div style={featureStyle(EFeature.DataAnalytics, dataAnalyticsOpacity)}>
                                    <h1 className={styles.featureTitle}>{text.DataAnalytics.title1}</h1>
                                    <h2>{text.DataAnalytics.subtitle1}</h2>
                                    <p>{text.DataAnalytics.text1}</p>
                                </a.div>
                            </Measure>
                        </a.div>
                    </div>
                </div>
                <div ref={view} className={styles.dynamic}></div>
                <a.div className="scroll" style={{ opacity: showScroll.to(value => value ? "1" : "0") }}>
                    <ScrollInstructions mobile={props.mobile} />
                </a.div>
                <div className={styles.canvasOverlay} style={{ transform: props.mobile ? `translateY(${state?.offset ?? 0}px)` : `translateX(${state?.offset ?? 0}px)` }}>
                    <div className={styles.analyticsOverlay}>
                        <a.div style={{ opacity: analyticsOverlayOpacity }}>
                            <MaskedArc from={-120} to={120} rx={270} color="#26ffff" strokeWidth={3}>
                                {Analytic({ angle: -105, analytic: EAnalytic.Wearables, Icon: Wearables, active: analytic, setActive: setAnalytic })}
                                {Analytic({ angle: -52.5, analytic: EAnalytic.Simulations, Icon: Simulations, active: analytic, setActive: setAnalytic })}
                                {Analytic({ angle: 0, analytic: EAnalytic.Insights, Icon: Insights, active: analytic, setActive: setAnalytic })}
                                {Analytic({ angle: 52.5, analytic: EAnalytic.Predictions, Icon: Predictions, active: analytic, setActive: setAnalytic })}
                                {Analytic({ angle: 105, analytic: EAnalytic.Performance, Icon: Performance, active: analytic, setActive: setAnalytic })}
                            </MaskedArc>
                            <a.div className={`${styles.analyticTitle}`} style={{ opacity: analyticTitleOpacity }}>
                                <h1 className="accent1">{analyticTitle[analytic]}</h1>
                            </a.div>
                        </a.div>
                    </div>
                    <div className={styles.buttons}>
                        <Button label={text.Monitoring.button} active={state?.feature === EFeature.Monitoring} onClick={() => click(EFeature.Monitoring)} />
                        <Button label={text.Diagnostics.button} active={state?.feature === EFeature.Diagnostics} onClick={() => click(EFeature.Diagnostics)} />
                        <Button label={text.DataAnalytics.button} active={state?.feature === EFeature.DataAnalytics} onClick={() => click(EFeature.DataAnalytics)} />
                    </div>
                </div>
                <Video src={monitoringVideo} resolution={props.mobile ? 512 : 1024}
                    muted loop restart hidden texture
                    play={state?.feature === EFeature.Monitoring}
                />
                <Video src={diagnosticsVideo} resolution={props.mobile ? 512 : 1024}
                    muted loop restart hidden texture
                    play={state?.feature === EFeature.Diagnostics}
                />
            </Card>
        </div>
    );
}

function Analytic({ angle, analytic, Icon, active, setActive }: {
    angle: number;
    analytic: EAnalytic;
    Icon: (props: Omit<ColorProps, "background">) => JSX.Element;
    active: EAnalytic;
    setActive: Dispatch<SetStateAction<EAnalytic>>;
}): ReactElement<MaskProps> {
    const [isHovered, setHovered] = useState(false);
    const isActive = analytic === active;

    const [{ radius }] = useSpring(() => ({
        radius: isHovered || isActive ? 37.5 : 22.5,
        config: { ...config.wobbly, precision: 0.001 }
    }), [isHovered, isActive]);

    const [{ color }] = useSpring(() => ({
        color: isActive ? "#26ffff" : "#ffffff",
        config: { ...config.gentle, precision: 0.001 }
    }), [isActive]);

    const AnimatedIcon = a(Icon);

    return (
        <Mask angle={angle} radius={radius}>
            <div className={styles.icon}
                onPointerEnter={() => setHovered(true)}
                onPointerLeave={() => setHovered(false)}
                onClick={() => setActive(analytic)}
            >
                <AnimatedIcon color={color} />
            </div>
        </Mask>
    );
}

function Canvas(props: CanvasProps) {
    const [view] = useViewport<HTMLDivElement>(page);

    const context = useContext(ApplicationsContext);
    const { dispatch, state } = context || {};
    const onChange = useCallback((offset: [x: number, y: number], pixels: [x: number, y: number]) => {
        dispatch?.({ type: "offset", offset: props.mobile ? pixels[1] : pixels[0] });
    }, [dispatch, props.mobile]);

    const shift: [x: number, y: number] = props.mobile ? [0, 0.45] : [0.5, 0];
    const z: number = props.mobile ? state?.feature === EFeature.DataAnalytics ? 17 : 15 : 10;

    const mouseInfluence: [x: number, y: number] = state?.feature === EFeature.DataAnalytics
        ? props.mobile ? [0.60, 0.20] : [0.30, 0.20]
        : props.mobile ? [0.30, 0.15] : [0.10, 0.05];

    return (
        <PointerView ref={view} alwaysUpdate={false} clear={true}>
            <ShiftedCamera enabled={props.active} shift={shift} fov={40} position-z={z} onChange={onChange} />
            {(props.active || props.mode !== EPerformanceMode.Performance) && <>
                <directionalLight intensity={1.0} position={[0.1, 5, -0.1]} castShadow />
                <ambientLight intensity={0.2} />
                <Environment files={`${process.env.PUBLIC_URL}/textures/city.exr`} />
                <FollowMouse influence={mouseInfluence} ease={Easing.inoutQuad}>
                    <InhaleSilhouette />
                    <ApplicationsPhone mobile={props.mobile} />
                    <ApplicationsMDIInhaler />
                </FollowMouse>
                <ClinicianSilhouette mobile={props.mobile} />
                <PatientSilhouette mobile={props.mobile} />
                <Waves mobile={props.mobile} />
                <Shadows />
            </>}
        </PointerView>
    );
}

function Shadows() {
    const context = useContext(ApplicationsContext);
    const { state } = context || {};

    const getPosition = useCallback((feature: EFeature | undefined) => {
        switch (feature) {
            default: case EFeature.None: return -1.5;
            case EFeature.Monitoring: return -1.5;
            case EFeature.Diagnostics: return -1.5;
            case EFeature.DataAnalytics: return -1.75;
        }
    }, []);

    const [{ opacity }] = useSpring(() => ({
        opacity: state?.feature !== EFeature.Monitoring ? 0.5 : 0,
        config: config.gentle
    }), [state?.feature]);

    return (
        <AnimatedContactShadows position-y={getPosition(state?.feature)} scale={5} blur={5} far={4} opacity={opacity} layers={shadowLayer} />
    );
}

const inhaleSilhouette = `${process.env.PUBLIC_URL}/textures/T_Silhouette_Inhale.png`;
useTexture.preload(inhaleSilhouette);
function InhaleSilhouette() {
    const image = useTexture(inhaleSilhouette);

    const context = useContext(ApplicationsContext);
    const { state } = context || {};

    const [{ opacity }] = useSpring(() => ({
        opacity: state?.feature === EFeature.Monitoring ? 1 : 0,
        config: config.gentle
    }), [state?.feature]);

    return (
        <Silhouette image={image} opacity={opacity} scale={11} position={[-1.7, -0.4, -0.1]} />
    );
}

const welcomeScreen = `${process.env.PUBLIC_URL}/textures/T_IphoneScreen_Welcome_A.png`;
const monitoringVideo = `${process.env.PUBLIC_URL}/videos/SA-Monitoring-0v5@$res`;
const diagnosticsVideo = `${process.env.PUBLIC_URL}/videos/SA-Diagnostics-0v3@$res`;
useTexture.preload(welcomeScreen);
function ApplicationsPhone({ mobile }: { mobile: boolean }) {
    const context = useContext(ApplicationsContext);
    const { state } = context || {};

    const welcomeTexture = useTexture(welcomeScreen);
    const monitoringTexture = useVideoTexture(monitoringVideo, mobile ? 512 : 1024);
    const diagnosticsTexture = useVideoTexture(diagnosticsVideo, mobile ? 512 : 1024);

    const getPosition = useCallback((feature: EFeature | undefined): Vector3 => {
        switch (feature) {
            default: case EFeature.None:
                return ThreeUtils.toVec3([-0.7, -0.7, -1]);
            case EFeature.Monitoring:
                return ThreeUtils.toVec3([0.7, -0.7, 1]);
            case EFeature.Diagnostics:
                return ThreeUtils.toVec3([-0.6, -0.7, -1]);
            case EFeature.DataAnalytics:
                return ThreeUtils.toVec3([0, -1.25, 0]);
        }
    }, []);

    const getRotation = useCallback((feature: EFeature | undefined): Quaternion => {
        switch (feature) {
            default: case EFeature.None:
                return ThreeUtils.toQuat([-5, 18, 5, "YXZ"], true);
            case EFeature.Monitoring:
                return ThreeUtils.toQuat([0, -10, 0, "YXZ"], true);
            case EFeature.Diagnostics:
                return ThreeUtils.toQuat([-10, -18, 0, "YXZ"], true);
            case EFeature.DataAnalytics:
                return ThreeUtils.toQuat([0, 0, 0, "YXZ"], true);
        }
    }, []);

    const getScale = useCallback((feature: EFeature | undefined): number => {
        switch (feature) {
            default: case EFeature.None: return 32;
            case EFeature.Monitoring: return 27;
            case EFeature.Diagnostics: return 32;
            case EFeature.DataAnalytics: return 25;
        }
    }, []);

    const getBrightness = useCallback((feature: EFeature | undefined) => {
        switch (feature) {
            default: case EFeature.None: return 1;
            case EFeature.Monitoring: return 1;
            case EFeature.Diagnostics: return 1;
            case EFeature.DataAnalytics: return 1;
        }
    }, []);

    const getImage = useCallback((feature: EFeature | undefined) => {
        switch (feature) {
            default: case EFeature.None: return welcomeTexture;
            case EFeature.Monitoring: return monitoringTexture.current;
            case EFeature.Diagnostics: return diagnosticsTexture.current;
            case EFeature.DataAnalytics: return welcomeTexture;
        }
    }, [welcomeTexture, monitoringTexture, diagnosticsTexture]);

    const [{ position }] = usePositionSpring({
        get: getPosition,
        from: [state?.prevFeature],
        to: [state?.feature],
        config: { ...config.wobbly, precision: 0.001 }
    });

    const [{ rotation }] = useQuaternionSpring({
        get: getRotation,
        from: [state?.prevFeature],
        to: [state?.feature],
        config: { ...config.wobbly, precision: 0.001 }
    });

    const [{ scale }] = useSpring(() => ({
        from: { scale: getScale(state?.prevFeature) },
        to: { scale: getScale(state?.feature) },
        config: { ...config.wobbly, precision: 0.001 }
    }), [state?.prevFeature, state?.feature]);

    const [{ brightness }] = useSpring(() => ({
        from: { brightness: 0 },
        to: { brightness: getBrightness(state?.feature) },
        reset: true,
        config: { ...config.gentle, precision: 0.001 }
    }), [state?.feature]);

    return (
        <Phone shadow
            image={getImage(state?.feature)}
            brightness={brightness}
            {...position}
            {...rotation}
            scale={scale}
            renderOrder={1}
        />
    );
}

function ApplicationsMDIInhaler() {
    const context = useContext(ApplicationsContext);
    const { state } = context || {};

    const getPosition = useCallback((feature: EFeature | undefined): Vector3 => {
        switch (feature) {
            default: case EFeature.None:
                return ThreeUtils.toVec3([0.7, -0.5, 0]);
            case EFeature.Monitoring:
                return ThreeUtils.toVec3([-1, -0.35, 0]);
            case EFeature.Diagnostics:
                return ThreeUtils.toVec3([0.9, -0.5, -2]);
            case EFeature.DataAnalytics:
                return ThreeUtils.toVec3([0, -0.5, -3]);
        }
    }, []);

    const getRotation = useCallback((feature: EFeature | undefined): Quaternion => {
        switch (feature) {
            default: case EFeature.None:
                return ThreeUtils.toQuat([-2, -40, -5, "YXZ"], true);
            case EFeature.Monitoring:
                return ThreeUtils.toQuat([-10, -90, 0, "YXZ"], true);
            case EFeature.Diagnostics:
                return ThreeUtils.toQuat([0, 40, 0, "YXZ"], true);
            case EFeature.DataAnalytics:
                return ThreeUtils.toQuat([0, 0, 0, "YXZ"], true);
        }
    }, []);

    const getScale = useCallback((feature: EFeature | undefined): number => {
        switch (feature) {
            default: case EFeature.None: return 30;
            case EFeature.Monitoring: return 23;
            case EFeature.Diagnostics: return 30;
            case EFeature.DataAnalytics: return 20;
        }
    }, []);

    const getOpacity = useCallback((feature: EFeature | undefined): number => {
        switch (feature) {
            default: case EFeature.None:
                return 1;
            case EFeature.Monitoring:
                return 1;
            case EFeature.Diagnostics:
                return 1;
            case EFeature.DataAnalytics:
                return 0;
        }
    }, []);

    const [{ position }] = usePositionSpring({
        get: getPosition,
        from: [state?.prevFeature],
        to: [state?.feature],
        config: { ...config.wobbly, precision: 0.001 }
    });

    const [{ rotation }] = useQuaternionSpring({
        get: getRotation,
        from: [state?.prevFeature],
        to: [state?.feature],
        config: { ...config.wobbly, precision: 0.001 }
    });

    const [{ scale }] = useSpring(() => ({
        from: { scale: getScale(state?.prevFeature) },
        to: { scale: getScale(state?.feature) },
        config: { ...config.wobbly, precision: 0.001 }
    }), [state?.prevFeature, state?.feature]);

    const [{ opacity }] = useSpring(() => ({
        opacity: getOpacity(state?.feature),
        config: { duration: 500, easing: easings.easeOutQuad, precision: 0.001 }
    }), [state?.feature]);

    return (
        <MDInhaler smart shadow
            opacity={opacity}
            {...position}
            {...rotation}
            scale={scale}
        />
    );
}

const clinicianSilhouette = `${process.env.PUBLIC_URL}/textures/T_Silhouette_Clinician.png`;
useTexture.preload(clinicianSilhouette);
function ClinicianSilhouette({ mobile }: { mobile: boolean }) {
    const image = useTexture(clinicianSilhouette);

    const context = useContext(ApplicationsContext);
    const { state } = context || {};

    const [x, y] = useOffset([mobile ? -0.75 : -0.5, 0]);

    const [{ opacity }] = useSpring(() => ({
        opacity: state?.feature === EFeature.DataAnalytics ? 0.5 : 0,
        delay: state?.feature === EFeature.DataAnalytics ? 500 : 0,
        config: config.gentle
    }), [state?.feature]);

    return (
        <Silhouette image={image} opacity={opacity} scale={4} position={[x, y, -2]} />
    );
}

const patientSilhouette = `${process.env.PUBLIC_URL}/textures/T_Silhouette_Patient.png`;
useTexture.preload(patientSilhouette);
function PatientSilhouette({ mobile }: { mobile: boolean }) {
    const image = useTexture(patientSilhouette);

    const context = useContext(ApplicationsContext);
    const { state } = context || {};

    const [x, y] = useOffset([mobile ? 0.75 : 0.5, 0]);

    const [{ opacity }] = useSpring(() => ({
        opacity: state?.feature === EFeature.DataAnalytics ? 0.5 : 0,
        delay: state?.feature === EFeature.DataAnalytics ? 500 : 0,
        config: config.gentle
    }), [state?.feature]);

    return (
        <Silhouette image={image} opacity={opacity} scale={4} position={[x, y, -2]} />
    );
}

function Waves({ mobile }: { mobile: boolean }) {
    const context = useContext(ApplicationsContext);
    const { state } = context || {};

    const left = useRef<Initializer>(null);
    const right = useRef<Initializer>(null);

    const initialize = useCallback(() => {
        left.current?.();
        right.current?.();
    }, [left, right]);

    const [{ opacity }] = useSpring(() => ({
        opacity: state?.feature === EFeature.DataAnalytics ? 1 : 0,
        config: { duration: state?.feature === EFeature.DataAnalytics ? 500 : 100, precision: 0.001 },
        onStart: initialize
    }), [state?.feature]);

    return (
        <group position={[0, 0, -1]}>
            <ShiftingWave count={14} steps={7} size={4} screenSize={mobile ? 0.33 : 0.25} direction={1} opacity={opacity} shiftOpacity={1} amplitude={6} scale={0.08} initializer={left} />
            <ShiftingWave count={14} steps={7} size={4} screenSize={mobile ? 0.33 : 0.25} direction={-1} opacity={opacity} shiftOpacity={1} amplitude={6} scale={0.08} initializer={right} />
        </group>
    );
}