import { RefObject, Suspense, useEffect, useMemo } from "react";
import { BufferAttribute, BufferGeometry, Group, Vector3 } from "three";
import { GroupProps, useFrame } from "@react-three/fiber";
import { animated, easings } from "@react-spring/three";
import { PhasedDisplaceLineMaterial } from "@shaders/PhasedDisplaceLine/PhasedDisplaceLineMaterialShader";
import { MathUtils } from "@utils/MathUtils";
import { useLineGeometry } from "@utils/useMesh";
import { speed } from "@utils/Settings";
import useMappedEasing from "@utils/useMappedEasing";
import useOpacity from "@utils/useOpacity";

export type CircleProps = {
    samples: number;
    radius: number;
    amplitude: number;
    shift: number;
    opacity?: number;
    groupRef?: RefObject<Group>;
} & Omit<GroupProps, "ref">;

export const Circle = animated(function Circle({ samples, radius, amplitude, shift, opacity = 1, groupRef, ...props }: CircleProps) {
    const ease = useMappedEasing([
        { start: 0.00, end: 0.70, from: 0.0, to: 0.0 },
        { start: 0.70, end: 1.00, from: 0.0, to: 1.0, easing: easings.easeInOutSine }
    ]);

    const geom = useMemo(() => {
        const step = 360 / samples;
        const points = new Array<Vector3>(samples + 1);
        const colors = new Float32Array((samples + 1) * 3);

        for (let i = 0; i <= samples; ++i) {
            const angle = step * i;
            const x = Math.cos(angle * MathUtils.deg2Rad) * radius;
            const z = Math.sin(angle * MathUtils.deg2Rad) * radius;
            points[i] = new Vector3(x, 0, z);

            const a = Math.abs(angle - 180);
            const infl = MathUtils.mapRanges(a, [0, 90, 180], [0, 1, 0], true);
            colors[i * 3 + 0] = ease(infl) * 0.5 + 0.5;
            colors[i * 3 + 1] = shift;
            colors[i * 3 + 2] = 0;
        }
        const geom = new BufferGeometry().setFromPoints(points);
        geom.setAttribute("color", new BufferAttribute(colors, 3));
        return geom;
    }, [samples, radius, shift, ease]);

    const mat = useMemo(() => {
        return new PhasedDisplaceLineMaterial({ color: "#26ffff", amplitude: 0, transparent: true, opacity: 1, depthWrite: false });
    }, []);

    useOpacity(mat, opacity, false);

    useEffect(() => {
        mat.amplitude = amplitude;
    }, [mat, amplitude]);

    const circle = useLineGeometry(geom, mat);
    if (props.renderOrder) circle.renderOrder = props.renderOrder;

    useFrame(({ clock }) => {
        mat.phase = clock.elapsedTime * speed;
    });

    return (
        <Suspense>
            <primitive ref={groupRef} object={circle} {...props} />
        </Suspense>
    );
});