import { Euler, Vector2, Vector3, Vector4 } from "@react-three/fiber";
import { Euler as Euler_, Quaternion, Vector, Vector2 as Vector2_, Vector3 as Vector3_, Vector4 as Vector4_ } from "three";
import { isNumber } from "lodash";
import { MathUtils } from "@utils/MathUtils";

export type QuatArray = [x: number, y: number, z: number, w: number];

export class ThreeUtils {
    static toRadians(rotation: Euler) {
        const c = MathUtils.deg2Rad;
        if (Array.isArray(rotation)) {
            for (let i = 0; i < Math.min(3, rotation.length); ++i) {
                (rotation[i] as number) *= c;
            }
        } else {
            rotation.x *= c;
            rotation.y *= c;
            rotation.z *= c;
        }
        return rotation;
    }

    static toDegrees(rotation: Euler) {
        const c = MathUtils.rad2Deg;
        if (Array.isArray(rotation)) {
            for (let i = 0; i < Math.min(3, rotation.length); ++i) {
                (rotation[i] as number) *= c;
            }
        } else {
            rotation.x *= c;
            rotation.y *= c;
            rotation.z *= c;
        }
        return rotation;
    }

    static toVec2(vector: Vector2) {
        if (Array.isArray(vector)) return new Vector2_(...vector);
        if (typeof vector === "number") return new Vector2_(vector, vector);
        return vector as Vector2_;
    }

    static toVec3(vector: Vector3) {
        if (Array.isArray(vector)) return new Vector3_(...vector);
        if (typeof vector === "number") return new Vector3_(vector, vector, vector);
        return vector as Vector3_;
    }

    static toVec4(vector: Vector4) {
        if (Array.isArray(vector)) return new Vector4_(...vector);
        if (typeof vector === "number") return new Vector4_(vector, vector, vector, vector);
        return vector as Vector4_;
    }

    static toQuat(rotation: Euler, degrees: boolean = false) {
        if (degrees) rotation = ThreeUtils.toRadians(rotation);
        let euler: Euler_;
        if (Array.isArray(rotation)) euler = new Euler_(rotation[0], rotation[1], rotation[2], rotation[3]);
        else if (rotation === undefined) euler = new Euler_();
        else euler = rotation;
        const quat = new Quaternion().setFromEuler(euler);
        return quat;
    }

    static toQuatArray(rotation: Euler, degrees: boolean = false) {
        const q = ThreeUtils.toQuat(rotation, degrees);
        return [q.x, q.y, q.z, q.w] as QuatArray;
    }

    static setVector<V = Vector2 | Vector3 | Vector4>(source: V, target: Vector) {
        if (Array.isArray(source)) target.set(...source);
        else if (isNumber(source)) target.setScalar(source);
        else target.copy(source as Vector);
    }
}