import { RefObject, createRef, useEffect, useReducer } from "react";

type OptionalDataComparer<D> = (prev: D, curr: D) => boolean;
type ViewportWithOptionalData<T, D> = [RefObject<T>, D | undefined, OptionalDataComparer<D>];

class ViewportManager {
    static _instance: ViewportManager;
    static get instance() {
        if (!ViewportManager._instance) ViewportManager._instance = new ViewportManager();
        return ViewportManager._instance;
    }

    private viewports: Map<string, ViewportWithOptionalData<any, any>>;
    private subscribers: Map<string, Array<() => void>>;

    constructor() {
        this.viewports = new Map<string, ViewportWithOptionalData<any, any>>();
        this.subscribers = new Map<string, Array<() => void>>();
    }

    has(id: string): boolean {
        return this.viewports.has(id);
    }

    get<T, D = undefined>(id: string): ViewportWithOptionalData<T, D> | undefined {
        return this.viewports.get(id);
    }

    setData<T, D>(id: string, data: D | undefined): void {
        if (!this.has(id)) return;
        const [viewport, , comparer] = this.get<T, D>(id)!;
        this.viewports.set(id, [viewport, data, comparer]);
        for (let subscriber of this.getSubscribers(id)) {
            subscriber();
        }
    }

    createViewport<T, D>(id: string, data?: D, comparer?: OptionalDataComparer<D>): ViewportWithOptionalData<T, D> {
        if (!this.viewports.has(id)) {
            const viewport = createRef<T>();
            this.viewports.set(id, [viewport, data, comparer ?? defaultComparer]);
        }
        return this.get(id)!;
    }

    getSubscribers(id: string): Array<() => void> {
        if (!this.subscribers.has(id)) this.subscribers.set(id, []);
        return this.subscribers.get(id)!;
    }

    subscribe(id: string, callback: () => void) {
        this.getSubscribers(id).push(callback);
    }

    unsubscribe(id: string, callback: () => void) {
        const subscribers = this.getSubscribers(id);
        const idx = subscribers.indexOf(callback);
        subscribers.splice(idx, 1);
    }
}

function defaultComparer<D>(prev: D, curr: D) {
    if (!prev) {
        if (curr) return false;
    } else if (!curr) {
        if (prev) return false;
    } else {
        if (typeof curr === "object" && typeof prev === "object") {
            for (let key in curr) {
                if (curr[key] === prev[key]) continue;
                return false;
            }
        } else if (Array.isArray(curr) && Array.isArray(prev)) {
            if (curr.length !== prev.length) return false;
            for (let i of curr) {
                if (curr[i] === prev[i]) continue;
                return false;
            }
        } else {
            return curr === prev;
        }
    }
    return true;
}

export function useViewport<T, D = undefined>(id: string, data?: D, comparer?: OptionalDataComparer<D>): ViewportWithOptionalData<T, D> {
    const manager = ViewportManager.instance;
    const [, toggle] = useReducer(b => !b, false);

    useEffect(() => {
        manager.subscribe(id, toggle);
        return () => manager.unsubscribe(id, toggle);
    }, [manager, id, toggle]);

    if (manager.has(id)) {
        const [, prevData, prevComp] = manager.get<T, D>(id)!;
        if (data !== undefined) {
            const comp = prevComp ?? comparer ?? defaultComparer;

            let needsUpdate = false;
            if (!prevData) { if (data) needsUpdate = true; }
            else if (!data) { if (prevData) needsUpdate = true; }
            else needsUpdate = !comp(prevData, data);

            if (needsUpdate) manager.setData(id, data);
        }
    }
    return manager.createViewport<T, D>(id, data, comparer);
}