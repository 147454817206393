import { Mesh, Object3D } from "three";

export type OptArray<T> = T | Array<T>;
export type ArrayVector = [x: number, y: number, z: number];

export type Range = [min: number, max: number];
export type OptRange = number | Range;

export class TSUtils {
    static isMesh(obj: Object3D): obj is Mesh {
        return obj instanceof Mesh || (obj as Mesh).isMesh;
    }

    static single<T>(array: OptArray<T>): T {
        if (Array.isArray(array)) return array[0];
        return array;
    }

    static forEach<T>(array: OptArray<T>, action: (elem: T, index: number) => any) {
        if (!array) return;
        const arr = TSUtils.toArray(array);
        for (let i = 0; i < arr.length; ++i) action(arr[i], i);
    }

    static x<T>(array: OptArray<T>): T {
        if (Array.isArray(array)) return array[0];
        return array;
    }

    static y<T>(array: OptArray<T>): T {
        if (Array.isArray(array) && array.length > 1) return array[1];
        return TSUtils.x(array);
    }

    static z<T>(array: OptArray<T>): T {
        if (Array.isArray(array) && array.length > 2) return array[2];
        return TSUtils.y(array);
    }

    static isArray<T>(array: OptArray<T>): array is Array<T> {
        return Array.isArray(array);
    }

    static toArray<T>(array: OptArray<T>): Array<T> {
        if (TSUtils.isArray(array)) return array;
        return [array];
    }

    static isRange(range: OptRange): range is Range {
        return typeof range !== "number";
    }

    static toRange(range: OptRange): Range {
        if (TSUtils.isRange(range)) return range;
        return [range, range];
    }

    static keys<K extends PropertyKey, T>(object: Record<K, T>) {
        return Object.keys(object) as (K)[];
    }

    static entries<K extends PropertyKey, T>(object: Record<K, T>) {
        return Object.entries(object) as ([K, T])[];
    }
}

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};