import { Ref, Suspense, useEffect, useMemo } from "react";
import { Group, Mesh, MeshStandardMaterial, Vector3 } from "three";
import { animated } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { TSUtils } from "@utils/TSUtils";
import useMesh, { MeshOptions } from "@utils/useMesh";
import useOpacity from "@utils/useOpacity";

type Props = {
    smart?: boolean;
    opacity?: number;
    groupRef?: Ref<Group>;
} & GroupProps & MeshOptions;

const inhaler = `${process.env.PUBLIC_URL}/models/Inhaler-0v2.glb`;
const mdiBottle = `${process.env.PUBLIC_URL}/models/MDIBottle-0v3.glb`;
const sonohaler = `${process.env.PUBLIC_URL}/models/Sonohaler-0v2.glb`;
useGLTF.preload(inhaler);
useGLTF.preload(mdiBottle);
useGLTF.preload(sonohaler);
export const MDInhaler = animated(function ({ smart = false, opacity = 1, groupRef, ...props }: Props) {
    const inhalerMesh = useMDInhaler({ opacity, ...props });
    const mdiBottleMesh = useMDIBottle({ opacity, ...props });
    const sonohalerMesh = useSonohaler({ opacity, ...props });

    useEffect(() => {
        inhalerMesh.traverse((child) => child.renderOrder = 0);
        mdiBottleMesh.traverse((child) => child.renderOrder = 1);
        sonohalerMesh.traverse((child) => child.renderOrder = 2);
    }, [inhalerMesh, mdiBottleMesh, sonohalerMesh]);

    return (
        <Suspense>
            <animated.group ref={groupRef} {...props}>
                <primitive object={inhalerMesh} />
                <primitive object={mdiBottleMesh} />
                {smart && <primitive object={sonohalerMesh} />}
            </animated.group>
        </Suspense>
    );
});

export function useMDInhaler({ opacity = 1, ...props }: { opacity?: number } & MeshOptions) {
    const [inhalerMesh] = useMesh(inhaler, { ...props, copyHierarchy: true, copyMaterial: true });
    const mesh = useMemo(() => {
        let mesh: Mesh = null!;
        inhalerMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            mesh = child;
        });
        mesh.position.copy(mesh.getWorldPosition(new Vector3()));
        mesh.updateMatrix();
        return mesh;
    }, [inhalerMesh]);
    useOpacity(mesh.material, opacity, true);
    return mesh;
};

export function useMDIBottle({ opacity = 1, ...props }: { opacity?: number } & MeshOptions) {
    const [mdiBottleMesh] = useMesh(mdiBottle, { ...props, copyHierarchy: true, copyMaterial: true });
    const mesh = useMemo(() => {
        let mesh: Mesh = null!;
        mdiBottleMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            mesh = child;
        });
        mesh.position.copy(mesh.getWorldPosition(new Vector3()));
        mesh.updateMatrix();
        return mesh;
    }, [mdiBottleMesh]);
    useOpacity(mesh.material, opacity, true);
    return mesh;
};

export function useSonohaler({ opacity = 1, ...props }: { opacity?: number } & MeshOptions) {
    const [sonohalerMesh] = useMesh(sonohaler, { ...props, copyHierarchy: true, copyMaterial: true });
    const mat = useMemo(() => {
        return new MeshStandardMaterial({ color: "#26ffff", transparent: true, opacity: 1 });
    }, []);
    const mesh = useMemo(() => {
        let mesh: Mesh = null!;
        sonohalerMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            mesh = child;
        });
        mesh.position.copy(mesh.getWorldPosition(new Vector3()));
        mesh.updateMatrix();
        mesh.material = mat;
        return mesh;
    }, [sonohalerMesh, mat]);
    useOpacity(mesh.material, opacity, true);
    return mesh;
};