import { MeshPhysicalMaterial, MeshPhysicalMaterialParameters, Shader, Uniform, WebGLRenderer } from "three";
import { ShaderUtils } from "@utils/ShaderUtils";
import { MathUtils } from "@utils/MathUtils";
import { EOrigin, OutlineGlowMaterialParams } from "@shaders/OutlineGlow/OutlineGlowMaterialShader";
import { InvertParams } from "@shaders/Invert/InvertMaterialShader";

import vert_inject from "@shaders/Invert/Invert.inject.vert";
import vert from "@shaders/Invert/Invert.vert";

import frag_pars from "@shaders/OutlineGlow/OutlineGlow.pars.frag";
import frag_inject from "@shaders/OutlineGlow/OutlineGlow.inject.frag";
import frag from "@shaders/OutlineGlow/OutlineGlow.frag";

type Params = OutlineGlowMaterialParams & InvertParams;
export type { Params as OutlineGlowLitInvertMaterialParams };

export class OutlineGlowLitInvertMaterial extends MeshPhysicalMaterial {
    private _origin: Uniform<EOrigin> = new Uniform(EOrigin.Bottom);
    private _outlineThickness: Uniform<number> = new Uniform(0.4);
    private _outlineOpacity: Uniform<number> = new Uniform(1);
    private _glowStartOpacity: Uniform<number> = new Uniform(0.25);
    private _glowEndOpacity: Uniform<number> = new Uniform(0);

    constructor(params: Params & MeshPhysicalMaterialParameters) {
        const p = ShaderUtils.extract(params, 
            "origin", "outlineThickness", "outlineOpacity", "glowStartOpacity", "glowEndOpacity"
        );
        super(params);
        if (!this.defines) this.defines = {};
        this.defines.USE_UV = "";
        ShaderUtils.assign(this, p);
    }

    onBeforeCompile(shader: Shader, renderer: WebGLRenderer) {
        super.onBeforeCompile(shader, renderer);

        const cos = Math.cos(this._origin.value * MathUtils.deg2Rad);
        const sin = Math.sin(this._origin.value * MathUtils.deg2Rad);
        const rotation = [cos, -sin, sin, cos];

        shader.uniforms.outlineGlowRotation = new Uniform(rotation);
        shader.uniforms.outlineThickness = this._outlineThickness;
        shader.uniforms.outlineOpacity = this._outlineOpacity;
        shader.uniforms.glowStartOpacity = this._glowStartOpacity;
        shader.uniforms.glowEndOpacity = this._glowEndOpacity;

        shader.vertexShader = ShaderUtils.inject(shader.vertexShader, vert_inject, vert, true);

        shader.fragmentShader = ShaderUtils.prepend(shader.fragmentShader, frag_pars, true);
        shader.fragmentShader = ShaderUtils.inject(shader.fragmentShader, frag_inject, frag, true);
    }

    public get origin() { return this._origin.value; }
    public set origin(value: EOrigin) { this._origin.value = value; }

    public get outlineThickness() { return this._outlineThickness.value; }
    public set outlineThickness(value: number) { this._outlineThickness.value = value; }

    public get outlineOpacity() { return this._outlineOpacity.value; }
    public set outlineOpacity(value: number) { this._outlineOpacity.value = value; }

    public get glowStartOpacity() { return this._glowStartOpacity.value; }
    public set glowStartOpacity(value: number) { this._glowStartOpacity.value = value; }

    public get glowEndOpacity() { return this._glowEndOpacity.value; }
    public set glowEndOpacity(value: number) { this._glowEndOpacity.value = value; }
};