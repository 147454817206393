import { useEffect } from "react";

type ResizeCallback = (width: number, height: number) => void;

/**
 * Add a callback to the winodw resize event.
 * `callback` must be a `React.useCallback`
 * @param callback Function to call when the window resizes
 * @param callImmediate Immediately execute when React is ready
 */
export default function useResize(callback: ResizeCallback, callImmediate: boolean = false) {
    useEffect(() => {
        function cb() {
            callback(window.innerWidth, window.innerHeight);
        }
        window.addEventListener("resize", cb);
        if (callImmediate) cb();
        return () => {
            window.removeEventListener("resize", cb);
        }
    }, [callback, callImmediate]);
}