import styles from "@styles/Root.module.css";
import { Component, ConsumerProps, createRef, RefObject } from "react";

export type RootProps = {
    /** HTML Element Id */
    id: string;
    /** Include scroll offsets */
    scroll?: boolean;
    /** For single page designs: Fixes the `vh` unit to account for the browser's URL bar. Exposes inner window size as CSS variables `--pageWidth` and `--pageHeight`, as replacement for `vh`. Usage: `height: calc(var(--pageHeight) * ${percent});` */
    singlePage?: boolean;
    /** 
     * For dynamically scaled pages: 
     * Overrides the `rem` unit to apply a scale to CSS styles, as a drop-in replacement for `px` units. 
     * The scale is also available as CSS variable `--scale`. 
     * Arguments are reference width and height. Viewports smaller than these sizes will result in a scale < 1, bigger > 1.
     * If `mobile` is `true`, width and height are flipped, or if 4 arguments are provided, size 3 and 4 are used.
     */
    dynamic?: [width: number, height: number] | [desktopWidth: number, desktopHeight: number, mobileWidth: number, mobileHeight: number];
    /** 
     * Conditionally style the page for mobile. Adds the `mobile` class to root if mobile style. 
     * 
     * Behaviour depends on supplied type:
     * - `function`: return whether the page is mobile for the given `pageWidth` and `pageHeight`. 
     * - `number`: specify at what aspect ratio the page switches style (< ratio is mobile, >= ratio is desktop). 
     * - `boolean`: set the mobile state 
     */
    mobile?: number | boolean | ((pageWidth: number, pageHeight: number) => boolean);
} & ConsumerProps<RootState>;

export type RootState = {
    left: number;
    top: number;
    width: number;
    height: number;
    ref: RefObject<HTMLDivElement>;
    pageWidth: number;
    pageHeight: number;
    mobile: boolean;
}

export default class Root extends Component<RootProps, RootState> {
    private _ref: RefObject<HTMLDivElement>;
    private _root: HTMLElement;
    private _updateBounds: () => void;

    constructor(props: RootProps) {
        super(props);
        this._ref = createRef();
        this._root = null!;
        this._updateBounds = this.updateBounds.bind(this);

        this.state = {
            left: 0,
            top: 0,
            width: 1,
            height: 1,
            ref: this._ref,
            pageWidth: 1,
            pageHeight: 1,
            mobile: false,
        }
    }

    updateBounds() {
        if (!this._ref.current) return;
        const bounds = this._ref.current.getBoundingClientRect();
        if (this.props.scroll) {
            bounds.x += this._root.scrollLeft;
            bounds.y += this._root.scrollTop;
        }

        let isMobile = false;
        switch (typeof this.props.mobile) {
            case "function": isMobile = this.props.mobile(window.innerWidth, window.innerHeight); break;
            case "number": isMobile = window.innerWidth / window.innerHeight < this.props.mobile; break;
            default: case "boolean": isMobile = this.props.mobile === true; break;
        }

        this.setState({
            left: bounds.x,
            top: bounds.y,
            width: bounds.width,
            height: bounds.height,
            pageWidth: window.innerWidth,
            pageHeight: window.innerHeight,
            mobile: isMobile
        });

        if (this.props.singlePage) {
            document.documentElement.style.setProperty("--page-width", `${window.innerWidth}px`);
            document.documentElement.style.setProperty("--page-height", `${window.innerHeight}px`);
        }

        if (this.props.dynamic) {
            let width = 1, height = 1;
            if (isMobile) {
                if (this.props.dynamic.length === 4) {
                    width = this.props.dynamic[2];
                    height = this.props.dynamic[3];
                } else {
                    width = this.props.dynamic[1];
                    height = this.props.dynamic[0];
                }
            } else {
                width = this.props.dynamic[0];
                height = this.props.dynamic[1];
            }
            const rem = Math.min(window.innerWidth / width, window.innerHeight / height);
            document.documentElement.style.fontSize = `${rem}px`;
            document.documentElement.style.setProperty("--scale", `${rem}`);
        }
    }

    componentDidMount(): void {
        this._root = document.getElementById(this.props.id)!;
        this.updateBounds();
        window.addEventListener("resize", this._updateBounds);
        if (this.props.scroll) this._root.addEventListener("scroll", this._updateBounds);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this._updateBounds);
        this._root?.removeEventListener("scroll", this._updateBounds);
    }

    render() {
        const classes = [styles.root];
        if (this.state.mobile) classes.push("mobile");
        return (
            <div ref={this._ref} className={classes.join(" ")}>
                {this.props.children(this.state)}
            </div>
        );
    }
}