export enum EPage {
    Sonohaler = "Sonohaler",
    Applications = "Applications",
    SonoOne = "Sono\u00a0One",
    SonoTwo = "Sono\u00a0Two",
    Mission = "Mission",
    Footer = "Footer"
}

export enum ETag {
    Sonohaler = "",
    Applications = "#applications",
    SonoOne = "#sono-one",
    SonoTwo = "#sono-two",
    Mission = "#mission",
    Footer = "#footer"
}