import { PropsWithChildren, createContext, useCallback } from "react";
import { PageAction, PageContextProps, PageState, usePageContext } from "@contexts/PageContext";
import Sonohaler from "@pages/Sonohaler";

type State = PageState & {
    readonly shown: boolean;
    readonly shouldConsume: (index: number) => boolean;
};

type Action = PageAction & {
};

type ProviderProps = {
} & PropsWithChildren;

export const SonohalerContext = createContext<PageContextProps<State, Action>>(null!);

export function SonohalerContextProvider({ children }: ProviderProps) {
    const initial: State = {
        index: 0,
        shown: false,
        shouldConsume: (index: number) => index >= 0 && index <= 2
    };

    const showHandler = useCallback((state: State, action: Action) => {
        return { ...state, shown: true };
    }, []);
    const [state, dispatch] = usePageContext<State, Action>(Sonohaler, initial, {
        "show": showHandler
    });
    return (
        <SonohalerContext.Provider value={{ state, dispatch }}>
            {SonohalerContext && children}
        </SonohalerContext.Provider>
    );
};