import { Easing } from "@animations/Easing";
import { AnimatedLogo, Logo as LogoIcon } from "@components/Icons";

export default function Logo({ animated = false }: { animated?: boolean }) {
    const logo = animated
        ? <AnimatedLogo speed={0.3} easeIn={Easing.outQuart} easeOut={Easing.inoutQuart} />
        : <LogoIcon />
    return (
        <>
            {logo}
        </>
    );
}