import { Layers } from "three";

const RENDER = 1;
const renderLayer = new Layers();
renderLayer.set(RENDER);
export { RENDER, renderLayer };
const SHADOW = 2;
const shadowLayer = new Layers();
shadowLayer.set(SHADOW);
export { SHADOW, shadowLayer };
const RAYCAST = 10;
const raycastLayer = new Layers();
raycastLayer.set(RAYCAST);
export { RAYCAST, raycastLayer };

export const speed = 0.25;

export const scrollDelay = 2500;