import { SpringConfig, useSpring } from "@react-spring/web";
import { DependencyList, useCallback, useEffect, useState } from "react";

type Options = {
    onFaded?: () => void;
} & SpringConfig;

/**
 * Perform a fade transition by fading opacity to 0, applying changes and then fading back to 1.
 * @param deps Execute fade when these dependencies change
 * @returns Opacity spring value
 */
export default function useFade({ onFaded, ...config }: Options, deps: DependencyList = []) {
    // Halve the duration, as we use the spring twice: 1->0 and 0->1
    if (config.duration) config.duration *= 0.5;

    const [reverse, setReverse] = useState(false);

    const faded = useCallback(() => {
        if (reverse) return;
        setReverse(true);
        onFaded?.();
    }, [onFaded, reverse, setReverse]);

    useEffect(() => {
        setReverse(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, setReverse]);

    const [{ opacity }] = useSpring(() => ({
        from: { opacity: 1 },
        to: { opacity: 0 },
        reverse: reverse,
        onRest: faded,
        config: config
    }), [reverse]);

    return opacity;
}