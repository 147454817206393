import { EasingFunction } from "@react-spring/types";
import { DependencyList, useCallback } from "react";
import { MathUtils } from "@utils/MathUtils";

type Key = {
    /** Start time */
    start: number;
    /** End time */
    end: number;
    /** From value */
    from: number;
    /** To value */
    to: number;
    /** Easing function */
    easing?: EasingFunction;
};
export type { Key as MappedKey };

export default function useMappedEasing(keys: Key[], deps?: DependencyList) {
    return useCallback((t: number) => {
        for (let i = 0; i < keys.length; ++i) {
            const key = keys[i];
            if (t < key.start || t > key.end) continue;
            const easer = key.easing ?? (x => x);
            const at = easer(MathUtils.mapRange(t, key.start, key.end, 0, 1, false));
            const val = MathUtils.mapRange(at, 0, 1, key.from, key.to, false);
            return val;
        }
        console.warn(`Failed to map easing: Missing key for time ${t}`);
        return keys[0].from;
        // Do not add keys as dependency, as it is constructed as a simple array of objects
        // This would cause the callback to continuously change, defeating the purpose of
        // the hook. For keys that change, one must supply dependencies manually.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps ?? []);
};