import { Callback, Listener } from "@utils/Callback";
import { Updater } from "@utils/Updater";
import { Easing } from "@animations/Easing";
import { Animation } from "@animations/Animation";
import { MathUtils } from "@utils/MathUtils";
import { JSUtils } from "@utils/JSUtils";

export enum EAnimationLoop {
    Once = 0,
    PingPong = 1,
    Loop = 2
};

export enum EAnimationDirection {
    Forward = 1,
    Backward = -1,
};

function invert(direction: EAnimationDirection) {
    return (direction * -1) as EAnimationDirection;
}

export class Sequence {
    name: string;
    type: EAnimationLoop;
    amount: number;
    duration: number;
    animations: Animation[];

    private _isPlaying: boolean;
    private _isFinished: boolean;
    private _onLoop: Callback<[count: number, maxCount: number]>;
    private _onPlay: Callback<[time: number]>;
    private _onPause: Callback<[time: number]>;
    private _onAbort: Callback<[time: number]>;
    private _onComplete: Callback<[]>;
    private _onTargetReached: Callback<[]>;
    private _onChange: Callback<[time: number, percent: number]>;

    private _startTime: number;
    private _startDirection: EAnimationDirection;

    private _count: number;
    private _maxCount: number;
    private _time: number;
    private _direction: EAnimationDirection;

    private _targetStart: number | null;
    private _targetEnd: number | null;
    private _targetDirection: EAnimationDirection;
    private _targetSmoothing: number;

    private _update: (deltaTime: number) => void;

    constructor(name: string, ...animations: Animation[]) {
        this.name = JSUtils.valueOrDefault(name, JSUtils.guid());
        this.type = EAnimationLoop.Once;
        this.amount = 1;
        this.duration = 0;
        this.animations = [];
        if (animations !== undefined && animations !== null && animations.length !== 0) this.add(...animations);

        this._isPlaying = false;
        this._isFinished = true;

        this._onLoop = new Callback();
        this._onPlay = new Callback();
        this._onPause = new Callback();
        this._onAbort = new Callback();
        this._onComplete = new Callback();
        this._onTargetReached = new Callback();
        this._onChange = new Callback();

        this._startTime = 0;
        this._startDirection = EAnimationDirection.Forward;

        this._count = 0;
        this._maxCount = 1;
        this._time = 0;
        this._direction = EAnimationDirection.Forward;

        this._targetStart = null;
        this._targetEnd = null;
        this._targetDirection = EAnimationDirection.Forward;
        this._targetSmoothing = 0.5;

        this._update = this.run.bind(this);
    }

    get currentTime() { return this._time; }
    get currentPercent() { return this._time / this.duration; }
    get currentDirection() { return this._targetEnd === null ? this._direction : this._targetDirection; }
    get isPlaying() { return this._isPlaying; }
    get isFinished() { return this._isFinished; }

    get isForward() { return this.currentDirection === EAnimationDirection.Forward; }
    get isBackward() { return this.currentDirection === EAnimationDirection.Backward; }

    /**
     * Add animations to the sequence
     * Animation times are absolute
     */
    add(...animations: Animation[]) {
        // Check for duplicates
        for (let i = 0; i < animations.length; ++i) {
            for (let j = 0; j < this.animations.length; ++j) {
                if (animations[i].name === this.animations[j].name) {
                    this.animations.splice(j, 1);
                    break;
                }
            }
        }

        // Extend duration and add animations
        for (let animation of animations) {
            this.duration = Math.max(this.duration, animation.endTime);
            this.animations.push(animation);
        }

        return this;
    }

    /**
     * Append animations to the end of the sequence
     * Animation times are relative and shifted to the end
     */
    then(...animations: Animation[]) {
        const time = this.duration;

        // Shift animations
        for (let animation of animations) {
            animation.setTimes(animation.startTime + time, animation.endTime + time);
        }

        // Add shifted animations
        this.add(...animations);

        return this;
    }

    /**
     * Set the loop type and the number of loops
     * Set the amount to 0 for infinite looping
     */
    loop(type: EAnimationLoop, amount: number = Infinity) {
        this.type = JSUtils.valueOrDefault(type, EAnimationLoop.Once);
        this.amount = JSUtils.valueOrDefault(amount, Infinity);
        this._maxCount = this.amount;
        return this;
    }

    /**
     * Start the sequence at a given time
     * This function does not actually start playing, you must call `play()` for that
     */
    startAt(time: number) {
        this._startTime = JSUtils.valueOrDefault(time, 0);
        return this;
    }

    /**
     * Start the sequence in a given direction
     * This function does not actually start playing, you must call `play()` for that
     */
    startIn(direction: EAnimationDirection) {
        this._startDirection = JSUtils.valueOrDefault(direction, EAnimationDirection.Forward);
        return this;
    }

    /**
     * Add a function to execute when the sequence loops
     */
    onLoop(callback: Listener<[count: number, maxCount: number]>, id: string | null = null) {
        this._onLoop.add(0, id, callback, false, false);
        return this;
    }

    /**
     * Add a function to execute when the sequence starts playing
     */
    onPlay(callback: Listener<[time: number]>, id: string | null = null) {
        this._onPlay.add(0, id, callback, false, false);
        return this;
    }

    /**
     * Add a function to execute when the sequence pauses
     */
    onPause(callback: Listener<[time: number]>, id: string | null = null) {
        this._onPause.add(0, id, callback, false, false);
        return this;
    }

    /**
     * Add a function to execute when the sequence aborts before the end
     */
    onAbort(callback: Listener<[time: number]>, id: string | null = null) {
        this._onAbort.add(0, id, callback, false, false);
        return this;
    }

    /**
     * Add a function to execute when the sequence completes
     * Specify whether the functions should execute only once
     */
    onComplete(callback: Listener<[]>, id: string | null = null, once = false) {
        this._onComplete.add(0, id, callback, false, once);
        return this;
    }

    /**
     * Add a function to execute when the target is reached
     */
    onTargetReached(callback: Listener<[]>, id: string | null = null) {
        this._onTargetReached.add(0, id, callback, false, false);
        return this;
    }

    /**
     * Add a function to execute when the sequence time changed
     */
    onChange(callback: Listener<[time: number, percent: number]>, id: string | null = null) {
        this._onChange.add(0, id, callback, false, false);
        return this;
    }

    /**
     * Play or resume the sequence
     * Optionally specify animation direction
     */
    play(direction: EAnimationDirection | null = null) {
        if (this._isPlaying) return this;
        if (!Updater.instance.add(0, `Sequence_${this.name}`, this._update, true, false)) return this;
        if (direction !== undefined && direction !== null) {
            this._direction = JSUtils.valueOrDefault(direction, this._startDirection);
        }
        if (this._isFinished) {
            this._isFinished = false;
            this._direction = JSUtils.valueOrDefault(direction, this._startDirection);
            this._time = this._direction === EAnimationDirection.Forward ? this._startTime : (this.duration - this._startTime);
            this._count = 0;
            this._maxCount = this.amount;
        }
        this._isPlaying = true;
        this._onPlay.call(this._time);
        return this;
    }

    /**
     * Execute the sequence at given time
     */
    frame(time: number) {
        this.pause();
        this._time = time;
        this.run(0);
        return this;
    }

    /**
     * Execute the sequence at given percent
     */
    evaluate(percent: number) {
        this.frame(percent * this.duration);
        return this;
    }

    /**
     * Play the sequence until target time with optional smoothing
     */
    playTo(time: number, smoothing: number = 0) {
        this._targetEnd = JSUtils.valueOrDefault(time, null);
        if (this._targetEnd === null) return this;
        this._targetEnd = MathUtils.clamp(this._targetEnd, 0, this.duration);
        if (this._time === this._targetEnd) { this.run(0); return this; }
        this._targetDirection = this._time < this._targetEnd ? EAnimationDirection.Forward : EAnimationDirection.Backward;
        this._targetSmoothing = JSUtils.valueOrDefault(smoothing, 0);
        this._targetStart = this._time;
        if (!Updater.instance.add(0, `Sequence_${this.name}`, this._update, true, false)) return this;
        return this;
    }

    /**
     * Play the sequence until the target percent with optional smoothing
     */
    playToPercent(percent: number, smoothing: number = 0.5) {
        this.playTo(percent * this.duration, smoothing);
        return this;
    }

    /**
     * Pause the sequence
     */
    pause() {
        if (!Updater.instance.remove(0, `Sequence_${this.name}`)) return this;
        this._isPlaying = false;
        this._onPause.call(this._time);
        return this;
    }

    /*
     * Stop the sequence and reset the time and direction
     */
    stop() {
        if (!Updater.instance.remove(0, `Sequence_${this.name}`)) return this;
        this._isPlaying = false;
        this._isFinished = true;
        for (let animation of this.animations) animation.isPlaying = false;
        this._onAbort.call(this._time);
        return this;
    }

    /*
     * Gracefully stop the seqeuence after finishing its cycle
     * Specify a direction to make sure the sequence finishes in the given direction (for PingPong type)
     */
    complete(direction = null) {
        if (direction === undefined || direction === null) this._maxCount = this._count + 1;
        else switch (this.type) {
            case EAnimationLoop.Once: {
                this._maxCount = this._count + 1;
                break;
            }
            case EAnimationLoop.PingPong: {
                if (direction === this.currentDirection) this._maxCount = this._count + 1;
                else this._maxCount = this._count + 2;
                break;
            }
            case EAnimationLoop.Loop: {
                this._maxCount = this._count + 1;
                break;
            }
            default: {
                console.warn(`Invalid animation loop for Sequence ${this.name}`);
                break;
            }
        }
        return this;
    }

    /**
     * Invert the sequence, or force it into the given direction
     */
    invert(direction: EAnimationDirection | null = null) {
        const prev = this.currentDirection;
        if (direction === undefined || direction === null) this._direction = invert(prev);
        else this._direction = JSUtils.valueOrDefault(direction, prev);
        if (prev !== this._direction) {
            const current = this.animations.find(x => x.startTime < this._time && x.endTime > this._time);
            if (current) {
                this._time = Easing.smoothTransition(
                    current.getEaser(prev), current.getEaser(this._direction),
                    this._time - current.startTime
                ) + current.startTime;
            }
        }
        return this;
    }

    /**
     * Run the animation
     * Not recommended to call manually!
     */
    run(deltaTime: number) {
        if (this._targetEnd !== null) this._runTarget(deltaTime);
        else this._run(deltaTime);
    }

    private _run(deltaTime: number) {
        this._time += this._direction * deltaTime;
        let t;
        let looped = false;
        switch (this.type) {
            case EAnimationLoop.Once: {
                t = MathUtils.clamp(this._time, 0, this.duration);
                if (t !== this._time) {
                    looped = true;
                }
                break;
            }
            case EAnimationLoop.PingPong: {
                let ct = MathUtils.clamp(this._time, 0, this.duration);
                let dt = this._time - ct;
                if (dt !== 0) {
                    this.invert();
                    looped = true;
                }
                t = ct - dt;
                break;
            }
            case EAnimationLoop.Loop: {
                t = this._time % this.duration;
                if (t !== this._time) {
                    looped = true;
                }
                break;
            }
            default: {
                console.warn(`Invalid animation loop for Sequence ${this.name}`);
                return;
            }
        }
        t = MathUtils.clamp(t, 0, this.duration);
        this._onChange.call(t, t / this.duration);
        if (looped) {
            ++this._count;
            if (this.type === EAnimationLoop.Once ||
                (this._count >= this._maxCount)) {
                Updater.instance.remove(0, `Sequence_${this.name}`);
                this._isPlaying = false;
                this._isFinished = true;
                if (this.type === EAnimationLoop.PingPong) this.invert();
                for (let animation of this.animations) {
                    animation.run(this._direction === EAnimationDirection.Forward ? this.duration : 0, this._direction);
                }
                this._onComplete.call();
                return;
            } else {
                for (let animation of this.animations) {
                    animation.run(t, this._direction);
                }
                this._onLoop.call(this._count, this._maxCount);
            }
        } else {
            for (let animation of this.animations) {
                animation.run(t, this._direction);
            }
        }
        this._time = t;
    }

    private _runTarget(deltaTime: number) {
        if (this._targetEnd === null) return;
        this._time += this._targetDirection * deltaTime;
        let t = MathUtils.lerp(this._time, this._targetEnd, this._targetSmoothing);
        t = MathUtils.clamp(t, this._targetStart!, this._targetEnd);
        this._onChange.call(t, t / this.duration);
        for (let animation of this.animations) {
            animation.run(t, this._targetDirection);
        }
        switch (this._targetDirection) {
            case EAnimationDirection.Forward: {
                if (this._time >= this._targetEnd) {
                    Updater.instance.remove(0, `Sequence_${this.name}`);
                    this._targetStart = null;
                    this._targetEnd = null;
                    this._onTargetReached.call();
                    this._time = t;
                    return;
                }
                break;
            }
            case EAnimationDirection.Backward: {
                if (this._time <= this._targetEnd) {
                    Updater.instance.remove(0, `Sequence_${this.name}`);
                    this._targetStart = null;
                    this._targetEnd = null;
                    this._onTargetReached.call();
                    this._time = t;
                    return;
                }
                break;
            }
            default: {
                console.warn(`Invalid animation direction for Sequence ${this.name}`);
                return;
            }
        }
        this._time = t;
    }
}