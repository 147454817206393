import { useEffect, useRef } from "react";

export default function useActiveEvent<T extends HTMLElement, K extends keyof HTMLElementEventMap>(
    type: K, 
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    options?: AddEventListenerOptions
) {
    const ref = useRef<T>(null);
    useEffect(() => {
        const elem = ref.current ?? document.documentElement;
        const opt = { ...options, passive: false };
        elem.addEventListener(type, listener, opt);
        return () => {
            elem.removeEventListener(type, listener);
        }
    }, [ref, type, listener, options]);
    return ref;
}
