import styles from "@styles/Navbar.module.css";
import { useContext, useMemo, useReducer } from "react";
import NavbarButton from "@components/NavbarButton";
import { EPage } from "@pages/Pages";
import Logo from "@components/Logo";
import { Menu } from "@components/Icons";
import { LoadingContext } from "@contexts/LoadingContext";
import { PagesContext } from "@contexts/PagesContext";

export default function Navbar({ mobile }: { mobile: boolean }) {
    const { loaded } = useContext(LoadingContext);
    const context = useContext(PagesContext);

    const pages = useMemo(() => {
        return [
            [EPage.Sonohaler],
            [EPage.Applications],
            [EPage.SonoOne],
            [EPage.SonoTwo],
            [EPage.Mission, EPage.Footer]
        ];
    }, []);

    const [open, toggle] = useReducer(b => !b, false);

    if (mobile) {
        return (
            <div className={styles.root}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.menu} onClick={toggle}>
                    <Menu color="#0f687f" open={open} />
                </div>
                <div className={styles.foldout} style={{ height: open ? "calc(var(--page-height) - var(--navbar-height))" : "0rem" }}>
                    {open && <div className={styles.content}>
                        {pages.map((names) => {
                            return <NavbarButton key={names[0]} text={names[0]} active={!!context && names.includes(context.target)} onClick={() => { context?.setTarget(names[0]); toggle(); }} />;
                        })}
                        <a className={styles.contact} href="mailto:contact@sonohaler.com" style={{ opacity: loaded ? "1" : "0" }}>Get in touch</a>
                    </div>}
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.root}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.buttons} style={{ opacity: loaded ? "1" : "0" }}>
                    {pages.map((names) => {
                        return <NavbarButton key={names[0]} text={names[0]} active={!!context && names.includes(context.target)} onClick={() => context?.setTarget(names[0])} />;
                    })}
                </div>
                <a className={styles.contact} href="mailto:contact@sonohaler.com" style={{ opacity: loaded ? "1" : "0" }}>Get in touch</a>
            </div>
        );
    }
}