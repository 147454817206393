import { Ref, Suspense, useEffect, useMemo } from "react";
import { Group, MeshBasicMaterial, MeshPhysicalMaterial, MeshStandardMaterial } from "three";
import { animated } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { TSUtils } from "@utils/TSUtils";
import useMesh, { MeshOptions } from "@utils/useMesh";
import useOpacity from "@utils/useOpacity";

type Props = {
    opacity?: number;
    groupRef?: Ref<Group>;
    materialType?: "standard" | "wire" | "transmissive";
} & GroupProps & MeshOptions;

const sonohaler = `${process.env.PUBLIC_URL}/models/Sonohaler-0v2.glb`;
useGLTF.preload(sonohaler);
export const SonohalerRing = animated(function ({ opacity = 1, groupRef, materialType = "standard", ...props }: Props) {
    const [sonohalerMesh] = useMesh(sonohaler, { ...props, copyHierarchy: true });

    const sonohalerMat = useMemo(() => {
        switch (materialType) {
            default: case "standard": return new MeshStandardMaterial({ color: "#26ffff", transparent: true, opacity: 1, roughness: 0.6 });
            case "wire": return new MeshBasicMaterial({ color: "#26ffff", transparent: true, opacity: 1, depthWrite: false, wireframe: true });
            case "transmissive": return new MeshPhysicalMaterial({ color: "#26ffff", transparent: true, opacity: 1, roughness: 0.6, envMapIntensity: 1, transmission: 0.712 });
        }
    }, [materialType]);

    useEffect(() => {
        sonohalerMesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            child.material = sonohalerMat;
        });
    }, [sonohalerMesh, sonohalerMat]);

    useOpacity(sonohalerMat, opacity, materialType !== "wire");

    return (
        <Suspense>
            <animated.group ref={groupRef} {...props}>
                <primitive object={sonohalerMesh} />
            </animated.group>
        </Suspense>
    );
});