import { Material, Object3D } from "three";
import { OptArray, TSUtils } from "@utils/TSUtils";
import { useCallback, useEffect, useMemo } from "react";

function isObject(materials: OptArray<Material> | Object3D): materials is Object3D {
    return "traverse" in materials;
}

/**
 * Apply transparency to materials
 * @param materials Materials to make transparent
 * @param opacity Opacity value
 * @param depthWrite Should the material write to depth. Default `true`
 * @param visibleThreshold Minimum opacity for the material to be visible. Default `0`. If negative, material will always be visible.
 */
export default function useOpacity(materials: OptArray<Material> | Object3D, opacity: number, depthWrite: boolean = true, visibleThreshold: number = 0) {
    // Create dependency array
    const deps = TSUtils.isArray(materials) ? materials : [materials];

    // Map input to material array
    const mats = useMemo(() => {
        const mats: Array<Material> = [];
        if (isObject(materials)) {
            materials.traverse(child => {
                if (!TSUtils.isMesh(child)) return;
                if (TSUtils.isArray(child.material)) mats.push(...child.material);
                else mats.push(child.material);
            });
        } else {
            if (TSUtils.isArray(materials)) mats.push(...materials);
            else mats.push(materials);
        }
        return mats;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    // Apply opacity and depthWrite flag
    const apply = useCallback((material: Material) => {
        material.transparent = true;
        material.opacity = opacity;
        material.depthWrite = opacity <= 0 ? false : depthWrite;
        material.visible = opacity > visibleThreshold;
    }, [opacity, depthWrite, visibleThreshold]);

    // Apply immediate and for every change
    TSUtils.forEach(mats, apply);
    useEffect(() => {
        TSUtils.forEach(mats, apply);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...mats, opacity, depthWrite]);
}