import { MathUtils } from "@utils/MathUtils";

export class JSUtils {
    /**
     * Generate a GUID. Not guaranteed to be unique
     * @returns The GUID
     */
    static guid() {
        const tS4 = function () {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return tS4() + tS4() + "-" + tS4() + "-" + tS4() + "-" +
            tS4() + "-" + tS4() + tS4() + tS4();
    }

    /**
     * Get the value if defined, or default if not
     * @param value Value
     * @param dflt Default
     * @returns Value if not null or undefined, default otherwise
     */
    static valueOrDefault<T>(value: T | null | undefined, dflt: T) {
        if (value === undefined || value === null) return dflt;
        return value;
    }

    /**
     * Transform an array from type `T` to type `S`
     * @param array Array with elements of type `T` to transform
     * @param transformer Function to transform element of type `T` to type `S`
     * @returns The transformed array with elements of type `S`
     */
    static transform<T, S>(array: T[], transformer: ((x: T) => S)) {
        const result: S[] = [];
        for (let el of array) result.push(transformer(el));
        return result;
    }

    /**
     * Shuffle an array using Fisher-Yates shuffle algorithm
     * 
     * https://www.geeksforgeeks.org/shuffle-a-given-array-using-fisher-yates-shuffle-algorithm/
     * @param array Array to shuffle
     * @returns The shuffled array
     */
    static shuffle<T>(array: T[]) {
        const shuffled: T[] = new Array<T>(...array);
        for (let i = shuffled.length - 1; i > 0; --i) {
            const j = Math.floor(MathUtils.randRange([0, i + 1]));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    }
}