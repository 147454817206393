import { Ref, Suspense, useEffect, useMemo, useRef } from "react";
import { animated } from "@react-spring/three";
import { GroupProps, useFrame } from "@react-three/fiber";
import { Color, Group, MeshBasicMaterial, MeshPhysicalMaterial } from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import { MaskedDotMaterial } from "@shaders/MaskedDot/MaskedDotMaterialShader";
import { EOrigin } from "@shaders/OutlineGlow/OutlineGlowMaterialShader";
import { OutlineGlowLitInvertMaterial } from "@shaders/OutlineGlowLitInvert/OutlineGlowLitInvertMaterialShader";
import useMesh, { MeshOptions } from "@utils/useMesh";
import { TSUtils } from "@utils/TSUtils";
import useOpacity from "@utils/useOpacity";
import { MathUtils } from "@utils/MathUtils";

type Props = {
    enabled?: boolean;
    start?: number;
    angularSpeed?: number;
    opacity?: number;
    groupRef?: Ref<Group>;
} & GroupProps & MeshOptions;

const globe = `${process.env.PUBLIC_URL}/models/Globe-0v1.glb`;
const halo = `${process.env.PUBLIC_URL}/models/Halo-0v1.glb`;
useGLTF.preload(globe);
useGLTF.preload(halo);
const mask = `${process.env.PUBLIC_URL}/textures/T_Globe_Mask.png`;
useTexture.preload(mask);
export const Globe = animated(function Globe({ enabled = true, start = 0, angularSpeed = 1, opacity = 1, groupRef, ...props }: Props) {
    const [globeMesh] = useMesh(globe, { ...props, copyHierarchy: true });
    const [haloMesh] = useMesh(halo, { ...props, copyHierarchy: true });
    const [outlineMesh] = useMesh(halo, { ...props, copyHierarchy: true });
    const [dotsMesh] = useMesh(globe, { ...props, copyHierarchy: true });
    const maskTex = useTexture(mask);
    maskTex.flipY = false;

    const ref = useRef<Group>(null);

    useEffect(() => {
        haloMesh.traverse(child => child.renderOrder = -1);
        haloMesh.scale.setScalar(0.95);
    }, [haloMesh]);

    useEffect(() => {
        outlineMesh.traverse(child => child.renderOrder = -2);
        outlineMesh.scale.setScalar(0.95);
    }, [outlineMesh]);

    useEffect(() => {
        dotsMesh.traverse(child => child.renderOrder = 1);
        dotsMesh.scale.setScalar(1.001);
    }, [dotsMesh]);

    const globeMat = useMemo(() => {
        return new MeshPhysicalMaterial({
            color: "#0f687f",
            roughness: 0.7,
            sheenColor: new Color("#26ffff"),
            sheen: 0.5,
            sheenRoughness: 0.3,
            transparent: true
        });
    }, []);

    const haloMat = useMemo(() => {
        return new OutlineGlowLitInvertMaterial({
            color: new Color("#26ffff"),
            transparent: true,
            outlineThickness: 0.5,
            outlineOpacity: 0.0,
            glowStartOpacity: 0.5,
            glowEndOpacity: 0.0,
            origin: EOrigin.Top,
        });
    }, []);

    const outlineMat = useMemo(() => {
        return new MeshBasicMaterial({
            color: new Color("#26ffff"),
            transparent: true,
        });
    }, []);

    const dotsMat = useMemo(() => {
        return new MaskedDotMaterial({
            color: "#26ffff",
            transparent: true,
            opacity: 1,
            mask: maskTex,
            radius: 0.005,
            gap: 0.002,
            scale: [1, 2]
        });
    }, [maskTex]);

    useEffect(() => {
        globeMesh.traverse(child => {
            if (!TSUtils.isMesh(child)) return;
            child.material = globeMat;
        });
    }, [globeMesh, globeMat]);

    useEffect(() => {
        haloMesh.traverse(child => {
            if (!TSUtils.isMesh(child)) return;
            child.material = haloMat;
        });
    }, [haloMesh, haloMat]);

    useEffect(() => {
        outlineMesh.traverse(child => {
            if (!TSUtils.isMesh(child)) return;
            child.material = outlineMat;
        });
    }, [outlineMesh, outlineMat]);

    useEffect(() => {
        dotsMesh.traverse(child => {
            if (!TSUtils.isMesh(child)) return;
            child.material = dotsMat;
        });
    }, [dotsMesh, dotsMat]);

    useOpacity([globeMat, outlineMat, haloMat, dotsMat], opacity, true);

    useFrame((state, dt) => {
        if (enabled) ref.current?.rotateY(angularSpeed * dt);
    });

    return (
        <Suspense>
            <animated.group ref={groupRef} {...props}>
                <primitive object={haloMesh} position={[0.02, 0.025, 0]} />
                <primitive object={outlineMesh} />
                <group rotation-x={0.5}>
                    <group ref={ref} rotation-y={start * MathUtils.deg2Rad}>
                        <primitive object={globeMesh} />
                        <primitive object={dotsMesh} />
                    </group>
                </group>
            </animated.group>
        </Suspense>
    );
});