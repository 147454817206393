import styles from "@styles/SonoOne.module.css";
import { Dispatch, ReactElement, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Group } from "three";
import { a } from "@react-spring/web";
import { animated, config, easings, SpringValue, useChain, useSpring, useSpringRef } from "@react-spring/three";
import { useFrame, useThree } from "@react-three/fiber";
import { Environment, useTexture } from "@react-three/drei";
import type { CanvasProps, HtmlProps, PageModule } from "@pages/Page";
import { EPage } from "@pages/Pages";
import Card from "@components/Card";
import { AnimatedTypeEffect } from "@components/TypeEffect";
import Button from "@components/Button";
import ScrollInstructions from "@components/ScrollInstructions";
import ShiftedCamera from "@components/ShiftedCamera";
import FollowMouse from "@components/FollowMouse";
import PointerView from "@components/PointerView";
import { AnimatedContactShadows } from "@components/lib/ContactShadows";
import { Phone } from "@components/Phone";
import Tag, { ETagSide } from "@components/Tag";
import { MDInhaler, useMDIBottle, useMDInhaler } from "@components/MDInhaler";
import { useTurbohaler } from "@components/Turbohaler";
import { useEllipta } from "@components/Ellipta";
import { Shower } from "@components/Shower";
import { AirQuality, ChestTightness, ColorProps, Coughing, Fatigue, Humidity, Pain, Pollen, ShortnessOfBreath, TroubleSleeping, Wheezing, Wind } from "@components/Icons";
import Carousel from "@components/Carousel";
import { SonohalerRing } from "@components/SonohalerRing";
import { Circle } from "@components/Circle";
import { BreathingCapsule } from "@components/Capsule";
import { MLConnections } from "@components/MLConnections";
import { BreathingWave } from "@components/Wave";
import { Globe } from "@components/Globe";
import Video, { useVideo, useVideoTexture } from "@components/Video";
import { LoadingContext } from "@contexts/LoadingContext";
import { SonoOneContext } from "@contexts/SonoOneContext";
import { Easing } from "@animations/Easing";
import { useViewport } from "@utils/ViewportManager";
import { ThreeUtils } from "@utils/ThreeUtils";
import { MathUtils } from "@utils/MathUtils";
import useMappedEasing from "@utils/useMappedEasing";
import usePositionSpring from "@utils/usePositionSpring";
import useQuaternionSpring from "@utils/useQuaternionSpring";
import { EnumDictionary } from "@utils/TSUtils";
import { shadowLayer, speed } from "@utils/Settings";
import { EPerformanceMode } from "@utils/usePerformance";
import data from "@data/Text.json";
const text = data.SonoOne;

const page: EPage = EPage.SonoOne;
export default {
    Html,
    Canvas,
    page
} as PageModule;

export enum EFeature {
    None = 1,
    SmartThroughSound = 2,
    UserCentricDesign = 3,
    Sustainability = 4
};

export enum ESymptom {
    None = 0,
    Coughing = 1,
    Wheezing = 2,
    ShortnessOfBreath = 4,
    ChestTightness = 8,
    Fatigue = 16,
    Pain = 32,
    TroubleSleeping = 64
}

const symptomTitle: EnumDictionary<ESymptom, string> = {
    [ESymptom.None]: "",
    [ESymptom.Coughing]: text.Symptoms.Coughing,
    [ESymptom.Wheezing]: text.Symptoms.Wheezing,
    [ESymptom.ShortnessOfBreath]: text.Symptoms.ShortnessOfBreath,
    [ESymptom.ChestTightness]: text.Symptoms.ChestTightness,
    [ESymptom.Fatigue]: text.Symptoms.Fatigue,
    [ESymptom.Pain]: text.Symptoms.Pain,
    [ESymptom.TroubleSleeping]: text.Symptoms.TroubleSleeping,
}

export enum ETrigger {
    None = 0,
    AirQuality = 1,
    Pollen = 2,
    Humidity = 4,
    Wind = 8
}

const triggerTitle: EnumDictionary<ETrigger, string> = {
    [ETrigger.None]: "",
    [ETrigger.AirQuality]: text.Triggers.AirQuality,
    [ETrigger.Pollen]: text.Triggers.Pollen,
    [ETrigger.Humidity]: text.Triggers.Humidity,
    [ETrigger.Wind]: text.Triggers.Wind,
}

function Html(props: HtmlProps) {
    const { loaded } = useContext(LoadingContext);
    const context = useContext(SonoOneContext);
    const { state, dispatch } = context || {};
    const [view] = useViewport<HTMLDivElement>(page);

    const titleSpring = useSpringRef();
    const subtitle1Spring = useSpringRef();
    const subtitle2Spring = useSpringRef();
    const innerSpring = useSpringRef();
    const scrollSpring = useSpringRef();
    const [{ titlePercent }] = useSpring(() => ({
        titlePercent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 200, precision: 0.001 },
        ref: titleSpring
    }), [state?.index]);
    const [{ subtitle1Percent }] = useSpring(() => ({
        subtitle1Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        delay: 200,
        config: { duration: 400, precision: 0.001 },
        ref: subtitle1Spring
    }), [state?.index]);
    const [{ subtitle2Percent }] = useSpring(() => ({
        subtitle2Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 400, precision: 0.001 },
        ref: subtitle2Spring
    }), [state?.index]);
    const [{ innerOpacity }] = useSpring(() => ({
        innerOpacity: (state?.index ?? 0) > 0 ? 1 : 0,
        delay: 200,
        config: { duration: 500, precision: 0.001 },
        ref: innerSpring
    }), [state?.index]);
    const [{ showScroll }] = useSpring(() => ({
        showScroll: (state?.index ?? 0) > 0 ? true : false,
        delay: 200,
        config: { duration: 1 },
        ref: scrollSpring
    }), [state?.index]);
    useChain([titleSpring, subtitle1Spring, subtitle2Spring, innerSpring, scrollSpring]);

    const [{
        smartThroughSoundOpacity,
        userCentricDesignOpacity,
        sustainabilityOpacity
    }] = useSpring(() => ({
        smartThroughSoundOpacity: state?.feature === EFeature.SmartThroughSound ? 1 : 0,
        userCentricDesignOpacity: state?.feature === EFeature.UserCentricDesign ? 1 : 0,
        sustainabilityOpacity: state?.feature === EFeature.Sustainability ? 1 : 0,
        config: { duration: 1000, precision: 0.001 }
    }), [state?.feature]);

    const [{
        symptomsOpacity,
        triggersOpacity
    }] = useSpring(() => ({
        symptomsOpacity: state?.feature === EFeature.UserCentricDesign && state?.page[EFeature.UserCentricDesign] === 1 ? 1 : 0,
        triggersOpacity: state?.feature === EFeature.UserCentricDesign && state?.page[EFeature.UserCentricDesign] === 2 ? 1 : 0,
        config: { duration: 1000, precision: 0.001 }
    }), [state?.feature, state?.page[EFeature.UserCentricDesign]]);

    const [{
        smartThroughSoundTime
    }] = useSpring(() => ({
        smartThroughSoundTime: state?.feature === EFeature.SmartThroughSound && state?.page[EFeature.SmartThroughSound] === 1 ? 20 : 0,
        config: { duration: state?.feature === EFeature.SmartThroughSound && state?.page[EFeature.SmartThroughSound] === 1 ? 15000 : 1, precision: 0.001 }
    }), [state?.feature, state?.page[EFeature.SmartThroughSound]]);

    useEffect(() => {
        let index = 0;
        if (loaded && state?.shown) index = 1;
        if (state?.feature !== EFeature.None) index = 2;
        dispatch?.({ type: "set", index: index });
    }, [dispatch, loaded, state?.shown, state?.index, state?.feature]);

    useEffect(() => {
        if (!props.active) return;
        dispatch?.({ type: "show" });
        dispatch?.({ type: "feature", feature: EFeature.None });
    }, [dispatch, props.active]);

    const click = useCallback((feature: EFeature) => {
        dispatch?.({ type: "feature", feature: feature });
    }, [dispatch]);

    const featureStyle = useCallback((feature: EFeature, opacity: SpringValue<number>) => {
        const active = feature === state?.feature;
        const ease = (t: number) => t;
        const tOut = (t: number) => MathUtils.mapRange(t, 1, 0.5, 1, 0, true);
        const tIn = (t: number) => MathUtils.mapRange(t, 0.5, 1, 0, 1, true);
        const transform = active ? tIn : tOut;
        return {
            opacity: opacity.to(t => ease(transform(t))),
            display: opacity.to(t => Math.round(t) === 1 ? "" : "none")
        };
    }, [state?.feature]);

    const pageStyle = useCallback((feature: EFeature, page: number, opacity: SpringValue<number>) => {
        const active = page === state?.page[feature];
        const ease = (t: number) => t;
        const tOut = (t: number) => MathUtils.mapRange(t, 1, 0.5, 1, 0, true);
        const tIn = (t: number) => MathUtils.mapRange(t, 0.5, 1, 0, 1, true);
        const transform = active ? tIn : tOut;
        return {
            opacity: opacity.to(t => ease(transform(t))),
            display: opacity.to(t => Math.round(t) === 1 ? "" : "none")
        };
    }, [state?.page]);

    const getSmartThroughSoundTitleOpacity = useCallback((time: SpringValue<number>) => {
        return time.to(t => {
            return MathUtils.mapRanges(t,
                [0, 0.5, 3.499, 3.5, 4, 10.499, 10.5, 11.0, 19.499, 19.5, 20],
                [0, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1],
                true
            );
        })
    }, []);

    const getSmartThroughSoundTitle = useCallback((time: SpringValue<number>) => {
        return time.to(t => {
            if (t < 3.5) return text.SmartThroughSound.keyword1;
            if (t < 10.5) return text.SmartThroughSound.keyword2;
            if (t < 19.5) return text.SmartThroughSound.keyword3;
            return text.SmartThroughSound.keyword4;
        });
    }, []);

    return (
        <div className={styles.root}>
            <Card>
                <div className={styles.static}>
                    <div className={styles.staticContent}>
                        <div className={styles.header}>
                            <AnimatedTypeEffect type="h1" text={text.title1} percent={titlePercent} /><br />
                            <AnimatedTypeEffect type="h2" text={text.subtitle1} percent={subtitle1Percent} />
                            <AnimatedTypeEffect type="h2" className="accent1" text={text.subtitle2} percent={subtitle2Percent} occupy />
                        </div>
                        <a.div className={styles.brief} style={{ opacity: innerOpacity }}>
                            <div>
                                <p>{text.text1}</p>
                            </div>
                        </a.div>
                        <div className={styles.buttons}>
                            <Button label={text.SmartThroughSound.button} active={state?.feature === EFeature.SmartThroughSound} onClick={() => click(EFeature.SmartThroughSound)} />
                            <Button label={text.UserCentricDesign.button} active={state?.feature === EFeature.UserCentricDesign} onClick={() => click(EFeature.UserCentricDesign)} />
                            <Button label={text.Sustainability.button} active={state?.feature === EFeature.Sustainability} onClick={() => click(EFeature.Sustainability)} />
                        </div>
                    </div>
                </div>
                <div ref={view} className={styles.dynamic}></div>
                <a.div className="scroll" style={{ opacity: showScroll.to(value => value ? "1" : "0") }}>
                    <ScrollInstructions mobile={props.mobile} />
                </a.div>
                <div className={styles.canvasOverlay} style={{ transform: props.mobile ? `translateY(${state?.offset ?? 0}px)` : `translateX(${state?.offset ?? 0}px)` }}>
                    <a.div className={styles.iconOverlay} style={pageStyle(EFeature.UserCentricDesign, 1, symptomsOpacity)}>
                        <Symptoms wrap={props.mobile} />
                    </a.div>
                    <a.div className={styles.iconOverlay} style={pageStyle(EFeature.UserCentricDesign, 2, triggersOpacity)}>
                        <Triggers />
                    </a.div>
                    <div className={styles.titleOverlay}>
                        <a.h1 className={`${styles.overlayTitle} accent1`} style={{ opacity: getSmartThroughSoundTitleOpacity(smartThroughSoundTime) }}>
                            {getSmartThroughSoundTitle(smartThroughSoundTime)}
                        </a.h1>
                    </div>
                    <div className={styles.feature}>
                        <a.div style={featureStyle(EFeature.SmartThroughSound, smartThroughSoundOpacity)}>
                            <Carousel pages={2} buttonPosition="top" infinite onChange={(page) => {
                                if (state?.feature !== EFeature.SmartThroughSound) return;
                                dispatch?.({ type: "page", page: { key: EFeature.SmartThroughSound, index: page } });
                            }}>
                                {({ page }) => (
                                    <>
                                        <SmartThroughSoundTitle page={page} />
                                        <SmartThroughSoundText page={page} />
                                    </>
                                )}
                            </Carousel>
                        </a.div>
                        <a.div style={featureStyle(EFeature.UserCentricDesign, userCentricDesignOpacity)}>
                            <Carousel pages={4} buttonPosition="top" infinite onChange={(page) => {
                                if (state?.feature !== EFeature.UserCentricDesign) return;
                                dispatch?.({ type: "page", page: { key: EFeature.UserCentricDesign, index: page } });
                            }}>
                                {({ page }) => (
                                    <>
                                        <UserCentricDesignTitle page={page} />
                                        <UserCentricDesignText page={page} />
                                    </>
                                )}
                            </Carousel>
                        </a.div>
                        <a.div style={featureStyle(EFeature.Sustainability, sustainabilityOpacity)}>
                            <Carousel pages={1} buttonPosition="top" infinite onChange={(page) => {
                                if (state?.feature !== EFeature.Sustainability) return;
                                dispatch?.({ type: "page", page: { key: EFeature.Sustainability, index: page } });
                            }}>
                                {({ page }) => (
                                    <>
                                        <SustainabilityTitle page={page} />
                                        <SustainabilityText page={page} />
                                    </>
                                )}
                            </Carousel>
                        </a.div>
                    </div>
                </div>
                <Video src={analyticsVideo} resolution={props.mobile ? 512 : 1024}
                    muted loop restart hidden texture
                    play={state?.feature === EFeature.UserCentricDesign && state?.page[EFeature.UserCentricDesign] === 0}
                />
                <Video src={trackingVideo} resolution={props.mobile ? 512 : 1024}
                    muted loop restart hidden texture
                    play={state?.feature === EFeature.UserCentricDesign && state?.page[EFeature.UserCentricDesign] === 0}
                />
            </Card>
        </div>
    );
}

function SmartThroughSoundTitle({ page }: { page: number }) {
    return (
        <>
            {page === 0 &&
                <h2 style={{ margin: 0, textAlign: "center" }}>{text.SmartThroughSound.title1}</h2>
            }
            {page === 1 &&
                <h2 style={{ margin: 0, textAlign: "center" }}>{text.SmartThroughSound.title2}</h2>
            }
        </>
    )
}

function SmartThroughSoundText({ page }: { page: number }) {
    return (
        <>
            {page === 0 &&
                <p style={{ textAlign: "center" }}>{text.SmartThroughSound.text1}</p>
            }
            {page === 1 &&
                <p style={{ textAlign: "center" }}>{text.SmartThroughSound.text2}</p>
            }
        </>
    );
}

function UserCentricDesignTitle({ page }: { page: number }) {
    return (
        <>
            {page === 0 &&
                <h2 style={{ margin: 0, textAlign: "center" }}>{text.UserCentricDesign.title1}</h2>
            }
            {page === 1 &&
                <h2 style={{ margin: 0, textAlign: "center" }}>{text.UserCentricDesign.title2}</h2>
            }
            {page === 2 &&
                <h2 style={{ margin: 0, textAlign: "center" }}>{text.UserCentricDesign.title3}</h2>
            }
            {page === 3 &&
                <h2 style={{ margin: 0, textAlign: "center" }}>{text.UserCentricDesign.title4}</h2>
            }
        </>
    );
}

function UserCentricDesignText({ page }: { page: number }) {
    return (
        <>
            {page === 0 &&
                <p style={{ textAlign: "center" }}>{text.UserCentricDesign.text1}</p>
            }
            {page === 1 &&
                <p style={{ textAlign: "center" }}>{text.UserCentricDesign.text2}</p>
            }
            {page === 2 &&
                <p style={{ textAlign: "center" }}>{text.UserCentricDesign.text3}</p>
            }
            {page === 3 &&
                <p style={{ textAlign: "center" }}>{text.UserCentricDesign.text4}</p>
            }
        </>
    );
}

function SustainabilityTitle({ page }: { page: number }) {
    return (
        <>
            {page === 0 &&
                <h2 style={{ margin: 0, textAlign: "center" }}>{text.Sustainability.title1}</h2>
            }
        </>
    );
}

function SustainabilityText({ page }: { page: number }) {
    return (
        <>
            {page === 0 &&
                <p style={{ textAlign: "center" }}>{text.Sustainability.text1}</p>
            }
        </>
    );
}

function Symptoms({ wrap }: { wrap: boolean }) {
    const [symptom, setSymptom] = useState(ESymptom.Coughing);

    const [{ titleOpacity }] = useSpring(() => ({
        from: { titleOpacity: 0 },
        to: { titleOpacity: 1 },
        reset: true,
        config: { duration: 500, precision: 0.001 }
    }), [symptom]);

    return (
        <>
            <div className={styles.icons}>
                <Icon value={ESymptom.Coughing} Icon={Coughing} active={symptom} setActive={setSymptom} />
                <Icon value={ESymptom.Wheezing} Icon={Wheezing} active={symptom} setActive={setSymptom} />
                <Icon value={ESymptom.ShortnessOfBreath} Icon={ShortnessOfBreath} active={symptom} setActive={setSymptom} />
                <Icon value={ESymptom.ChestTightness} Icon={ChestTightness} active={symptom} setActive={setSymptom} />
                {wrap && <div className={styles.forceWrap} />}
                <Icon value={ESymptom.Fatigue} Icon={Fatigue} active={symptom} setActive={setSymptom} />
                <Icon value={ESymptom.Pain} Icon={Pain} active={symptom} setActive={setSymptom} />
                <Icon value={ESymptom.TroubleSleeping} Icon={TroubleSleeping} active={symptom} setActive={setSymptom} />
            </div>
            <a.div className={`${styles.iconTitle}`} style={{ opacity: titleOpacity }}>
                <h1 className="accent1">{symptomTitle[symptom]}</h1>
            </a.div>
        </>
    );
}

function Triggers() {
    const [trigger, setTrigger] = useState(ETrigger.AirQuality);

    const [{ titleOpacity }] = useSpring(() => ({
        from: { titleOpacity: 0 },
        to: { titleOpacity: 1 },
        reset: true,
        config: { duration: 500, precision: 0.001 }
    }), [trigger]);

    return (
        <>
            <div className={styles.icons}>
                <Icon value={ETrigger.AirQuality} Icon={AirQuality} active={trigger} setActive={setTrigger} />
                <Icon value={ETrigger.Pollen} Icon={Pollen} active={trigger} setActive={setTrigger} />
                <Icon value={ETrigger.Humidity} Icon={Humidity} active={trigger} setActive={setTrigger} />
                <Icon value={ETrigger.Wind} Icon={Wind} active={trigger} setActive={setTrigger} />
            </div>
            <a.div className={styles.iconTitle} style={{ opacity: titleOpacity }}>
                <h1 className="accent1">{triggerTitle[trigger]}</h1>
            </a.div>
        </>
    );
}

function Icon<T extends number | string>({ value, Icon, active, setActive }: {
    value: T;
    Icon: (props: Omit<ColorProps, "background">) => JSX.Element;
    active: T;
    setActive: Dispatch<SetStateAction<T>>;
}): ReactElement {
    const [isHovered, setHovered] = useState(false);
    const isActive = value === active;

    const [{ size }] = useSpring(() => ({
        size: isHovered || isActive ? 100 : 50,
        config: { ...config.wobbly, precision: 0.001 }
    }), [isHovered, isActive]);

    const [{ color }] = useSpring(() => ({
        color: isActive ? "#26ffff" : "#ffffff",
        config: { ...config.gentle, precision: 0.001 }
    }), [isActive]);

    const AnimatedIcon = a(Icon);

    return (
        <a.div style={{ width: size.to(value => `${value}rem`), height: size.to(value => `${value}rem`) }}>
            <div className={styles.icon}
                onPointerEnter={() => setHovered(true)}
                onPointerLeave={() => setHovered(false)}
                onClick={() => setActive(value)}
            >
                <AnimatedIcon color={color} />
            </div>
        </a.div>
    );
}

function Canvas(props: CanvasProps) {
    const [view] = useViewport<HTMLDivElement>(page);

    const context = useContext(SonoOneContext);
    const { dispatch, state } = context || {};
    const onChange = useCallback((offset: [x: number, y: number], pixels: [x: number, y: number]) => {
        dispatch?.({ type: "offset", offset: props.mobile ? pixels[1] : pixels[0] });
    }, [dispatch, props.mobile]);

    const isUserCentricDesign = state?.feature === EFeature.UserCentricDesign;
    const userCentricDesignPage = state?.page[EFeature.UserCentricDesign];
    const isSustainability = state?.feature === EFeature.Sustainability;

    const shift: [x: number, y: number] = props.mobile ? [0, 0.5] : [-0.5, 0];
    const z: number = props.mobile ? state?.feature === EFeature.None ? 15 : state?.feature === EFeature.Sustainability ? 17 : 20 : 10

    const mouseInfluence: [x: number, y: number] = isUserCentricDesign && (userCentricDesignPage === 1 || userCentricDesignPage === 2)
        ? props.mobile ? [0.20, 0.02] : [0.10, 0.02]
        : isUserCentricDesign && userCentricDesignPage === 3
            ? props.mobile ? [0.60, 0.20] : [0.30, 0.20]
            : isSustainability
                ? [3.00, 0.50]
                : props.mobile ? [0.30, 0.15] : [0.10, 0.05];

    return (
        <PointerView ref={view} alwaysUpdate={false} clear={true}>
            <ShiftedCamera enabled={props.active} shift={shift} fov={40} position-z={z} onChange={onChange} />
            {(props.active || props.mode !== EPerformanceMode.Performance) && <>
                <directionalLight intensity={1.0} position={[0.1, 5, -0.1]} castShadow />
                <ambientLight intensity={0.2} />
                <Environment files={`${process.env.PUBLIC_URL}/textures/city.exr`} />
                <FollowMouse influence={mouseInfluence} ease={Easing.inoutQuad}>
                    <ClickyMDIInhaler />
                    <RingViz />
                    <SonoOnePhone mobile={props.mobile} />
                </FollowMouse>
                <group position-y={1.1}>
                    <FollowMouse influence={mouseInfluence} ease={Easing.inoutQuad}>
                        <SonoOneSonohalerRing />
                    </FollowMouse>
                    <SonoOneGlobe />
                </group>
                <SonoOneShower />
                <Shadows />
            </>}
        </PointerView>
    );
}

function Shadows() {
    const context = useContext(SonoOneContext);
    const { state } = context || {};

    const getPosition = useCallback((feature: EFeature | undefined) => {
        switch (feature) {
            default: case EFeature.None: return -2;
            case EFeature.SmartThroughSound: return 0;
            case EFeature.UserCentricDesign: return -0.6;
            case EFeature.Sustainability: return 0;
        }
    }, []);

    const getOpacity = useCallback((feature: EFeature | undefined, page: number | undefined) => {
        switch (feature) {
            default: case EFeature.None: return 0.5;
            case EFeature.SmartThroughSound: return 0;
            case EFeature.UserCentricDesign: return (page ?? 0) < 3 ? 0.5 : 0;
            case EFeature.Sustainability: return 0;
        }
    }, [])

    const [{ opacity }] = useSpring(() => ({
        from: { opacity: getOpacity(state?.prevFeature, state?.prevPage[EFeature.UserCentricDesign]) },
        to: { opacity: getOpacity(state?.feature, state?.page[EFeature.UserCentricDesign]) },
        config: config.gentle
    }), [state?.prevFeature, state?.feature, state?.prevPage[EFeature.UserCentricDesign], state?.page[EFeature.UserCentricDesign]]);

    return (
        <AnimatedContactShadows position-y={getPosition(state?.feature)} scale={5} blur={5} far={3} opacity={opacity} layers={shadowLayer} />
    );
}

function ClickyMDIInhaler() {
    const context = useContext(SonoOneContext);
    const { state } = context || {};

    const click = useMappedEasing([
        { start: 0.00, end: 0.30, from: 0.5, to: 0.1, easing: easings.easeInOutQuad },
        { start: 0.30, end: 0.45, from: 0.1, to: 0.0, easing: easings.easeInExpo },
        { start: 0.45, end: 0.55, from: 0.0, to: 0.0 },
        { start: 0.55, end: 0.70, from: 0.0, to: 0.1, easing: easings.easeOutExpo },
        { start: 0.70, end: 0.90, from: 0.1, to: 0.5, easing: easings.easeInOutQuad },
        { start: 0.90, end: 1.00, from: 0.5, to: 0.5 },
    ]);

    const bounce = useMappedEasing([
        { start: 0.00, end: 0.40, from: -1.0, to: -1.0 },
        { start: 0.40, end: 0.45, from: -1.0, to: -1.1, easing: easings.easeInQuad },
        { start: 0.45, end: 0.50, from: -1.1, to: -1.0, easing: easings.easeOutQuad },
        { start: 0.50, end: 1.00, from: -1.0, to: -1.0 }
    ]);

    const [{ spring }] = useSpring(() => ({
        from: { spring: 0 },
        to: { spring: 1 },
        loop: true,
        config: { duration: 4000, precision: 0.001 }
    }), []);

    const [{ opacity }] = useSpring(() => ({
        opacity: state?.feature === EFeature.None ? 1 : 0,
        config: { duration: 500, easing: easings.easeOutQuad, precision: 0.001 }
    }), [state?.feature]);

    return (
        <animated.group position-y={spring.to(bounce)}>
            <MDInhaler shadow
                opacity={opacity}
                quaternion={ThreeUtils.toQuat([0, 40, 0, "YXZ"], true)}
                scale={30}
            />
            <SonohalerRing
                opacity={opacity}
                position-y={spring.to(click)}
                quaternion={ThreeUtils.toQuat([0, 40, 0, "YXZ"], true)}
                scale={30}
            />
        </animated.group>
    );
}

function easeInOutQuadMod(t: number) {
    return easings.easeInOutQuad(t % 1) + Math.floor(t);
}

function RingViz() {
    // Page 0:
    //   Rotation 15 deg pitch                 dyn
    //   SonohalerRing fade in               0      1
    //   Circles fade in                     0      1
    // 
    // Page 1:
    //   Rotation 5 deg pitch                  dyn
    //   T: AUDIO SIGNAL                     0    0.5
    //   SonohalerRing fade out              0      1
    //   Waves fade in                       1      2
    //   Circles fade out                    3      4
    //   T: FLOW PATTERN                   3.5      4
    //   Waves reduce amplitude              5      6
    //   Waves fade out                      7      8
    //   ML algorithms layer 0 fade in       7    7.5
    //   T: ML ALGORITHMS                  7.5      8
    //   ML algorithms layer 0 shift         8     10
    //   ML algorithms layer 0 up           10     11
    //   ML algorithms layer 1 up           10     11
    //   ML algorithms layer 1 fade in      11   11.5
    //   ML algorithms layer 1 down         11     12
    //   ML algorithms lines 1 fade in    11.5     12
    //   ML algorithms layer 0 up 2         13     14
    //   ML algorithms layer 1 up           13     14
    //   ML algorithms layer 2 up           13     14
    //   ML algorithms layer 2 fade in      14   14.5
    //   ML algorithms layer 2 down         14     15
    //   ML algorithms lines 2 fade in    14.5     15
    //   ML algorithms layer 0 fade out     18   18.5
    //   ML algorithms lines 1 fade out   18.5     19
    //   ML algorithms layer 1 fade out     19   19.5
    //   ML algorithms lines 2 fade out   19.5     20
    //   T: PERFORMANCE METRICS           19.5     20      

    const context = useContext(SonoOneContext);
    const { state } = context || {};

    const isFeature = state?.feature === EFeature.SmartThroughSound;
    const wasFeature = state?.prevFeature === EFeature.SmartThroughSound;
    const featureChanged = !wasFeature && isFeature;
    const page = state?.page[EFeature.SmartThroughSound];
    const prevPage = state?.prevPage[EFeature.SmartThroughSound];


    const getRotation = useCallback((feature: EFeature | undefined, page: number | undefined) => {
        if (feature !== EFeature.SmartThroughSound) page = 0;
        switch (page) {
            default: case 0: return ThreeUtils.toQuat([15, 0, 0, "YXZ"], true);
            case 1: return ThreeUtils.toQuat([5, 0, 0, "YXZ"], true);
        }
    }, []);

    const [{ page0Spring }] = useSpring(() => ({
        from: { page0Spring: isFeature && !featureChanged && prevPage === 0 ? 1 : 0 },
        to: { page0Spring: isFeature && page === 0 ? 1 : 0 },
        config: { duration: 500, precision: 0.001 }
    }), [isFeature, featureChanged, prevPage, page]);

    const [{ page1Spring }] = useSpring(() => ({
        from: { page1Spring: isFeature && !featureChanged && prevPage === 1 ? 20 : 0 },
        to: { page1Spring: isFeature && page === 1 ? 20 : 0 },
        config: { duration: isFeature && page ? 15000 : 1, precision: 0.001 }
    }), [isFeature, featureChanged, prevPage, page]);

    const ringOpacity = page0Spring.to((x: number) => {
        return MathUtils.mapRanges(x, [0, 1], [0, 1], true);
    });

    const circleOpacity = !isFeature ? 0 : page === 0
        ? page0Spring.to((x: number) => {
            return MathUtils.mapRanges(x, [0, 1], [0, 1], true);
        })
        : page1Spring.to((x: number) => {
            return MathUtils.mapRanges(x, [3, 4], [1, 0], true);
        });

    const waveOpacity = isFeature && page === 1
        ? page1Spring.to((x: number) => {
            return MathUtils.mapRanges(x, [1, 2, 7, 8], [0, 1, 1, 0], true);
        })
        : 0;

    const waveAmplitude = page1Spring.to((x: number) => {
        return easings.easeInQuad(MathUtils.mapRanges(x, [5, 6], [1, 0], true)) * 6;
    });

    const layer0Opacity = isFeature && page === 1
        ? page1Spring.to((x: number) => {
            return MathUtils.mapRanges(x, [7, 7.5, 18, 18.5], [0, 1, 1, 0], true);
        })
        : 0;

    const layer1Opacity = isFeature && page === 1
        ? page1Spring.to((x: number) => {
            return MathUtils.mapRanges(x, [11, 11.5, 19, 19.5], [0, 1, 1, 0], true);
        })
        : 0;

    const layer2Opacity = isFeature && page === 1
        ? page1Spring.to((x: number) => {
            return MathUtils.mapRanges(x, [14, 14.5], [0, 1], true);
        })
        : 0;

    const line1From = page1Spring.to((x: number) => {
        return easings.easeInOutQuad(MathUtils.mapRanges(x, [18.5, 19], [0, 1], true));
    });

    const line1To = page1Spring.to((x: number) => {
        return easings.easeInOutQuad(MathUtils.mapRanges(x, [11.5, 12], [0, 1], true));
    });

    const line2From = page1Spring.to((x: number) => {
        return easings.easeInOutQuad(MathUtils.mapRanges(x, [19.5, 20], [0, 1], true));
    });

    const line2To = page1Spring.to((x: number) => {
        return easings.easeInOutQuad(MathUtils.mapRanges(x, [14.5, 15], [0, 1], true));
    });

    const layer0Shift = page1Spring.to((x: number) => {
        return easings.easeInOutQuad(MathUtils.mapRanges(x, [8, 10], [0, 1], true));
    });

    const layer0Up = page1Spring.to((x: number) => {
        return easeInOutQuadMod(MathUtils.mapRanges(x, [10, 11, 13, 14], [0, 1, 1, 2], true));
    });

    const layer1Up = page1Spring.to((x: number) => {
        return easings.easeInOutQuad(MathUtils.mapRanges(x, [10, 11, 11, 12, 13, 14], [0, 1, 1, 0, 0, 1], true));
    });

    const layer2Up = page1Spring.to((x: number) => {
        return easings.easeInOutQuad(MathUtils.mapRanges(x, [13, 14, 14, 15], [0, 1, 1, 0], true));
    });

    const doScale = page1Spring.to((x: number) => {
        return x >= 19.9;
    });

    const [{ rotation }] = useQuaternionSpring({
        get: getRotation,
        from: [state?.feature, state?.prevPage[EFeature.SmartThroughSound]],
        to: [state?.feature, state?.page[EFeature.SmartThroughSound]],
        config: { ...config.gentle, precision: 0.001 }
    });

    const circles = useMemo(() => {
        const count = 16;
        const samples = 500;
        const amplitude = 0.6;
        const startRadius = 1.5;
        const gap = 0.11;
        const circles = new Array<ReactElement>();
        for (let i = 0; i < count; ++i) {
            const radius = startRadius + i * gap;
            const shift = i / (count - 1) * 0.5 - 0.21; // Not entirely sure why the circle phase is offset by 0.21
            circles.push(<Circle key={`+${i}`} samples={samples} radius={radius} amplitude={amplitude} shift={-shift} opacity={circleOpacity} renderOrder={-1} />);
            circles.push(<Circle key={`-${i}`} samples={samples} radius={radius} amplitude={-amplitude} shift={-shift} opacity={circleOpacity} renderOrder={-1} />);
        }
        return circles;
    }, [circleOpacity]);

    const circlesRef = useRef<Group>(null);

    useFrame((state, dt) => {
        if (!circlesRef.current) return;
        if (isFeature && page === 0) {
            const rot = circlesRef.current.rotation;
            rot.y += 45 * MathUtils.deg2Rad * dt;
            circlesRef.current.rotation.copy(rot);
        } else {
            const rot = circlesRef.current.rotation;
            const target = MathUtils.ceilTo(rot.y, Math.PI);
            rot.y = MathUtils.lerp(rot.y, target, dt * 2);
            circlesRef.current.rotation.copy(rot);
        }
    });

    return (
        <animated.group
            position={ThreeUtils.toVec3([0, 0.25, 0])}
            {...rotation}
        >
            <group
                position={ThreeUtils.toVec3([0.75, -4.275, 0])}
                quaternion={ThreeUtils.toQuat([10, -115, 0, "YXZ"], true)}
                scale={75}
            >
                <SonohalerRing materialType="transmissive"
                    opacity={ringOpacity}
                />
                <SonohalerRing materialType="wire"
                    opacity={ringOpacity.to(x => x * 0.5)}
                />
            </group>
            <group
                quaternion={ThreeUtils.toQuat([0, -90, 0, "YXZ"], true)}
            >
                <group ref={circlesRef}>
                    {circles}
                </group>
            </group>
            <group>
                <BreathingWave count={8} steps={7} direction={1} size={1.54} opacity={waveOpacity} amplitude={waveAmplitude} scale={0.08} position={[1.5, 0, 0]} />
                <BreathingWave count={8} steps={7} direction={-1} size={1.54} opacity={waveOpacity} amplitude={waveAmplitude} scale={0.08} position={[-1.5, 0, 0]} />
            </group>
            <MLAlgorithms
                opacity0={layer0Opacity} opacity1={layer1Opacity} opacity2={layer2Opacity}
                from1={line1From} to1={line1To}
                from2={line2From} to2={line2To}
                shift={layer0Shift}
                up0={layer0Up} up1={layer1Up} up2={layer2Up}
                doScale={doScale}
            />
        </animated.group>
    );
}

type MLAlgorithmsProps = {
    opacity0: number;
    opacity1: number;
    opacity2: number;
    from1: number;
    to1: number;
    from2: number;
    to2: number;
    shift: number;
    up0: number;
    up1: number;
    up2: number;
    doScale: boolean;
}
const MLAlgorithms = animated(function MLAlgorithms({
    opacity0, opacity1, opacity2,
    from1, to1,
    from2, to2,
    shift,
    up0, up1, up2,
    doScale
}: MLAlgorithmsProps) {
    const count0 = 8;
    const count1 = 6;
    const count2 = 4;

    const steps = 7;
    const step = 1 / steps;

    const defaultStart = 1.5;
    const defaultGap = 0.44;
    const size = 2 * (defaultStart + defaultGap * count0 * 0.5) * 0.7;
    const targetGap = size / count0;
    const targetStart = targetGap * 0.5;
    const height = targetGap;
    const offset = (count1 - count2) * 0.5 * targetGap;

    const start = MathUtils.lerp(defaultStart, targetStart, shift);
    const gap = MathUtils.lerp(defaultGap, targetGap, shift);

    const l2Ref = useRef<Array<Group | null>>(new Array(count2).fill(null));

    const dotsL0 = useMemo(() => {
        const dots = [];
        for (let i = 0; i < count0 * 0.5; ++i) {
            dots.push(<BreathingCapsule key={`L0 +${i}`}
                opacity={opacity0}
                amplitude={0}
                offset={-i * step * 2}
                position={[(start + i * gap) * 1, up0 * height, 0.001]}
            />);
            dots.push(<BreathingCapsule key={`L0 -${i}`}
                opacity={opacity0}
                amplitude={0}
                offset={-i * step * 2}
                position={[(start + i * gap) * -1, up0 * height, 0.001]}
            />);
        }
        return dots;
    }, [count0, opacity0, step, start, gap, up0, height]);

    const dotsL1 = useMemo(() => {
        const dots = [];
        for (let i = 0; i < count1 * 0.5; ++i) {
            dots.push(<BreathingCapsule key={`L1 +${i}`}
                opacity={opacity1}
                amplitude={0}
                offset={-i * step * 2}
                position={[(start + i * gap) * 1, up1 * height, 0.001]}
            />);
            dots.push(<BreathingCapsule key={`L1 -${i}`}
                opacity={opacity1}
                amplitude={0}
                offset={-i * step * 2}
                position={[(start + i * gap) * -1, up1 * height, 0.001]}
            />);
        }
        return dots;
    }, [count1, opacity1, step, start, gap, up1, height]);

    const dotsL2 = useMemo(() => {
        const dots = [];
        for (let i = 0; i < count2 * 0.5; ++i) {
            dots.push(<BreathingCapsule key={`L2 +${i}`}
                opacity={opacity2}
                amplitude={0}
                offset={-i * step * 2}
                position={[(start + i * gap) * 1, up2 * height, 0.001]}
                groupRef={(ref) => l2Ref.current[i + count2 * 0.5] = ref}
            />);
            dots.push(<BreathingCapsule key={`L2 -${i}`}
                opacity={opacity2}
                amplitude={0}
                offset={-i * step * 2}
                position={[(start + i * gap) * -1, up2 * height, 0.001]}
                groupRef={(ref) => l2Ref.current[count2 * 0.5 - i - 1] = ref}
            />);
        }
        return dots;
    }, [count2, opacity2, step, start, gap, up2, height]);

    const { clock } = useThree();
    const [time, setTime] = useState(clock.elapsedTime);
    useFrame(({ clock }) => {
        if (doScale) {
            for (let i = 0; i < l2Ref.current.length; ++i) {
                const dot = l2Ref.current[i];
                if (!dot) continue;
                const t = ((clock.elapsedTime - time) * speed) % 1;
                const at = MathUtils.mapRanges(t, [i / count2, (i + 0.5) / count2, (i + 1) / count2], [0, 1, 0], true);
                const scl = easings.easeInOutQuad(at);
                dot.scale.setScalar(0.08 + scl * 0.04);
            }
        }
    });

    useEffect(() => {
        setTime(clock.elapsedTime);
    }, [clock, doScale]);

    return (
        <group>
            {dotsL0}
            {dotsL1}
            {dotsL2}
            <MLConnections
                from={from1} to={to1}
                nodesTop={count0} nodesBottom={count1}
                spacing={targetGap}
                position={[targetStart - size * 0.5, (up0 - 1) * height, 0]}
                renderOrder={-1}
            />
            <MLConnections
                from={from2} to={to2}
                nodesTop={count1} nodesBottom={count2}
                spacing={targetGap}
                position={[targetStart - size * 0.5 + offset, (up1 - 1) * height, 0]}
                renderOrder={-1}
            />
        </group>
    )
});

const analyticsVideo = `${process.env.PUBLIC_URL}/videos/SA-Performance_Analytics-0v2@$res`;
const trackingVideo = `${process.env.PUBLIC_URL}/videos/SA-AdherenceTracking-0v3@$res`;
const welcomeScreen = `${process.env.PUBLIC_URL}/textures/T_IphoneScreen_Welcome_A.png`;
useTexture.preload(welcomeScreen);
function SonoOnePhone({ mobile }: { mobile: boolean }) {
    const context = useContext(SonoOneContext);
    const { dispatch, state } = context || {};

    const [activeTag, setActiveTag] = useState(0);
    const [previousTag, setPreviousTag] = useState(activeTag);

    const analyticsHandle = useVideo(analyticsVideo, mobile ? 512 : 1024);
    const trackingHandle = useVideo(trackingVideo, mobile ? 512 : 1024);
    useEffect(() => {
        analyticsHandle.current?.restart();
        trackingHandle.current?.restart();
    }, [activeTag, analyticsHandle, trackingHandle]);

    const analyticsTexture = useVideoTexture(analyticsVideo, mobile ? 512 : 1024);
    const trackingTexture = useVideoTexture(trackingVideo, mobile ? 512 : 1024);
    const welcomeTexture = useTexture(welcomeScreen);

    const setTag = useCallback((tag: SetStateAction<number>) => {
        dispatch?.({ type: "syncPage" });
        setActiveTag(t => {
            setPreviousTag(t);
            if (typeof tag === "function") return tag(t);
            return tag;
        });
    }, [dispatch, setActiveTag]);

    useEffect(() => {
        setTimeout(() => {
            setPreviousTag(activeTag);
        }, 500);
    }, [activeTag]);

    const getPosition = useCallback((page: number | undefined) => {
        switch (page) {
            default: case 0:
                return ThreeUtils.toVec3([0, 0, 0]);
            case 1:
                return ThreeUtils.toVec3([1.3, -0.3, 0]);
            case 2:
                return ThreeUtils.toVec3([1.3, -0.3, 0]);
            case 3:
                return ThreeUtils.toVec3([0, -0.3, 0]);
        }
    }, []);

    const getRotation = useCallback((page: number | undefined, tag: number | undefined) => {
        switch (page) {
            default: case 0:
                return ThreeUtils.toQuat([0, tag === 1 ? 20 : -20, 0, "YXZ"], true);
            case 1:
                return ThreeUtils.toQuat([-80, 0, 90, "YXZ"], true);
            case 2:
                return ThreeUtils.toQuat([-80, 0, 90, "YXZ"], true);
            case 3:
                return ThreeUtils.toQuat([0, 0, 0, "YXZ"], true);
        }
    }, []);

    const getScale = useCallback((page: number | undefined) => {
        switch (page) {
            default: case 0: return 32;
            case 1: return 32;
            case 2: return 32;
            case 3: return 32;
        }
    }, []);

    const getBrightness = useCallback((page: number | undefined, tag: number | undefined) => {
        switch (page) {
            default: case 0: return tag === 1 ? 1 : 1;
            case 1: return 1;
            case 2: return 1;
            case 3: return 1;
        }
    }, []);

    const getImage = useCallback((page: number | undefined, tag: number | undefined) => {
        switch (page) {
            default: case 0: return tag === 1 ? trackingTexture.current : analyticsTexture.current;
            case 1: return welcomeTexture;
            case 2: return welcomeTexture;
            case 3: return welcomeTexture;
        }
    }, [analyticsTexture, trackingTexture, welcomeTexture]);

    const [{ position }] = usePositionSpring({
        get: getPosition,
        from: [state?.prevPage[EFeature.UserCentricDesign]],
        to: [state?.page[EFeature.UserCentricDesign]],
        config: { ...config.wobbly, precision: 0.001 }
    });

    const [{ rotation }] = useQuaternionSpring({
        get: getRotation,
        from: [state?.prevPage[EFeature.UserCentricDesign], previousTag],
        to: [state?.page[EFeature.UserCentricDesign], activeTag],
        config: { tension: 100, friction: 20, precision: 0.001 }
    });

    const [{ scale }] = useSpring(() => ({
        from: { scale: getScale(state?.prevPage[EFeature.UserCentricDesign]) },
        to: { scale: getScale(state?.page[EFeature.UserCentricDesign]) },
        config: { ...config.wobbly, precision: 0.001 }
    }), [state?.prevPage, state?.page]);

    const [{ brightness }] = useSpring(() => ({
        from: { brightness: 0 },
        to: { brightness: getBrightness(state?.page[EFeature.UserCentricDesign], activeTag) },
        reset: true,
        config: { ...config.gentle, precision: 0.001 }
    }), [state?.page, activeTag]);

    const [{ opacity }] = useSpring(() => ({
        from: { opacity: state?.prevFeature === EFeature.UserCentricDesign ? 1 : 0 },
        to: { opacity: state?.feature === EFeature.UserCentricDesign ? 1 : 0 },
        config: { duration: 500, precision: 0.001 }
    }), [state?.prevFeature, state?.feature]);

    const [{ tagOpacity }] = useSpring(() => ({
        from: { tagOpacity: state?.feature === EFeature.UserCentricDesign && state?.prevPage[EFeature.UserCentricDesign] === 0 ? 1 : 0 },
        to: { tagOpacity: state?.feature === EFeature.UserCentricDesign && state?.page[EFeature.UserCentricDesign] === 0 ? 1 : 0 },
        config: { duration: 250, precision: 0.001 }
    }), [state?.feature, state?.prevPage, state?.page]);

    return (
        <Phone shadow={state?.page[EFeature.UserCentricDesign] !== 3}
            image={getImage(state?.page[EFeature.UserCentricDesign], activeTag)}
            brightness={brightness}
            {...position}
            {...rotation}
            scale={scale}
            opacity={opacity}
            renderOrder={1}
        >
            <group scale={0.01}>
                <Tag index={0} text={text.UserCentricDesign.tag1} pin={{ side: ETagSide.Left }} opacity={tagOpacity} active={activeTag} setActive={setTag} position={[2, 7.5, 0.5]} scale={1} />
                <Tag index={1} text={text.UserCentricDesign.tag2} pin={{ side: ETagSide.Right }} opacity={tagOpacity} active={activeTag} setActive={setTag} position={[-2, 2.8, 0.5]} scale={1} />
            </group>
        </Phone>
    );
}

function SonoOneShower() {
    const context = useContext(SonoOneContext);
    const { state } = context || {};

    const [{ opacity }] = useSpring(() => ({
        from: { opacity: state?.feature === EFeature.UserCentricDesign && state?.prevPage[EFeature.UserCentricDesign] === 3 ? 1 : 0 },
        to: { opacity: state?.feature === EFeature.UserCentricDesign && state?.page[EFeature.UserCentricDesign] === 3 ? 1 : 0 },
        config: { duration: 500, precision: 0.001 }
    }), [state?.feature, state?.page[EFeature.UserCentricDesign]]);

    const inhaler = useMDInhaler({ opacity: 0 });
    const bottle = useMDIBottle({ opacity: 0 });
    const turbohaler = useTurbohaler({ opacity: 0 });
    const ellipta = useEllipta({ opacity: 0 });

    const meshDefinitions = useMemo(() => [
        { mesh: inhaler, index: 0, scale: 50 },
        { mesh: bottle, index: 0, scale: 50 },
        { mesh: turbohaler, index: 1, scale: 50 },
        { mesh: ellipta, index: 2, scale: 50 },
    ], [inhaler, bottle, turbohaler, ellipta]);

    return (
        <Shower count={24} speed={1} width={0.25} depth={[10, 25]} opacity={opacity} meshDefinitions={meshDefinitions} />
    );
}

function SonoOneGlobe() {
    const context = useContext(SonoOneContext);
    const { state } = context || {};

    const [{ opacity }] = useSpring(() => ({
        from: { opacity: state?.prevFeature === EFeature.Sustainability ? 1 : 0 },
        to: { opacity: state?.feature === EFeature.Sustainability ? 1 : 0 },
        config: { duration: 500, precision: 0.001 }
    }), [state?.prevFeature, state?.feature]);

    return (
        <Globe angularSpeed={-0.05} opacity={opacity} scale={2} />
    )
}

function SonoOneSonohalerRing() {
    const context = useContext(SonoOneContext);
    const { state } = context || {};

    const [{ opacity }] = useSpring(() => ({
        from: { opacity: state?.prevFeature === EFeature.Sustainability ? 1 : 0 },
        to: { opacity: state?.feature === EFeature.Sustainability ? 1 : 0 },
        config: { duration: 500, precision: 0.001 }
    }), [state?.prevFeature, state?.feature]);

    return (
        <group>
            <group rotation-x={10 * MathUtils.deg2Rad} scale={[95, 50, 95]}>
                <group position-z={-0.00195} position-y={-0.056343}>
                    <SonohalerRing opacity={opacity} materialType={"transmissive"} />
                </group>
            </group>
        </group>
    );
}