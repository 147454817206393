export class ShaderUtils {
    static prepend(s: string, content: string, newLine: boolean = true) {
        let value = content;
        if (newLine) value += "\n";
        return value + s;
    }

    static inject(s: string, inject: string, content: string, newLine: boolean = true) {
        let value = inject;
        if (newLine) value += "\n";
        value += content;
        return s.replace(inject, value);
    }

    static extract<P extends { [key: string]: any }>(params: P, ...keys: Array<keyof P>) {
        const values: Partial<P> = {};
        for (const key of keys) {
            const value = params[key];
            values[key] = value;
            delete params[key];
        }
        return values;
    }

    static assign<T, P extends { [key: string]: any }>(material: T, params: P) {
        for (const key in params) {
            const param = params[key];
            if (param) (material as any)[key] = params[key];
        }
    }
}