import styles from "@styles/SonoTwo.module.css";
import { useCallback, useContext, useEffect } from "react";
import { a } from "@react-spring/web";
import { useChain, useSpring, useSpringRef } from "@react-spring/three";
import { Environment, useGLTF } from "@react-three/drei";
import type { CanvasProps, HtmlProps, PageModule } from "@pages/Page";
import { EPage } from "@pages/Pages";
import Card from "@components/Card";
import { AnimatedTypeEffect } from "@components/TypeEffect";
import ScrollInstructions from "@components/ScrollInstructions";
import PointerView from "@components/PointerView";
import ShiftedCamera from "@components/ShiftedCamera";
import FollowMouse from "@components/FollowMouse";
import { LoadingContext } from "@contexts/LoadingContext";
import { SonoTwoContext } from "@contexts/SonoTwoContext";
import { Easing } from "@animations/Easing";
import { useViewport } from "@utils/ViewportManager";
import useMesh from "@utils/useMesh";
import { MathUtils } from "@utils/MathUtils";
import { EPerformanceMode } from "@utils/usePerformance";
import data from "@data/Text.json";
const text = data.SonoTwo;

const page: EPage = EPage.SonoTwo;
export default {
    Html,
    Canvas,
    page
} as PageModule;

function Html(props: HtmlProps) {
    const { loaded } = useContext(LoadingContext);
    const context = useContext(SonoTwoContext);
    const { state, dispatch } = context || {};
    const [view] = useViewport<HTMLDivElement>(page);

    const titleSpring = useSpringRef();
    const subtitle1Spring = useSpringRef();
    const subtitle2Spring = useSpringRef();
    const innerSpring = useSpringRef();
    const scrollSpring = useSpringRef();
    const [{ titlePercent }] = useSpring(() => ({
        titlePercent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 200, precision: 0.001 },
        ref: titleSpring
    }), [state?.index]);
    const [{ subtitle1Percent }] = useSpring(() => ({
        subtitle1Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        delay: 200,
        config: { duration: 400, precision: 0.001 },
        ref: subtitle1Spring
    }), [state?.index]);
    const [{ subtitle2Percent }] = useSpring(() => ({
        subtitle2Percent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 400, precision: 0.001 },
        ref: subtitle2Spring
    }), [state?.index]);
    const [{ innerOpacity }] = useSpring(() => ({
        innerOpacity: (state?.index ?? 0) > 0 ? 1 : 0,
        delay: 200,
        config: { duration: 500, precision: 0.001 },
        ref: innerSpring
    }), [state?.index]);
    const [{ showScroll }] = useSpring(() => ({
        showScroll: (state?.index ?? 0) > 0 ? true : false,
        delay: 200,
        config: { duration: 1 },
        ref: scrollSpring
    }), [state?.index]);
    useChain([titleSpring, subtitle1Spring, subtitle2Spring, innerSpring, scrollSpring]);

    useEffect(() => {
        let index = 0;
        if (loaded && state?.shown) index = 1;
        dispatch?.({ type: "set", index: index });
    }, [dispatch, loaded, state?.shown, state?.index]);

    useEffect(() => {
        if (!props.active) return;
        dispatch?.({ type: "show" });
    }, [dispatch, props.active]);

    return (
        <div className={styles.root}>
            <Card>
                <div className={styles.static}>
                    <div className={styles.staticContent}>
                        <div className={styles.header}>
                            <AnimatedTypeEffect type="h1" text={text.title1} percent={titlePercent} /><br />
                            <AnimatedTypeEffect type="h2" className="accent1" text={text.subtitle1} percent={subtitle1Percent} />
                            <AnimatedTypeEffect type="h2" text={text.subtitle2} percent={subtitle2Percent} occupy />
                        </div>
                        <a.div className={styles.brief} style={{ opacity: innerOpacity }}>
                            <div>
                                <p>{text.text1}</p>
                                <p>{text.text2}</p>
                            </div>
                        </a.div>
                    </div>
                </div>
                <div ref={view} className={styles.dynamic}></div>
                <a.div className="scroll" style={{ opacity: showScroll.to(value => value ? "1" : "0") }}>
                    <ScrollInstructions mobile={props.mobile} />
                </a.div>
            </Card>
        </div>
    );
}

function Canvas(props: CanvasProps) {
    const [view] = useViewport<HTMLDivElement>(page);

    const context = useContext(SonoTwoContext);
    const { dispatch } = context || {};
    const onChange = useCallback((offset: [x: number, y: number], pixels: [x: number, y: number]) => {
        dispatch?.({ type: "offset", offset: props.mobile ? pixels[1] : pixels[0] });
    }, [dispatch, props.mobile]);

    const shift: [x: number, y: number] = props.mobile ? [0, 0.5] : [0.5, 0];
    const z: number = props.mobile ? 20 : 10;

    const mouseInfluence: [x: number, y: number] = props.mobile ? [0.20, 0.20] : [0.10, 0.10];

    return (
        <PointerView ref={view} alwaysUpdate={false} clear={true}>
            <ShiftedCamera enabled={props.active} shift={shift} fov={40} position-z={z} onChange={onChange} />
            {(props.active || props.mode !== EPerformanceMode.Performance) && <>
                <directionalLight intensity={1.0} position={[0.1, 5, -0.1]} castShadow />
                <ambientLight intensity={0.2} />
                <Environment files={`${process.env.PUBLIC_URL}/textures/city.exr`} />
                <FollowMouse influence={mouseInfluence} ease={Easing.inoutQuad}>
                    <SonoTwoMystery />
                </FollowMouse>
            </>}
        </PointerView>
    );
}

const cloth = `${process.env.PUBLIC_URL}/models/SonoTwo-Present-0v1.glb`;
useGLTF.preload(cloth);
function SonoTwoMystery() {
    const [mesh] = useMesh(cloth, { copyHierarchy: true });

    return (
        <group rotation-x={15 * MathUtils.deg2Rad}>
            <primitive object={mesh} scale={42} position-y={-1.25} rotation-y={45 * MathUtils.deg2Rad} />
        </group>
    )
}