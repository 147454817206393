import { MeshBasicMaterial, MeshBasicMaterialParameters, Shader, Uniform, WebGLRenderer } from "three";
import { ShaderUtils } from "@utils/ShaderUtils";

import frag_pars from "@shaders/Fill/Fill.pars.frag";
import frag_inject from "@shaders/Fill/Fill.inject.frag";
import frag from "@shaders/Fill/Fill.frag";

type Params = {
    from?: number;
    to?: number;
};
export type { Params as FillMaterialParams };

export class FillMaterial extends MeshBasicMaterial {
    private _from: Uniform<number> = new Uniform(0);
    private _to: Uniform<number> = new Uniform(1);

    constructor(params: Params & MeshBasicMaterialParameters) {
        const p = ShaderUtils.extract(params, 
            "from", "to"
        );
        super(params);
        if (!this.defines) this.defines = {};
        this.defines.USE_UV = "";
        ShaderUtils.assign(this, p);
    }

    onBeforeCompile(shader: Shader, renderer: WebGLRenderer) {
        super.onBeforeCompile(shader, renderer);

        shader.uniforms.fillFrom = this._from;
        shader.uniforms.fillTo = this._to;

        shader.fragmentShader = ShaderUtils.prepend(shader.fragmentShader, frag_pars, true);
        shader.fragmentShader = ShaderUtils.inject(shader.fragmentShader, frag_inject, frag, true);
    }

    public get from() { return this._from.value; }
    public set from(value: number) { this._from.value = value; }

    public get to() { return this._to.value; }
    public set to(value: number) { this._to.value = value; }
};