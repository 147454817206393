import { MutableRefObject, StrictMode, useRef } from "react";
import { CameraProps, Canvas, OrthographicCameraProps, PerspectiveCameraProps } from "@react-three/fiber";
import { PerformanceMonitor, Preload } from "@react-three/drei";
import { Raycaster } from "three";
import { Perf } from "r3f-perf"
import Root from "@components/Root";
import Loading from "@components/Loading";
import Navbar from "@components/Navbar";
import Page, { PageR3F } from "@pages/Page";
import { AppContextProvider } from "@contexts/AppContext";
import { PagesContextProvider } from "@contexts/PagesContext";
import { EUpdateMode, Updater } from "@utils/Updater";
import { raycastLayer, renderLayer } from "@utils/Settings";

export const cameraSettings: Partial<CameraProps & PerspectiveCameraProps & OrthographicCameraProps> = {
    fov: 40,
    layers: renderLayer,
    near: 0.1,
    far: 100,
    position: [0, 0, 10]
};

export const raycasterSettings: Partial<Raycaster> = {
    layers: raycastLayer
};

export default function App() {
    const canvas = useRef<HTMLCanvasElement>() as MutableRefObject<HTMLCanvasElement>;
    Updater.instance.mode = EUpdateMode.Auto;
    return (
        <Root id="root" singlePage dynamic={[1920, 1080, 720, 1440]} mobile={1.2}>
            {({ pageWidth, pageHeight, mobile, ref }) => {
                return (
                    <AppContextProvider root={ref.current} deps={[mobile]}>
                        <Loading>
                            <PagesContextProvider canvas={canvas} mobile={mobile}>
                                <Navbar mobile={mobile} />
                                <Page />
                                <Canvas
                                    ref={canvas}
                                    shadows
                                    flat
                                    dpr={[1, 2]}
                                    camera={cameraSettings}
                                    raycaster={raycasterSettings}
                                    eventSource={ref.current!}
                                    style={{
                                        position: "fixed", left: "0px", top: "var(--navbar-height)",
                                        width: `${pageWidth}px`, height: `calc(${pageHeight}px - var(--navbar-height))`,
                                        pointerEvents: "none", zIndex: 1
                                    }}
                                    onCreated={(state) => {
                                        state.setEvents({
                                            compute: (event, state) => {
                                                state.pointer.set(event.pageX / state.size.width * 2 - 1, -event.pageY / state.size.height * 2 + 1);
                                                state.raycaster.setFromCamera(state.pointer, state.camera);
                                            }
                                        });
                                    }}
                                >
                                    <StrictMode>
                                        {process.env.NODE_ENV === "development" && <Perf position="bottom-left" />}
                                        <PerformanceMonitor bounds={(hz) => [45, hz]} factor={1}>
                                            <PageR3F />
                                        </PerformanceMonitor>
                                        <Preload all />
                                    </StrictMode>
                                </Canvas>
                            </PagesContextProvider>
                        </Loading>
                    </AppContextProvider>
                )
            }}
        </Root>
    );
}