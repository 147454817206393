import { DependencyList, ReactElement, cloneElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OptArray, TSUtils } from "@utils/TSUtils";
import useResize from "@utils/useResize";
import { appContext } from "@contexts/AppContext";
import { Updater } from "@utils/Updater";
import { JSUtils } from "@utils/JSUtils";

type Props<T> = {
    setSize: React.Dispatch<React.SetStateAction<DOMRect>>;
    offset?: boolean;
    dynamic?: boolean;
    hidden?: boolean;
    children: ReactElement<T>;
    omit?: OptArray<keyof T>;
    deps?: DependencyList;
};

export default function Measure<T>({ setSize, offset = false, dynamic = false, hidden = false, children, omit = [], deps = [] }: Props<T>) {
    const ref = useRef<HTMLDivElement>(null);
    const prev = useRef<DOMRect>(new DOMRect(0, 0, 1, 1));
    const timeSinceChange = useRef<number>(0);

    const isChanged = useCallback((rect: DOMRect) => {
        return prev.current.width !== rect.width
            || prev.current.height !== rect.height
            || prev.current.x !== rect.x
            || prev.current.y !== rect.y;
    }, []);

    const save = useCallback((rect: DOMRect) => {
        prev.current.width = rect.width;
        prev.current.height = rect.height;
        prev.current.x = rect.x;
        prev.current.y = rect.y;
    }, []);

    const set = useCallback(() => {
        if (!ref.current) return;

        // Get the rect from DOM
        const rect = ref.current.getBoundingClientRect();

        if (offset) {
            // Use offset sizes
            rect.width = ref.current.offsetWidth;
            rect.height = ref.current.offsetHeight;
        }

        if (dynamic) {
            // Get dynamic scale
            const scale = parseFloat(appContext.getCustomStyle("scale") ?? "1");

            // Get the original rect, before dynamic scale is applied
            rect.width /= scale;
            rect.height /= scale;
            rect.x /= scale;
            rect.y /= scale;
        }

        // Only update state if rect is changed
        if (!isChanged(rect)) return;
        timeSinceChange.current = 0;

        // Do not consume space in DOM
        // Note: Only height can be excluded from DOM. Width seems to expand to infinity
        ref.current.style.marginBottom = `-${rect.height}${dynamic ? "rem" : "px"}`;

        // Save the rect for next call
        save(rect);

        // Update state
        setSize(rect);
    }, [offset, dynamic, setSize, isChanged, save]);
    useResize(set, true);

    const internal = useMemo(() => {
        const props = { ...children.props } as Partial<T>;
        TSUtils.forEach(omit, key => {
            props[key] = undefined;
        });
        const clone = cloneElement(children, props);
        return clone;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, omit, ...deps]);

    useEffect(() => {
        const id = `Measure-${JSUtils.guid()}`;
        Updater.instance.add(0, id, (dt) => {
            // If size hasn't changed for 5 seconds, we can assume that
            // the DOM has finished layouting
            timeSinceChange.current += dt;
            if (timeSinceChange.current > 5) return;
            
            set();
        });
        return (() => void Updater.instance.remove(0, id));
    }, [set]);

    useEffect(() => {
        set();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, set]);

    return (
        <>
            {!hidden && children}
            <div ref={ref} style={{ visibility: "hidden", position: "relative", pointerEvents: "none", width: "fit-content", height: "fit-content" }}>
                {internal}
            </div>
        </>
    )
};

export function useMeasure() {
    return useState<DOMRect>(new DOMRect(0, 0, 1, 1));
}