import { PropsWithChildren, createContext, useCallback } from "react";
import { PageAction, PageContextProps, PageState, usePageContext } from "@contexts/PageContext";
import Mission from "@pages/Mission";

type State = PageState & {
    readonly shown: boolean;
    readonly offset: number;
};

type Action = PageAction & {
    offset?: number;
};

type ProviderProps = {
} & PropsWithChildren;

export const MissionContext = createContext<PageContextProps<State, Action>>(null!);

export function MissionContextProvider({ children }: ProviderProps) {
    const initial: State = {
        index: 0,
        shown: false,
        offset: 0
    };
    const showHandler = useCallback((state: State, action: Action) => {
        return { ...state, shown: true };
    }, []);
    const offsetHandler = useCallback((state: State, action: Action) => {
        if (action.offset === undefined) return state;
        if (action.offset === state.offset) return state;
        return { ...state, offset: action.offset };
    }, []);
    const [state, dispatch] = usePageContext<State, Action>(Mission, initial, {
        "show": showHandler,
        "offset": offsetHandler
    });
    return (
        <MissionContext.Provider value={{ state, dispatch }}>
            {MissionContext && children}
        </MissionContext.Provider>
    );
};