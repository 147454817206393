import { Object3D } from "three";
import { PropsWithChildren, useRef } from "react";
import useFollowMouse, { type FollowOptions } from "@utils/useFollowMouse";

export default function FollowMouse(props: FollowOptions & PropsWithChildren) {
    const objects = useRef<Array<Object3D>>([null!]);
    useFollowMouse(objects.current, props as FollowOptions);
    return (
        <group ref={ref => objects.current[0] = ref!}>
            {props.children}
        </group>
    );
}