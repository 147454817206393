import { Ref, Suspense, useEffect, useMemo, useState } from "react";
import { Color, Group } from "three";
import { GroupProps, useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { animated } from "@react-spring/three";
import { PhasedDisplaceMaterial } from "@shaders/PhasedDisplace/PhasedDisplaceMaterialShader";
import { PhasedDisplaceOutlineGlowMaterial } from "@shaders/PhasedDisplaceOutlineGlow/PhasedDisplaceOutlineGlowMaterialShader";
import { EOrigin } from "@shaders/OutlineGlow/OutlineGlowMaterialShader";
import useMesh from "@utils/useMesh";
import { speed } from "@utils/Settings";
import { MathUtils } from "@utils/MathUtils";
import { TSUtils } from "@utils/TSUtils";
import useOpacity from "@utils/useOpacity";

type Props = {
    /** Capsule scale */
    scale?: number;
    /** Capsule opacity */
    opacity: number;
    /** Displacement amplitude */
    amplitude?: number;
    /** Phase offset */
    offset?: number;
    groupRef?: Ref<Group>;
} & Omit<GroupProps, "ref" | "scale">;

type CapsuleProps = {
    /** Run phase animation? */
    animate?: boolean;
} & Props;

const capsule = `${process.env.PUBLIC_URL}/models/Capsule-0v4.glb`;
useGLTF.preload(capsule);
export const Capsule = animated(function Capsule({ scale = 0.08, opacity, amplitude = 6, offset = 0, animate = false, groupRef, ...props }: CapsuleProps) {
    const [mesh] = useMesh(capsule, { copyHierarchy: true });

    const capsuleMaterial = useMemo(() => {
        return new PhasedDisplaceMaterial({
            color: new Color("#61c8de"),
            transparent: true,
            opacity: 0,
            depthWrite: false,
            depthTest: false,
            phase: offset,
            amplitude: 0
        });
    }, [offset]);

    const outlineMaterial = useMemo(() => {
        return new PhasedDisplaceOutlineGlowMaterial({
            color: new Color("#26ffff"),
            transparent: true,
            opacity: 0,
            depthWrite: false,
            depthTest: false,
            phase: offset,
            amplitude: 0,
            outlineThickness: 0.4,
            outlineOpacity: 1,
            glowStartOpacity: 0.3,
            glowEndOpacity: 0,
            origin: EOrigin.Top
        });
    }, [offset]);

    useOpacity([capsuleMaterial, outlineMaterial], opacity, false);

    useEffect(() => {
        capsuleMaterial.amplitude = amplitude;
        outlineMaterial.amplitude = amplitude;
    }, [capsuleMaterial, outlineMaterial, amplitude]);

    useMemo(() => {
        mesh.traverse((child) => {
            if (!TSUtils.isMesh(child)) return;
            const mat = child.name.endsWith("_1") ? outlineMaterial : capsuleMaterial;
            child.material = mat;
        });
    }, [mesh, outlineMaterial, capsuleMaterial]);

    useFrame(({ clock }) => {
        if (!animate) return;
        const phase = clock.elapsedTime * speed + offset;
        capsuleMaterial.phase = phase;
        outlineMaterial.phase = phase;
    });

    mesh.scale.setScalar(scale);
    mesh.rotation.set(0, -Math.PI * 0.5, 0);
    if (props.renderOrder) mesh.renderOrder = props.renderOrder;

    return (
        <Suspense>
            <primitive ref={groupRef} object={mesh} {...props} />
        </Suspense>
    );
});

type ShiftingCapsuleProps = {
    phase: number;
    repeat: number;
    size: number;
    direction: 1 | -1;
    width: number;
} & Props;

export const ShiftingCapsule = animated(function ShiftingCapsule({ phase, amplitude = 6, repeat, size, offset = 0, direction, width, opacity, scale = 0.08, ...props }: ShiftingCapsuleProps) {
    const [time, setTime] = useState(0);
    useFrame(({ clock }) => {
        setTime(clock.elapsedTime * speed);
    });

    const p = (time - phase) / repeat;
    const x = MathUtils.mod(p, 1) * size * direction;
    opacity *= p <= offset ? 0 : MathUtils.mapRange(x, 0, Math.min(size, width) * direction, 1, 0, true);

    return (
        <group position-x={-x}>
            <Capsule
                scale={scale}
                offset={phase}
                amplitude={amplitude}
                opacity={opacity}
                {...props}
            />
        </group>
    )
});

type BreathingCapsuleProps = {
} & Props;

export const BreathingCapsule = animated(function BreathingCapsule({ opacity, amplitude = 6, offset, scale = 0.08, ...props }: BreathingCapsuleProps) {
    return (
        <group>
            <Capsule animate
                scale={scale}
                offset={offset}
                amplitude={amplitude}
                opacity={opacity}
                renderOrder={1}
                {...props}
            />
        </group>
    );
});