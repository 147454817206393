import { usePerformanceMonitor } from "@react-three/drei";
import { useReducer } from "react";

export enum EPerformanceMode {
    Quality,
    Performance
};

export default function usePerformance() {
    const [mode, optimize] = useReducer(() => EPerformanceMode.Performance, EPerformanceMode.Quality);
    usePerformanceMonitor({ onDecline: optimize, onFallback: optimize });
    return mode;
}