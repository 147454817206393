import { Object3D } from "three";
import { useFrame } from "@react-three/fiber";
import { EaserType, Easing } from "@animations/Easing";
import { MathUtils } from "@utils/MathUtils";
import { OptArray, TSUtils } from "@utils/TSUtils";

export type FollowOptions = {
    influence?: OptArray<number>;
    smoothing?: number;
    ease?: EaserType;
}

export default function useFollowMouse(objects: OptArray<Object3D>, {
    influence = 0.05,
    smoothing = 0.98,
    ease = Easing.inoutQuad
}: FollowOptions) {
    const easing = Easing.getEaser(ease);
    useFrame(({ mouse }) => {
        const pitch = easing(MathUtils.mapRange(mouse.y, 1, -1, 0, 1, true)) * 2 - 1;
        const yaw = easing(MathUtils.mapRange(mouse.x, -1, 1, 0, 1, true)) * 2 - 1;

        TSUtils.forEach(objects, (obj) => {
            const rot = obj.rotation;
            rot.x = MathUtils.lerp(rot.x, pitch * TSUtils.y(influence), 1 - smoothing);
            rot.y = MathUtils.lerp(rot.y, yaw * TSUtils.x(influence), 1 - smoothing);
            obj.rotation.copy(rot);
        });
    });
}