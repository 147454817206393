import { Ref, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { animated } from "@react-spring/three";
import { GroupProps, useThree } from "@react-three/fiber";
import { BreathingCapsule, ShiftingCapsule } from "@components/Capsule";
import { speed } from "@utils/Settings";
import { useSize } from "@utils/useOffset";

export type Initializer = () => void;

type WaveProps = {
    count: number;
    steps: number;
    direction: 1 | -1;
    size: number;
    opacity: number;
    amplitude?: number;
    scale?: number;
} & Omit<GroupProps, "ref" | "scale">;

type ShiftingWaveProps = {
    breathing?: boolean;
    screenSize: number;
    shiftOpacity: number;
    initializer?: Ref<Initializer>;
} & WaveProps;

export const ShiftingWave = animated(function ShiftingWave({ breathing = false, count, steps, size, screenSize, direction, opacity, shiftOpacity, amplitude = 6, scale = 0.08, initializer, ...props }: ShiftingWaveProps) {
    const { clock } = useThree();
    const [width] = useSize();

    const [start, setStart] = useState(0);
    const [offset, setOffset] = useState(0);
    const repeat = count / steps;

    const initialize = useCallback(() => {
        const phase = clock.elapsedTime * speed / repeat;
        const step = 1 / steps / repeat;
        const start = Math.ceil((phase % 1) / step);
        setStart(start);
        setOffset(phase - start * step);
    }, [clock, repeat, steps, setStart, setOffset]);

    useImperativeHandle(initializer, () => initialize, [initialize]);

    const capsules = useMemo(() => {
        const capsules = [];
        const step = 1 / steps;
        const w = width * screenSize;
        for (let i = 0; i < count; ++i) {
            const j = i < start ? i + count : i;
            capsules.push(<ShiftingCapsule key={i} phase={step * j} amplitude={amplitude} repeat={repeat} size={size} offset={offset} direction={direction} width={w} opacity={opacity * shiftOpacity} scale={scale} />)
        }
        return capsules;
    }, [steps, width, screenSize, count, start, amplitude, repeat, size, offset, direction, opacity, shiftOpacity, scale]);

    return (
        <group {...props}>
            {breathing && <BreathingCapsule key="source" opacity={opacity} amplitude={amplitude} offset={0} scale={scale} position-z={0.01} />}
            {capsules}
        </group>
    );
});

type BreathingWaveProps = {
} & WaveProps;

export const BreathingWave = animated(function BreathingWave({ count, steps, direction, size, opacity, amplitude = 6, scale = 0.08, ...props }: BreathingWaveProps) {
    const capsules = useMemo(() => {
        const capsules = [];
        const step = 1 / steps;
        const gap = size / (count - 1);
        for (let i = 0; i < count; ++i) {
            capsules.push(<BreathingCapsule key={i} opacity={opacity} amplitude={amplitude} offset={-i * step} position={[(i * gap) * direction, 0, 0]} scale={scale} />);
        }
        return capsules;
    }, [steps, size, count, opacity, amplitude, direction, scale]);
    return (
        <group {...props}>
            {capsules}
        </group>
    )
});