import { PropsWithChildren, createContext, useCallback } from "react";
import { PageAction, PageContextProps, PageState, usePageContext } from "@contexts/PageContext";
import SonoTwo from "@pages/SonoTwo";

type State = PageState & {
    readonly shown: boolean;
    readonly offset: number;
};

type Action = PageAction & {
    offset?: number;
};

type ProviderProps = {
} & PropsWithChildren;

export const SonoTwoContext = createContext<PageContextProps<State, Action>>(null!);

export function SonoTwoContextProvider({ children }: ProviderProps) {
    const initial: State = {
        index: 0,
        shown: false,
        offset: 0
    };
    const showHandler = useCallback((state: State, action: Action) => {
        return { ...state, shown: true };
    }, []);
    const offsetHandler = useCallback((state: State, action: Action) => {
        if (action.offset === undefined) return state;
        if (action.offset === state.offset) return state;
        return { ...state, offset: action.offset };
    }, []);
    const [state, dispatch] = usePageContext<State, Action>(SonoTwo, initial, {
        "show": showHandler,
        "offset": offsetHandler
    });
    return (
        <SonoTwoContext.Provider value={{ state, dispatch }}>
            {SonoTwoContext && children}
        </SonoTwoContext.Provider>
    );
};