import { useMemo } from "react";
import { Camera, useThree } from "@react-three/fiber";

/**
 * Compute an offset from a relative viewport position
 * @param position Relative X and Y viewport position [-1, 1]
 * @param z Optionally, the Z position. For perspective cameras, this computes the proper size for an object at the given distance in Z-axis from the camera
 * @param camera Provide a camera to calculate the viewport position from. Defaults to current Three camera.
 * @returns The absolute X and Y positions, and the unit to pixel factor. Multiply X and Y with factor to get offset in pixels.
 */
export default function useOffset(position: [x: number, y: number], z: number = 0, camera?: Camera | null): [x: number, y: number, factor: number] {
    const { camera: defaultCamera, events, size, viewport } = useThree();
    const cam = camera ?? defaultCamera;
    return useMemo(() => {
        const viewSize = (events.connected as HTMLElement | null | undefined)?.getBoundingClientRect() ?? size;
        const { width, height, factor } = viewport.getCurrentViewport(cam, [0, 0, z], viewSize);
        return [width * 0.5 * position[0], height * 0.5 * position[1], factor];
    }, [cam, events, size, viewport, position, z]);
}

/**
 * Compute the size of the viewport
 * @param z Optionally, the Z position. For perspective cameras, this computes the proper size for an object at the given distance in Z-axis from the camera
 * @param camera Provide a camera to calculate the viewport position from. Defaults to current Three camera.
 * @returns The viewport width and height, and the unit to pixel factor. Multiply width and height with factor to get size in pixels.
 */
export function useSize(z: number = 0, camera?: Camera | null): [width: number, height: number, factor: number] {
    return useOffset([2, 2], z, camera);
}