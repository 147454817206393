import { ArrayVector, OptRange, TSUtils } from "@utils/TSUtils";

export class MathUtils {
    static readonly deg2Rad = Math.PI / 180;
    static readonly rad2Deg = 180 / Math.PI;

    /**
     * Clamp a value in a range
     * @param value Value to clamp
     * @param min Minimum value
     * @param max Maximum value
     * @returns Clamped value
     */
    static clamp(value: number, min: number, max: number): number {
        if (min > max) [min, max] = [max, min];
        if (value < min) return min;
        if (value > max) return max;
        return value;
    }

    /**
     * Maps a value from source range to target range
     * @param value Value to map
     * @param sourceMin Lower bound of source range
     * @param sourceMax Upper bound of source range
     * @param destinationMin Lower bound of target range
     * @param destinationMax Upper bound of target range
     * @param clamped Should the value be constrained to the range?
     * @returns Mapped value
     */
    static mapRange(value: number, sourceMin: number, sourceMax: number, destinationMin: number, destinationMax: number, clamped: boolean = false) {
        if (clamped) value = MathUtils.clamp(value, sourceMin, sourceMax);
        return ((value - sourceMin) / (sourceMax - sourceMin) * (destinationMax - destinationMin)) + destinationMin;
    }

    /**
     * Maps a value from source ranges to target ranges
     * @param value Value to map
     * @param sources List of source ranges
     * @param destinations List of target ranges
     * @param clamped Should the value be constrained to the ranges?
     * @returns Mapped value
     */
    static mapRanges(value: number, source: number[], destination: number[], clamped: boolean = false) {
        if (source.length !== destination.length) throw Error("Source range length not equal to destination range length");
        if (clamped) value = MathUtils.clamp(value, source[0], source[source.length - 1]);
        let sourceMin = source[0], sourceMax = source[0],
            destinationMin = destination[0], destinationMax = destination[0];
        for (let i = 1; i < source.length; ++i) {
            sourceMin = source[i - 1];
            sourceMax = source[i];
            destinationMin = destination[i - 1];
            destinationMax = destination[i];
            if (value <= sourceMax) return MathUtils.mapRange(value, sourceMin, sourceMax, destinationMin, destinationMax, clamped);
        }
        return MathUtils.mapRange(value, sourceMin, sourceMax, destinationMin, destinationMax, clamped);
    }

    /**
     * Linearly interpolates from `a` to `b` over `t`
     * @param a Value to lerp from
     * @param b Value to lerp to
     * @param t Time
     * @param clamped Should time be constrained to [0, 1]?
     * @returns Lerped value
     */
    static lerp(a: number, b: number, t: number, clamped: boolean = false) {
        if (clamped) t = MathUtils.clamp(t, 0, 1);
        return a * (1 - t) + b * t;
    }

    /**
     * Linearly interpolates from `a` to `b` over `t`
     * @param a Values to lerp from
     * @param b Values to lerp to
     * @param t Time
     * @param clamped Should time be constrained to [0, 1]?
     * @returns Lerped values
     */
    static lerpArray(a: Array<number>, b: Array<number>, t: number, clamped: boolean = false) {
        if (a.length !== b.length) throw Error("MathUtils.lerpArray: length of a and b must be equal!");
        const result: Array<number> = new Array(a.length);
        for (let i = 0; i < a.length; ++i) {
            result[i] = MathUtils.lerp(a[i], b[i], t, clamped);
        }
        return result;
    }

    /**
     * Linearly interpolates from `a` to `b` over `t`
     * @param a Values to lerp from
     * @param b Values to lerp to
     * @param t Time
     * @param clamped Should time be constrained to [0, 1]?
     * @returns Lerped values
     */
    static lerpArrayVector(a: ArrayVector, b: ArrayVector, t: number, clamped: boolean = false) {
        const result: ArrayVector = [0, 0, 0];
        for (let i = 0; i < a.length; ++i) {
            result[i] = MathUtils.lerp(a[i], b[i], t, clamped);
        }
        return result;
    }

    /**
     * Positive modulo
     * @param a Number to modulo
     * @param n Modulus
     * @returns Positive remainder
     */
    static mod(a: number, n: number) {
        return ((a % n) + n) % n;
    }

    /**
     * Gets a random value within a range
     * @param range Range or number
     * @returns A random value within [min, max[
     */
    static randRange(range: OptRange): number {
        const [min, max] = TSUtils.toRange(range);
        return Math.random() * (max - min) + min;
    }

    /**
     * Round a value down to the closest multiple of `to` 
     * @param value Value to round down
     * @param to Value to round down to
     * @returns Closest multiple of `to`, <= `to`
     */
    static floorTo(value: number, to: number) {
        const x = MathUtils.mod(value, to);
        return value - x;
    }

    /**
     * Round a value up to the closest multiple of `to`
     * @param value Value to round up
     * @param to Value to round up to
     * @returns Closest multiple of `to`, >= `to`
     */
    static ceilTo(value: number, to: number) {
        const x = MathUtils.mod(value, to);
        return value + (to - x);
    }

    /**
     * Round a value to the closest multiple of `to`
     * @param value Value to round
     * @param to Value to round to
     * @returns Closest multiple of `to`
     */
    static roundTo(value: number, to: number) {
        const x = MathUtils.mod(value, to);
        if (x / to >= 0.5) return MathUtils.ceilTo(value, to);
        else return MathUtils.floorTo(value, to);
    }
}