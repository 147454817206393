import { Suspense, useEffect, useMemo } from "react";
import { BufferAttribute, BufferGeometry, Material, Vector3 } from "three";
import { animated } from "@react-spring/three";
import { GroupProps } from "@react-three/fiber";
import { FillMaterial } from "@shaders/Fill/FillMaterialShader";
import { useLineGeometry } from "@utils/useMesh";

type Props = {
    nodesTop: number;
    nodesBottom: number;
    spacing: number;
    from: number;
    to: number;
} & GroupProps;

export const MLConnections = animated(function MLConnections({ nodesTop, nodesBottom, spacing, from, to, ...props }: Props) {
    const mat = useMemo(() => {
        return new FillMaterial({ color: "#26ffff", from: 0, to: 0, transparent: true });
    }, []);

    const nodes = useMemo(() => {
        const nodes = [];
        const offset = (nodesTop - nodesBottom) * 0.5;
        for (let i = 0; i < nodesBottom; ++i) {
            nodes.push(<MLNode key={i} index={i + offset} spacing={spacing} connections={nodesTop} material={mat} renderOrder={props.renderOrder} />);
        }
        return nodes;
    }, [nodesTop, nodesBottom, spacing, mat, props.renderOrder]);

    useEffect(() => {
        mat.from = from;
        mat.to = to;
    }, [mat, from, to]);

    if (from >= to) return null;
    return (
        <group {...props}>
            {nodes}
        </group>
    );
});

type NodeProps = {
    index: number;
    spacing: number;
    connections: number;
    material: Material;
} & GroupProps;
function MLNode({ index, spacing, connections, material, ...props }: NodeProps) {
    const geom = useMemo(() => {
        const length = 2 * connections;

        const points = new Array<Vector3>(length);
        const uvs = new Float32Array(length * 2);
        for (let i = 0; i < connections; ++i) {
            points[i * 2 + 0] = new Vector3(index * spacing, 0, 0);
            points[i * 2 + 1] = new Vector3(i * spacing, spacing, 0);

            uvs[i * 4 + 0] = 0;
            uvs[i * 4 + 1] = 1;
            uvs[i * 4 + 2] = 0;
            uvs[i * 4 + 3] = 0;
        }

        const geom = new BufferGeometry().setFromPoints(points);
        geom.setAttribute("uv", new BufferAttribute(uvs, 2));
        return geom;
    }, [index, spacing, connections]);

    const line = useLineGeometry(geom, material);
    if (props.renderOrder) line.renderOrder = props.renderOrder;

    return (
        <Suspense>
            <primitive object={line} {...props} />
        </Suspense>
    );
}