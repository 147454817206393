import { MeshBasicMaterial, MeshBasicMaterialParameters, Shader, Texture, Uniform, Vector2 as Vec2, WebGLRenderer } from "three";
import { ShaderUtils } from "@utils/ShaderUtils";

import frag_pars from "@shaders/MaskedDot/MaskedDot.pars.frag";
import frag_inject from "@shaders/MaskedDot/MaskedDot.inject.frag";
import frag from "@shaders/MaskedDot/MaskedDot.frag";
import { Vector2 } from "@react-three/fiber";
import { ThreeUtils } from "@utils/ThreeUtils";

type Params = {
    mask?: Texture;
    radius?: number;
    gap?: number;
    scale?: Vector2;
    shift?: Vector2;
};
export type { Params as MaskedDotMaterialParams };

export class MaskedDotMaterial extends MeshBasicMaterial {
    private _mask: Uniform<Texture | null> = new Uniform(null);
    private _radius: Uniform<number> = new Uniform(0.05);
    private _gap: Uniform<number> = new Uniform(0.02);
    private _scale: Uniform<Vec2> = new Uniform(new Vec2(1, 1));
    private _shift: Uniform<Vec2> = new Uniform(new Vec2(0, 0));

    constructor(params: Params & MeshBasicMaterialParameters) {
        const p = ShaderUtils.extract(params, 
            "mask", "radius", "gap", "scale", "shift"
        );
        super(params);
        if (!this.defines) this.defines = {};
        this.defines.USE_UV = "";
        ShaderUtils.assign(this, p);
    }

    onBeforeCompile(shader: Shader, renderer: WebGLRenderer) {
        super.onBeforeCompile(shader, renderer);

        shader.uniforms.mask = this._mask;
        shader.uniforms.dotRadius = this._radius;
        shader.uniforms.dotGap = this._gap;
        shader.uniforms.dotScale = this._scale;
        shader.uniforms.dotShift = this._shift;

        shader.fragmentShader = ShaderUtils.prepend(shader.fragmentShader, frag_pars, true);
        shader.fragmentShader = ShaderUtils.inject(shader.fragmentShader, frag_inject, frag, true);
    }

    public get mask() { return this._mask.value; }
    public set mask(value: Texture | null) { this._mask.value = value; }

    public get radius() { return this._radius.value; }
    public set radius(value: number) { this._radius.value = value; }

    public get gap() { return this._gap.value; }
    public set gap(value: number) { this._gap.value = value; }

    public get scale() { return this._scale.value; }
    public set scale(value: Vector2) { ThreeUtils.setVector(value, this._scale.value); }

    public get shift() { return this._shift.value; }
    public set shift(value: Vector2) { ThreeUtils.setVector(value, this._shift.value); }
};