import styles from "@styles/Mission.module.css";
import { useCallback, useContext, useEffect } from "react";
import { a } from "@react-spring/web";
import { useChain, useSpring, useSpringRef } from "@react-spring/three";
import { Environment } from "@react-three/drei";
import type { CanvasProps, HtmlProps, PageModule } from "@pages/Page";
import { EPage } from "@pages/Pages";
import { AnimatedTypeEffect } from "@components/TypeEffect";
import ScrollInstructions from "@components/ScrollInstructions";
import PointerView from "@components/PointerView";
import ShiftedCamera from "@components/ShiftedCamera";
import { Globe } from "@components/Globe";
import { LoadingContext } from "@contexts/LoadingContext";
import { MissionContext } from "@contexts/MissionContext";
import { appContext } from "@contexts/AppContext";
import { useViewport } from "@utils/ViewportManager";
import { EPerformanceMode } from "@utils/usePerformance";
import data from "@data/Text.json";
const text = data.Mission;

const page: EPage = EPage.Mission;
export default {
    Html,
    Canvas,
    page,
} as PageModule;

function Html(props: HtmlProps) {
    const { loaded } = useContext(LoadingContext);
    const context = useContext(MissionContext);
    const { state, dispatch } = context || {};
    const [view] = useViewport<HTMLDivElement>(page);

    const titleSpring = useSpringRef();
    const subtitleSpring = useSpringRef();
    const innerSpring = useSpringRef();
    const scrollSpring = useSpringRef();
    const [{ titlePercent }] = useSpring(() => ({
        titlePercent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 300, precision: 0.001 },
        ref: titleSpring
    }), [state?.index]);
    const [{ subtitlePercent }] = useSpring(() => ({
        subtitlePercent: (state?.index ?? 0) > 0 ? 1 : 0,
        config: { duration: 500, precision: 0.001 },
        ref: subtitleSpring
    }), [state?.index]);
    const [{ innerOpacity }] = useSpring(() => ({
        innerOpacity: (state?.index ?? 0) > 0 ? 1 : 0,
        delay: 200,
        config: { duration: 500, precision: 0.001 },
        ref: innerSpring
    }), [state?.index]);
    const [{ showScroll }] = useSpring(() => ({
        showScroll: (state?.index ?? 0) > 0 ? true : false,
        delay: 200,
        config: { duration: 1 },
        ref: scrollSpring
    }), [state?.index]);
    useChain([titleSpring, subtitleSpring, innerSpring, scrollSpring]);

    useEffect(() => {
        let index = 0;
        if (loaded && state?.shown) index = 1;
        dispatch?.({ type: "set", index: index });
    }, [dispatch, loaded, state?.shown, state?.index]);

    useEffect(() => {
        if (!props.active) return;
        dispatch?.({ type: "show" });
    }, [dispatch, props.active]);

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.static}>
                    <div className={styles.staticContent}>
                        <div className={styles.header}>
                            <AnimatedTypeEffect type="h1" className="accent3" text={text.title1} percent={titlePercent} /><br />
                            <AnimatedTypeEffect type="h2" className="accent2" text={text.subtitle1} percent={subtitlePercent} occupy />
                        </div>
                        <a.div className={styles.brief} style={{ opacity: innerOpacity }}>
                            <div>
                                <p className="accent2">{text.text1}</p>
                                <p className="accent2">{text.text2}</p>
                                <p className="accent2">{text.text3}</p>
                                <h2 className="accent3">{text.highlight}</h2>
                            </div>
                        </a.div>
                    </div>
                </div>
                {(state?.offset ?? 0) !== 0 &&
                    <div className={styles.canvasUnderlay} style={{ transform: props.mobile ? `translateY(${state?.offset ?? 0}px)` : `translateX(${state?.offset ?? 0}px)` }}>
                        <Background mobile={props.mobile} />
                    </div>
                }
                <div ref={view} className={styles.dynamic}></div>
                <a.div className="scroll" style={{ opacity: showScroll.to(value => value ? "1" : "0") }}>
                    <ScrollInstructions mobile={props.mobile} color="#21b0c1" />
                </a.div>
            </div>
        </div>
    );
}

function Background({ mobile }: { mobile: boolean }) {
    const height = parseInt(appContext.getCustomStyle("page-height") ?? "1") * (mobile ? 0.275 : 0.55);
    return (
        <div className={styles.background} style={{ width: `${height}px`, height: `${height}px` }} />
    );
}

function Canvas(props: CanvasProps) {
    const [view] = useViewport<HTMLDivElement>(page);

    const context = useContext(MissionContext);
    const { dispatch } = context || {};
    const onChange = useCallback((offset: [x: number, y: number], pixels: [x: number, y: number]) => {
        dispatch?.({ type: "offset", offset: props.mobile ? pixels[1] : pixels[0] });
    }, [dispatch, props.mobile]);

    const shift: [x: number, y: number] = props.mobile ? [0, 0.6] : [-0.5, 0];
    const z: number = props.mobile ? 20 : 10;

    return (
        <PointerView ref={view} alwaysUpdate={false} clear={true}>
            <ShiftedCamera enabled={props.active} shift={shift} fov={40} position-z={z} onChange={onChange} />
            {(props.active || props.mode !== EPerformanceMode.Performance) && <>
                <directionalLight intensity={1.0} position={[0.1, 5, -0.1]} castShadow />
                <ambientLight intensity={0.2} />
                <Environment files={`${process.env.PUBLIC_URL}/textures/city.exr`} />
                <MissionGlobe active={props.active} />
            </>}
        </PointerView>
    );
}

function MissionGlobe({ active }: { active: boolean }) {
    return (
        <Globe enabled={active} start={-90} angularSpeed={-0.05} scale={3} />
    )
}