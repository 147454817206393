import { useCallback } from "react";
import useActiveEvent from "@utils/useActiveEvent";

export default function useWheel<T extends HTMLElement>(onWheel: (amount: number, ev: WheelEvent) => void) {
    const wheel = useCallback((ev: WheelEvent) => {
        onWheel(ev.deltaY, ev);
    }, [onWheel]);

    const ref = useActiveEvent<T, "wheel">("wheel", wheel);
    
    return ref;
}