import styles from "@styles/Carousel.module.css";
import { ConsumerProps, useCallback, useState } from "react";
import { Arrow } from "@components/Icons";
import { MathUtils } from "@utils/MathUtils";
import { a, easings } from "@react-spring/web";
import useFade from "@utils/useFade";

type Alignment = "top" | "center" | "bottom";
function alignmentStyle(alignment: Alignment) {
    switch (alignment) {
        case "top": return "flex-start";
        default: case "center": return "center";
        case "bottom": return "flex-end";
    }
}

type State = {
    page: number;
}

type Props = {
    pages: number;
    infinite?: boolean;
    onChange?: (page: number, prev: number) => void;
    buttonPosition: Alignment;
} & ConsumerProps<State>;

let changing = false;

export default function Carousel({ pages, infinite = false, onChange, buttonPosition = "center", children }: Props) {
    const [index, setIndex] = useState(0);
    const [page, setPage] = useState(0);

    const increment = useCallback(() => {
        if (changing) return;
        changing = true;
        setTimeout(() => changing = false, 500);
        setIndex(i => {
            const last = i;
            ++i;
            if (infinite) i = MathUtils.mod(i, pages);
            i = MathUtils.clamp(i, 0, pages - 1);
            if (i !== last) onChange?.(i, last);
            return i;
        });
    }, [setIndex, onChange, infinite, pages]);

    const decrement = useCallback(() => {
        if (changing) return;
        changing = true;
        setTimeout(() => changing = false, 500);
        setIndex(i => {
            const last = i;
            --i;
            if (infinite) i = MathUtils.mod(i, pages);
            i = MathUtils.clamp(i, 0, pages - 1);
            if (i !== last) onChange?.(i, last);
            return i;
        });
    }, [setIndex, onChange, infinite, pages]);

    const update = useCallback(() => {
        setPage(index);
    }, [setPage, index]);

    const opacity = useFade({
        duration: 1000,
        easing: easings.easeOutQuad,
        precision: 0.001,
        onFaded: update
    }, [index]);

    return (
        <div className={styles.root}>
            <a.div className={styles.content} style={{ opacity: opacity }}>
                {children({ page: page })}
            </a.div>
            {pages > 1 && <div className={styles.controls} style={{ alignItems: alignmentStyle(buttonPosition) }}>
                <Button flip onClick={decrement} />
                <Button onClick={increment} />
            </div>}
        </div>
    );
}

type ButtonProps = {
    flip?: boolean;
    onClick?: () => void;
}
function Button({ flip = false, onClick }: ButtonProps) {
    const classes = [styles.button];
    if (flip) classes.push(styles.flip);
    return (
        <div className={classes.join(" ")} onClick={onClick}>
            <Arrow color="#44bcb3" />
        </div>
    )
}