import styles from "@styles/Video.module.css";
import { CSSProperties, useEffect, useLayoutEffect, useRef } from "react";
import { VideoTexture } from "three";
import { VideoHandle, VideoManager, VideoOptions } from "@utils/VideoManager";

export type Codec = {
    /** File extension without `.` */
    extension: string;
    /** File type */
    type: string;
    /** Supported codecs */
    codecs: string[];
}

type Props = {
    /** Video url without extension. Use `$res` as placeholder if using resolution property */
    src: string;
    /** Codec list */
    codecs?: Codec[];
    /** Video resolution (provided in src as `$res`) */
    resolution?: number;
    /** Mute the video */
    muted?: boolean;
    /** Loop the video */
    loop?: boolean;
    /** Play the video on load */
    autoPlay?: boolean;
    /** Hide the video */
    hidden?: boolean;
    /** Play video */
    play?: boolean;
    /** Optional styling */
    style?: CSSProperties;
} & VideoOptions;

function getSource(src: string, resolution: number = 1024) {
    return src.replace("$res", resolution.toString());
}

export default function Video({
    src,
    codecs = [{ extension: "mp4", type: "video/mp4", codecs: ["avc1.42E01E", "mp4a.40.2"] }],
    resolution = 1024,
    muted = false,
    loop = false,
    autoPlay = false,
    hidden = false,
    play = false,
    restart = false,
    texture = false,
    style
}: Props) {
    src = getSource(src, resolution);
    const video = useRef<HTMLVideoElement>(null);

    // Create the handle and texture
    useEffect(() => {
        VideoManager.instance.create(src, video, { restart, texture });
    }, [src, restart, texture]);

    // Mute the video, as the muted property 
    // during creation does not seem to work
    useLayoutEffect(() => {
        if (!video.current) return;
        video.current.muted = muted;
        video.current.defaultMuted = muted;
    });

    // Play/pause the video (this can also be 
    // achieved using `useVideo`)
    useEffect(() => {
        const handle = VideoManager.instance.get(src, "video");
        if (!handle) return;
        if (play) handle.play();
        else handle.pause();
    }, [src, play]);

    return (
        <video ref={video}
            crossOrigin="anonymous"
            preload="auto"
            playsInline
            muted={muted}
            loop={loop}
            autoPlay={autoPlay}
            className={hidden ? styles.hidden : undefined}
            style={style}
        >
            {codecs.map((codec, index) => (<source key={index} src={`${src}.${codec.extension}`} type={`${codec.type}; codecs="${codec.codecs.join(", ")}"`} />))}
        </video>
    );
};

export function useVideo(src: string, resolution: number = 1024) {
    src = getSource(src, resolution);
    const video = useRef<VideoHandle>();
    useEffect(() => {
        video.current = VideoManager.instance.get(src, "video");
    });
    return video;
}

export function useVideoTexture(src: string, resolution: number = 1024) {
    src = getSource(src, resolution);
    const texture = useRef<VideoTexture>();
    useEffect(() => {
        texture.current = VideoManager.instance.get(src, "texture");
    });
    return texture;
}